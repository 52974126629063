import { autorun } from 'mobx';

import {
  Color3,
  Observable,
  Scene,
  Space,
  StandardMaterial,
  Vector3,
} from '@babylonjs/core';
import { Vector2WithInfo } from '@babylonjs/gui';

import { BaseModelObject } from '../../common/base';

import {
  ToolsPanelClickInfo,
  ToolsPanelConfig,
  ToolsPanelPlaceType,
} from './types';
import { ToolsPanelGUI } from './gui';
import { ToolsPanelModel } from './model';
import { ToolsPanelStore } from './store';
import { setupLogic } from './logic';

export class ToolsPanelObject extends BaseModelObject<
  ToolsPanelModel,
  ToolsPanelStore,
  ToolsPanelConfig
> {
  private _gui: ToolsPanelGUI;

  private onToolsClick = new Observable<ToolsPanelClickInfo>();
  private onCloseButtonClick = new Observable<Vector2WithInfo>();

  constructor(
    scene: Scene,
    model: ToolsPanelModel,
    store: ToolsPanelStore,
    cfg: ToolsPanelConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._gui = new ToolsPanelGUI(
      this.model.texture,
      ToolsPanelModel.TEX_Q,
      this.model.uiImages
    );

    // Настройка материала
    const modalMat = new StandardMaterial('TabletModal_material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this.model.texture;
    modalMat.opacityTexture = this.model.texture;
    this.model.plane.plane.material = modalMat;
    this.model.texture.attachToMesh(this.model.plane.plane, true);

    // Перенаправление callback'ов
    this._gui.onToolsClick = (e) => {
      this.onToolsClick.notifyObservers(e);
    };
    this._gui.onCloseButtonClick = (e) => {
      this.onCloseButtonClick.notifyObservers(e);
    };
  }

  public static async setup(
    scene: Scene,
    cfg: ToolsPanelConfig
  ): Promise<ToolsPanelObject> {
    const model = await ToolsPanelModel.load(scene, cfg);
    const store = new ToolsPanelStore(model);
    return new ToolsPanelObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: ToolsPanelStore): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      this._gui.setSelected(store.selectedItems);
    });

    this.onToolsClick.add((e) => {
      store.clickToolButton(e.item);
    });

    autorun(() => {
      const { place } = store;
      if (place === undefined) return;

      const { root } = this.model.plane;
      root.parent = place.parent;

      switch (place.type) {
        case ToolsPanelPlaceType.KIP_RIGHT: {
          root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
          root.rotate(new Vector3(0, 1, 0), -0.5, Space.LOCAL);
          root.position.set(0.2, 0.8, -0.5);
          break;
        }
      }
    });
  }
}
