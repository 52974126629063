import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { InventoryConfig } from './types';
import { InventoryStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: InventoryStore,
  cfg: InventoryConfig
): void {
  autorun(() => {
    if (!store.scene.toolsPanel) return;
    const { gloveSelected, selectedTool } = store.scene.toolsPanel;
    objStore.setGloveSelected(gloveSelected);
    objStore.setSelectedTool(selectedTool);
  });
}
