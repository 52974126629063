import { HighlightLayer, Scene } from '@babylonjs/core';

import { BaseModelController } from '../../../common/base';

import { IPP1ClipsModel, IPP1Config } from '../types';
import { IPP1ClipsStore } from '../store/clips';
import { IPP1SingleClipsController } from './single-clip';

export class ClipsController extends BaseModelController<
  IPP1ClipsModel,
  IPP1ClipsStore,
  IPP1Config
> {
  private _yellow: IPP1SingleClipsController;
  private _red: IPP1SingleClipsController;
  private _black: IPP1SingleClipsController;

  constructor(
    scene: Scene,
    model: IPP1ClipsModel,
    store: IPP1ClipsStore,
    cfg: IPP1Config,
    highlightLayer: HighlightLayer
  ) {
    super(scene, model, store, cfg);

    this._yellow = new IPP1SingleClipsController(
      scene,
      model.yellow,
      store.yellow,
      highlightLayer
    );
    this._red = new IPP1SingleClipsController(
      scene,
      model.red,
      store.red,
      highlightLayer
    );
    this._black = new IPP1SingleClipsController(
      scene,
      model.black,
      store.black,
      highlightLayer
    );

    this.registerController(this._yellow);
    this.registerController(this._red);
    this.registerController(this._black);
  }
}
