import { AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';

export class KIPDisplayGUI {
  private _textBlock: TextBlock;

  public get textBlock(): TextBlock {
    return this._textBlock;
  }

  /**
   * Создание текстуры планшета
   */
  constructor(texture: AdvancedDynamicTexture, scale: number) {
    this._textBlock = new TextBlock();
    this._textBlock.fontFamily = 'digital-clock-font';
    this._textBlock.color = '#fff';
    this._textBlock.fontSize = 40 * scale;
    texture.addControl(this._textBlock);
  }
}
