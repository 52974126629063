import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName } from '../../../common/utils';

export class IPP1SingleClipModel {
  private _root: TransformNode;
  private _bbox: AbstractMesh;
  private _bodyMeshes: Mesh[];

  public get root(): TransformNode {
    return this._root;
  }

  public get bbox(): AbstractMesh {
    return this._bbox;
  }

  public get bodyMeshes(): Mesh[] {
    return this._bodyMeshes;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult, name: string) {
    const { meshes } = model;
    this._root = findByName(meshes, `Crocodile_${name}`);
    this._bbox = findByName(meshes, `Bbox_Crocodile_${name}`);
    this._bodyMeshes = this._root
      .getChildMeshes()
      .filter((e) => e !== this._bbox)
      .map((e) => e as Mesh);
    this._bodyMeshes.push(this._root as Mesh);

    this.setVisibility(false);
  }

  setVisibility(isVisible: boolean): void {
    for (const m of this._bodyMeshes) m.isVisible = isVisible;
    this._bbox.isVisible = false;
  }
}
