import { Button, Container, Control, Measure, TextBlock } from '@babylonjs/gui';

import { AskIfAcceptWindowConfig } from '../types';

export class WBodyAskQuestion extends Container {
  private _LABEL_PADDING = 10;
  private _LABEL_HEIGHT = 45;
  private _LABEL_FONT_SIZE = 13;
  private _BUTTON_PADDING = 10;
  private _BUTTON_HEIGHT = 25;
  private _BUTTON_FONT_SIZE = 13;
  private _BUTTON_RADIUS = 4;

  public labelText: TextBlock;
  public acceptButton: Button;
  public rejectButton: Button;

  private _scale = 1;

  public get scale(): number {
    return this._scale;
  }

  public set scale(scale: number) {
    this._scale = scale;
    this._markAsDirty();
  }

  constructor(cfg: AskIfAcceptWindowConfig) {
    super();
    this.labelText = this._setupAskText(cfg.questionText);
    this.acceptButton = this._setupAcceptButton(cfg.acceptText);
    this.rejectButton = this._setupRejectButton(cfg.rejectText);
  }

  /**
   * Создание и настройка текста вопроса
   */
  private _setupAskText(text: string): TextBlock {
    const block = new TextBlock('titleText');
    block.fontFamily = 'sans-serif';
    block.text = text;
    block.color = '#222';
    block.textWrapping = true;
    block.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    block.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    block.horizontalAlignment = Container.HORIZONTAL_ALIGNMENT_CENTER;
    this.addControl(block);
    return block;
  }

  private _createBlueButton(name: string, text: string): Button {
    const btn = Button.CreateSimpleButton(name, text);
    btn.fontFamily = 'sans-serif';
    btn.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    btn.color = 'white';
    btn.background = '#1c90f5';
    btn.thickness = 0;
    this.addControl(btn);
    return btn;
  }

  /**
   * Создание и настройка кнопки Accept
   */
  private _setupAcceptButton(text: string): Button {
    const btn = this._createBlueButton('acceptButton', text);
    btn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    return btn;
  }

  /**
   * Создание и настройка кнопки Reject
   */
  private _setupRejectButton(text: string): Button {
    const btn = this._createBlueButton('rejectButton', text);
    btn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    return btn;
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    const box = this._currentMeasure;
    const scale = this._scale;

    // Позиционирование текста вопроса
    this.labelText.fontSize = this._LABEL_FONT_SIZE * scale;
    this.labelText.top = this._LABEL_PADDING * scale;
    this.labelText.heightInPixels = this._LABEL_HEIGHT * scale;
    this.labelText.widthInPixels = box.width - this._LABEL_PADDING * 2 * scale;

    const buttonWidth = (box.width - this._BUTTON_PADDING * scale * 3) / 2;

    // Позиционирование кнопки accept
    this.acceptButton.fontSize = this._BUTTON_FONT_SIZE * scale;
    this.acceptButton.top = -this._BUTTON_PADDING * scale;
    this.acceptButton.left = this._BUTTON_PADDING * scale;
    this.acceptButton.heightInPixels = this._BUTTON_HEIGHT * scale;
    this.acceptButton.widthInPixels = buttonWidth;
    this.acceptButton.cornerRadius = this._BUTTON_RADIUS * scale;

    // Позиционирование кнопки reject
    this.rejectButton.fontSize = this._BUTTON_FONT_SIZE * scale;
    this.rejectButton.top = -this._BUTTON_PADDING * scale;
    this.rejectButton.left = -this._BUTTON_PADDING * scale;
    this.rejectButton.heightInPixels = this._BUTTON_HEIGHT * scale;
    this.rejectButton.widthInPixels = buttonWidth;
    this.rejectButton.cornerRadius = this._BUTTON_RADIUS * scale;
  }
}
