import { Result } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';

import css from './index.module.css';

const UnavailableLesson: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Result
      className={css.lessonDoesNotExist}
      icon={<CloseCircleOutlined />}
      title={t('lesson.lessonDoesNotExist')}
    />
  );
});

export default UnavailableLesson;
