import { PhotoDome, Scene } from '@babylonjs/core';
import { getRootUrlForObject } from '../../common/utils';

import { SkyboxConfig } from './types';

export class SkyboxModel {
  public static readonly MODEL_VERSION = 'v0001';

  private _main: PhotoDome;

  get main(): PhotoDome {
    return this._main;
  }

  constructor(scene: Scene, main: PhotoDome) {
    this._main = main;

    this.setVisibility(true);
  }

  static load(scene: Scene, cfg: SkyboxConfig): Promise<SkyboxModel> {
    const imageRootUrl = getRootUrlForObject(
      cfg.image.root_url,
      this.MODEL_VERSION,
      cfg.image.folder
    );

    return new Promise<SkyboxModel>((resolve, reject) => {
      const mainMesh = new PhotoDome(
        'skybox',
        imageRootUrl + cfg.image.filename,
        {
          resolution: 16,
          size: 1000,
        },
        scene,
        (msg) => reject(msg)
      );

      if (mainMesh.texture.isReady()) resolve(new SkyboxModel(scene, mainMesh));
      else
        mainMesh.texture.onLoadObservable.add(() =>
          resolve(new SkyboxModel(scene, mainMesh))
        );
    });
  }

  /**
   * Управлять видимостью объекта
   */
  public setVisibility(isVisible: boolean): void {
    this._main.mesh.isVisible = isVisible;
  }
}
