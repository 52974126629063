import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC, useEffect, useRef } from 'react';

import css from './index.module.css';

const Scene: FC = observer(() => {
  const canvasElement = useRef<HTMLCanvasElement | null>(null);

  const { lessonScene, setCanvasElement } = useRootData((store) => ({
    lessonScene: store.lesson.lessonScene,
    setCanvasElement: store.lesson.setCanvasElement,
  }));

  useEffect(() => {
    canvasElement.current && setCanvasElement(canvasElement.current);
  }, [setCanvasElement]);

  // Пересылать сообщение об изменении размера окна объекту engine
  window.addEventListener('resize', () => {
    lessonScene && lessonScene.resize();
  });

  return (
    <div className={css.canvasWrapper}>
      <canvas ref={canvasElement} className={css.canvas} />
    </div>
  );
});

export default Scene;
