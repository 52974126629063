import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { BrushConfig } from './types';

export class BrushModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _brush: AbstractMesh;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._brush = findByName(model.meshes, 'Brush');
  }

  static async load(scene: Scene, cfg: BrushConfig): Promise<BrushModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new BrushModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    this._brush.isVisible = isVisible;
  }

  get root(): TransformNode {
    return this._brush;
  }

  get brush(): AbstractMesh {
    return this._brush;
  }
}
