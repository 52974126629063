import { action, makeAutoObservable } from 'mobx';

import { CraneUnitModel, ICraneUnitModel } from './model';

export interface ICraneUnitStore {
  /**
   * Модель
   */
  get model(): ICraneUnitModel;

  /**
   * Виден ли объект
   */
  get isVisible(): boolean;
}

export class CraneUnitStore implements ICraneUnitStore {
  private _model: CraneUnitModel;

  private _isVisible = false;

  public get model(): CraneUnitModel {
    return this._model;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor(model: CraneUnitModel) {
    this._model = model;

    makeAutoObservable(this, {
      setVisibility: action,
    });
  }

  /**
   * Установить видемость объекта
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
