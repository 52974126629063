import { Measure } from '@babylonjs/gui';

import { WindowCButtonBase } from './window-cbutton-base';
import { drawRoundedRect } from '../../draw-utils/rounded-rect';

/**
 * Класс для создания красного крестика (кнопка управления) для окна
 */
export class WindowCButtonClose extends WindowCButtonBase {
  private _CLOSE_THICKNESS = 0.15;
  private _HEIGHT_COEFF = 0.8;
  private _crossColor = '#d6d6d6';

  constructor(name: string) {
    super(name);

    this.background = '#cd3a3a';
  }

  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();
    super._localDraw(context); // Отображение кнопки

    // Отрисовка крестика
    const { left, top, width, height } = this._currentMeasure;
    const lineWidth = width * this._CLOSE_THICKNESS;
    const lineHeight = height * this._HEIGHT_COEFF;

    const box = new Measure(
      -lineWidth / 2,
      -lineHeight / 2,
      lineWidth,
      lineHeight
    );
    // Смещение к центру крестика
    context.translate(left + width / 2, top + height / 2);

    context.fillStyle = this._crossColor;

    context.rotate(-Math.PI / 4);
    drawRoundedRect(context, box, this.cornerRadius);
    context.fill();

    context.rotate(Math.PI / 2);
    drawRoundedRect(context, box, this.cornerRadius);
    context.fill();

    context.restore();
  }
}
