import { Grid } from '@babylonjs/gui';

import { ImageItem } from './image-item';
import { PanelItemType, ToolsPanelClickInfo } from '../types';

type ToolsPanelClickCallback = (e: ToolsPanelClickInfo) => void;

export class WBodyContent extends Grid {
  private ROWS = 2;
  private COLUMNS = 4;

  private _id2item: PanelItemType[];
  private _items: (ImageItem | undefined)[];

  public onButtonClickCallback: ToolsPanelClickCallback | null = null;

  constructor(
    scale: number,
    UIImages: Record<PanelItemType, HTMLImageElement>,
    name?: string
  ) {
    super(name);
    this._id2item = [];
    this._items = Array.from(
      { length: this.ROWS * this.COLUMNS },
      () => undefined
    );

    const addItem = (
      y: number,
      x: number,
      item: PanelItemType,
      title: string
    ) => {
      const id = y * this.COLUMNS + x;
      this._id2item[id] = item;
      this._items[id] = new ImageItem(title, UIImages[item], scale);
    };

    addItem(0, 0, PanelItemType.GLOVES, 'перчатки');
    addItem(0, 1, PanelItemType.KEY, 'ключ');
    addItem(0, 2, PanelItemType.BRUSH, 'щетка');
    addItem(0, 3, PanelItemType.WRENCH, 'трубчатый ключ');
    addItem(1, 0, PanelItemType.TUBE, 'вазелин');
    addItem(1, 1, PanelItemType.TABLET, 'планшет');
    addItem(1, 2, PanelItemType.IPP1, 'ИПП-1');

    // create grid
    for (let i = 0; i < this.ROWS; i += 1) this.addRowDefinition(1, false);
    for (let i = 0; i < this.COLUMNS; i += 1)
      this.addColumnDefinition(1, false);

    // add items to the grid
    for (let y = 0; y < this.ROWS; y += 1)
      for (let x = 0; x < this.COLUMNS; x += 1) {
        const id = y * this.COLUMNS + x;
        const it = this._items[id];
        if (it) {
          this.addControl(it, y, x);
          it.onPointerClickObservable.add((e) => {
            const ne = { item: this._id2item[id], v: e };
            this.onButtonClickCallback && this.onButtonClickCallback(ne);
          });
        }
      }
  }

  setSelected(selected: Set<PanelItemType>): void {
    this._id2item.forEach((el, i) => {
      this._items[i]?.setSelected(selected.has(el));
    });
  }
}
