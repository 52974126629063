import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../../common/base';

import { TechBlockConfig } from '../types';
import { TechBlockModel } from '../model';
import { TechBlockStore } from '../store';
import { WheelController } from '../../../common/wheel';
import { setupLogic } from '../logic';

export class TechBlockObject extends BaseModelObject<
  TechBlockModel,
  TechBlockStore,
  TechBlockConfig
> {
  private _wheel1: WheelController;
  private _wheel2: WheelController;

  constructor(
    scene: Scene,
    model: TechBlockModel,
    store: TechBlockStore,
    cfg: TechBlockConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._wheel1 = new WheelController(scene, model.wheel[0], store.wheel1);
    this.registerController(this._wheel1);

    this._wheel2 = new WheelController(scene, model.wheel[1], store.wheel2);
    this.registerController(this._wheel2);
  }

  public static async setup(
    scene: Scene,
    cfg: TechBlockConfig
  ): Promise<TechBlockObject> {
    const model = await TechBlockModel.load(scene, cfg);
    const store = new TechBlockStore(model);
    return new TechBlockObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: TechBlockStore, cfg: TechBlockConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }
}
