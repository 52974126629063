import { Measure, StackPanel } from '@babylonjs/gui';

import { InfoPageContentType } from '../store';

import { InfoPoint } from './info-point';

export class WBodyInfoPage extends StackPanel {
  private _borderMargin: number;

  public fullName: InfoPoint;
  public lessonTitle: InfoPoint;
  public lessonDuration: InfoPoint;
  public lessonMode: InfoPoint;

  constructor(scale: number, name?: string) {
    super(name);

    this.fullName = new InfoPoint(scale, 'ФИО: ', 2, 'FullName');
    this.lessonTitle = new InfoPoint(scale, 'Тема: ', 3, 'LessonTitle');
    this.lessonDuration = new InfoPoint(
      scale,
      'Время прохождения: ',
      1,
      'lessonDuration'
    );
    this.lessonMode = new InfoPoint(scale, 'Режим: ', 1, 'LessonMode');

    this._borderMargin = 4 * scale;

    this.addControl(this.fullName);
    this.addControl(this.lessonTitle);
    this.addControl(this.lessonDuration);
    this.addControl(this.lessonMode);
  }

  protected _preMeasure(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._preMeasure(parentMeasure, context);

    this.widthInPixels = parentMeasure.width - this._borderMargin * 2;
    this.heightInPixels = parentMeasure.height - this._borderMargin * 2;
  }

  setContent(content: InfoPageContentType): void {
    this.fullName.valueText.text = content.fullName;
    this.lessonTitle.valueText.text = content.lessonTitle;
    this.lessonDuration.valueText.text = content.lessonDuration;
    this.lessonMode.valueText.text = content.lessonMode;
  }
}
