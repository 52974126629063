import { HandsStore } from '../hands';
import { IInventoryStore } from '../inventory';

export type PlayerStoreList = {
  hands?: HandsStore;
  inventory?: IInventoryStore;
};

export interface IPlayerStore {
  /**
   * Руки
   */
  get hands(): HandsStore | undefined;

  /**
   * Инвентарь
   */
  get inventory(): IInventoryStore | undefined;
}

export interface ILogicPlayerStore extends IPlayerStore {}

export class PlayerStore implements ILogicPlayerStore {
  private _stores: PlayerStoreList;

  public get hands(): HandsStore | undefined {
    return this._stores.hands;
  }

  public get inventory(): IInventoryStore | undefined {
    return this._stores.inventory;
  }

  constructor(stores: PlayerStoreList) {
    this._stores = stores;
  }
}
