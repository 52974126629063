import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { TabletConfig } from './types';
import { TabletStore } from './store';
import { ToolsType } from '../types';

export function setupLogic(
  store: LessonStore,
  objStore: TabletStore,
  cfg: TabletConfig
): void {
  // Установка видимости и положения
  autorun(() => {
    const { hands, inventory } = store.player;
    if (!hands || !inventory) return;
    const isSelected = inventory.selectedTool === ToolsType.TABLET;
    objStore.setVisibility(isSelected && !hands.animating);
    objStore.moveToParent(isSelected ? hands.minorHand.helperTake : null);
  });

  // Установка акивности кнопки планшета
  autorun(() => {
    // Отключить кнопки у планшета если все поля уже заполнены
    // или если на текущем шаге не требуется ничего заполнять
    const isRequiredToAddRecord =
      store.script?.currentStep?.doneCondition?.stateName ===
      'tabletEntriesCount';
    objStore.setEnabled(isRequiredToAddRecord && objStore.recordsId < 9);
  });
}
