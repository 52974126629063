import { makeAutoObservable } from 'mobx';

import { IBrushStore } from '../brush';
import { IIPP1Store } from '../ipp1';
import { IKeyStore } from '../key';
import { ITabletStore } from '../tablet';
import { ITubeStore } from '../tube/store';
import { IWrenchStore } from '../wrench';

export type ToolsStoreList = {
  brush?: IBrushStore;
  ipp1?: IIPP1Store;
  key?: IKeyStore;
  tablet?: ITabletStore;
  tube?: ITubeStore;
  wrench?: IWrenchStore;
};

export class ToolsStore {
  private _stores: ToolsStoreList;

  /**
   * Щетка
   */
  public get brush(): IBrushStore | undefined {
    return this._stores.brush;
  }

  /**
   * ИПП-1
   */
  public get ipp1(): IIPP1Store | undefined {
    return this._stores.ipp1;
  }

  /**
   * Ключ
   */
  public get key(): IKeyStore | undefined {
    return this._stores.key;
  }

  /**
   * Планшет
   */
  public get tablet(): ITabletStore | undefined {
    return this._stores.tablet;
  }

  /**
   * Тюбик вазелина
   */
  public get tube(): ITubeStore | undefined {
    return this._stores.tube;
  }

  /**
   * Гаечный ключ
   */
  public get wrench(): IWrenchStore | undefined {
    return this._stores.wrench;
  }

  constructor(stores: ToolsStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
