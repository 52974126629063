import { AdvancedDynamicTexture } from '@babylonjs/gui';
import {
  Color3,
  HighlightLayer,
  Scene,
  Space,
  StandardMaterial,
  Texture,
  Vector3,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { KIPConfig, KIPTerminalState } from '../types';
import { KIPDisplayGUI } from '../gui';
import { KIPDoorController } from './door-cotroller';
import { KIPLabelsController } from './label-controller';
import { KIPModel } from '../model';
import { KIPStore } from '../store';

import { CommonPlaneModel } from '../../../common/models';
import { KIPTerminalsController } from './terminals-cotroller';
import { KIPWireController } from './wire';

export class KIPObject {
  private static TEX_Q = 4;

  private _model: KIPModel;
  private _displayModel: CommonPlaneModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: KIPDisplayGUI;

  private _isVisible = true;
  private _isDisplayVisible = false;

  private _highlightLayer: HighlightLayer;
  private _backDoor: KIPDoorController;
  private _frontDoor: KIPDoorController;
  private _labels: KIPLabelsController;
  private _terminals: KIPTerminalsController;
  private _t1Wire: KIPWireController;

  /**
   * Модель KIP'а
   */
  get model(): KIPModel {
    return this._model;
  }

  get backDoor(): KIPDoorController {
    return this._backDoor;
  }

  get frontDoor(): KIPDoorController {
    return this._frontDoor;
  }

  get t1Wire(): KIPWireController {
    return this._t1Wire;
  }

  get labels(): KIPLabelsController {
    return this._labels;
  }

  get terminals(): KIPTerminalsController {
    return this._terminals;
  }

  constructor(scene: Scene, model: KIPModel, displayModel: CommonPlaneModel) {
    this._model = model;
    this._displayModel = displayModel;
    this._texture = new AdvancedDynamicTexture(
      'KIPDisplay',
      100 * KIPObject.TEX_Q,
      100 * KIPObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new KIPDisplayGUI(this._texture, KIPObject.TEX_Q);

    this._displayModel.root.parent = this._model.root;
    this._displayModel.root.position.set(0, 0.8, 0.25);
    this._displayModel.root.rotate(
      new Vector3(0, 1, 0),
      Math.PI / 2,
      Space.LOCAL
    );

    this._highlightLayer = new HighlightLayer('KIP_hll', scene);
    this._backDoor = new KIPDoorController(
      scene,
      this._highlightLayer,
      model.backDoor
    );
    this._frontDoor = new KIPDoorController(
      scene,
      this._highlightLayer,
      model.frontDoor
    );
    this._labels = new KIPLabelsController(
      scene,
      model.labelsBoxes,
      this._highlightLayer
    );
    this._terminals = new KIPTerminalsController(scene, model);
    this._t1Wire = new KIPWireController(scene, model.t1Wire);

    // Настройка материала дисплея KIP'а
    const displayMat = new StandardMaterial('KIPDisplay_material', scene);
    displayMat.backFaceCulling = false;
    displayMat.specularColor = Color3.Black();
    displayMat.diffuseTexture = this._texture;
    displayMat.opacityTexture = this._texture;
    this._displayModel.plane.material = displayMat;

    this._updateVisibility();
  }

  /**
   * Скачать все модели и создать КИП
   */
  public static async setup(scene: Scene, cfg: KIPConfig): Promise<KIPObject> {
    const model = await KIPModel.load(cfg, scene);
    const plane = await CommonPlaneModel.load(scene, {
      height: 0.2,
      width: 0.2,
    });
    return new KIPObject(scene, model, plane);
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: KIPStore): void {
    store.init(this._model);
    store.labels.setLabelsCount(this._labels.labelsCount);

    // Реакции на изменения store задней двери
    autorun(() => {
      this.backDoor.highlight(store.backDoor.isHighlighted);
      this.backDoor.setDoorState(store.backDoor.targetIsOpen);
    });

    // Реакции на изменения store передней двери
    autorun(() => {
      this.frontDoor.highlight(store.frontDoor.isHighlighted);
      this.frontDoor.setDoorState(store.frontDoor.targetIsOpen);
    });

    // Реакции на изменения состояния провода Т1
    autorun(() => {
      this.t1Wire.setWireState(store.t1Wire.isConnected);
    });

    // Реакции на изменения текста на экране
    autorun(() => {
      this.setDisplayText(store.displayText);
    });

    // Реакции на изменения состояния клемм
    autorun(() => {
      const isHighlighted = store.terminals.highlighted;
      const isDirty = store.terminals.dirty;
      for (let i = 0; i < isHighlighted.length; i += 1) {
        if (isHighlighted[i])
          this._terminals.setTerminalState(i, KIPTerminalState.Highlighted);
        else if (isDirty[i])
          this._terminals.setTerminalState(i, KIPTerminalState.Dirty);
        else this._terminals.setTerminalState(i, KIPTerminalState.Normal);
      }
    });

    // Реакции на изменения состояния клемм
    autorun(() => {
      const { looseTerminals } = store.terminals;
      this._model.terminals.measure.forEach((terminal, index) => {
        if (looseTerminals.has(index)) {
          this.setLooseTerminal(true, index);
        } else {
          this.setLooseTerminal(false, index);
        }
      });
    });

    // Изменение store
    const storeToUpdate = store;
    this.backDoor.onAnimationDone.add((isOpen) => {
      storeToUpdate.backDoor.isOpen = isOpen;
    });
    this.frontDoor.onAnimationDone.add((isOpen) => {
      storeToUpdate.frontDoor.isOpen = isOpen;
    });
  }

  /**
   * Установить текст на дисплей KIP'а
   */
  public setDisplayText(text: string): void {
    this._gui.textBlock.text = text;
  }

  private _updateVisibility(): void {
    this._model.setVisibility(this._isVisible);
    this._displayModel.setVisibility(this._isVisible && this._isDisplayVisible);
  }

  /**
   * Изменить видимость KIP
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
    this._updateVisibility();
  }

  /**
   * Изменить видимость экрана KIP
   */
  public setDisplayVisibility(isVisible: boolean): void {
    this._isDisplayVisible = isVisible;
    this._updateVisibility();
  }

  /**
   * Изменить состояние клеммы откручено/затянуто
   */
  public setLooseTerminal(loose: boolean, i: number): void {
    const { screw } = this._model.terminals.measure[i];
    if (!screw) return;
    if (loose) {
      screw.position.x = 0.05;
    } else {
      screw.position.x = 0;
    }
  }
}
