import { autorun } from 'mobx';

import {
  AbstractMesh,
  Observable,
  Quaternion,
  Scene,
  Vector3,
  WebXRDefaultExperience,
  WebXRInputSource,
  WebXRState,
} from '@babylonjs/core';

import { BaseObject } from '../../common/base';
import { XRConfig } from './types';
import { XRStore } from './store';
import { setupLogic } from './logic';

export class XRObject extends BaseObject<XRStore, XRConfig> {
  private _xr?: WebXRDefaultExperience;
  private _collisionFloor?: AbstractMesh;

  public onStateChangedObservable = new Observable<WebXRState>();
  public onAddControllerObservable = new Observable<WebXRInputSource>();

  constructor(scene: Scene, store: XRStore, cfg: XRConfig) {
    super(scene, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  private _configureXR(floor: AbstractMesh) {
    this.scene
      .createDefaultXRExperienceAsync({
        inputOptions: { doNotLoadControllerMeshes: true },
        floorMeshes: [floor],
      })
      .then((xr) => {
        this._xr = xr;
        xr.teleportation.addFloorMesh(floor);
        xr.input.xrCamera.checkCollisions = true;
        xr.input.xrCamera.applyGravity = true;
        xr.input.xrCamera.ellipsoid = new Vector3(1, 1, 1);
        xr.baseExperience.onStateChangedObservable.add((state) => {
          this.onStateChangedObservable.notifyObservers(state);
        });
        xr.input.controllers.forEach((controller) => {
          this.onAddControllerObservable.notifyObservers(controller);
        });
        xr.input.onControllerAddedObservable.add((controller) => {
          controller.onMotionControllerInitObservable.add(() => {
            this.onAddControllerObservable.notifyObservers(controller);
          });
        });
      });
  }

  protected _connectToStore(store: XRStore, cfg: XRConfig): void {
    autorun(() => {
      if (!this.store.collisionFloor || this._collisionFloor) return;
      this._collisionFloor = this.store.collisionFloor;
      this._configureXR(this._collisionFloor);
    });

    this.onStateChangedObservable.add((state) => {
      if (state === WebXRState.ENTERING_XR) this.store.setIsActive(true);
      if (state === WebXRState.EXITING_XR) this.store.setIsActive(false);
    });

    this.onAddControllerObservable.add((controller) => {
      const { motionController } = controller;
      if (!motionController) return;

      const type = motionController.handedness;
      if (type !== 'left' && type !== 'right') return;

      const pointer = controller.grip || controller.pointer;
      const controllerStore = this.store.controllers.addController(
        type === 'left',
        pointer
      );

      motionController
        .getComponent('xr-standard-trigger')
        ?.onButtonStateChangedObservable.add((s) => {
          controllerStore.buttons.setStandardTrigger(s.pressed);
        });
      motionController
        .getComponent('a-button')
        ?.onButtonStateChangedObservable.add((s) => {
          controllerStore.buttons.setA(s.pressed);
        });

      let curQ: Quaternion | undefined;
      this.scene.onBeforeRenderObservable.add(() => {
        const Q =
          store.controllers[type]?.pointer.absoluteRotationQuaternion.clone();

        if (curQ && Q && Q.equals(curQ)) return;
        if (curQ && Q) {
          const dQ = Quaternion.Inverse(curQ).multiply(Q);
          controllerStore.setRotationDeltaQ(dQ);
        }
        curQ = Q?.clone();
      });
    });
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static setup(scene: Scene, cfg: XRConfig): Promise<XRObject> {
    const store = new XRStore();
    return Promise.resolve(new XRObject(scene, store, cfg));
  }
}
