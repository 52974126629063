/* tslint:disable */
/* eslint-disable */
/**
 * WebSocketTraning.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface LearnerInfoModel
 */
export interface LearnerInfoModel {
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    lessonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    learnerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    startDate?: string;
    /**
     * 
     * @type {LessonType}
     * @memberof LearnerInfoModel
     */
    lessonType?: LessonType;
    /**
     * 
     * @type {Array<LessonStepInfoModel>}
     * @memberof LearnerInfoModel
     */
    lessonSteps?: Array<LessonStepInfoModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LearnerInfoModel
     */
    isOnline?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LearnerInfoModel
     */
    expiresAt?: string | null;
}
/**
 * 
 * @export
 * @interface LessonStepInfoModel
 */
export interface LessonStepInfoModel {
    /**
     * 
     * @type {string}
     * @memberof LessonStepInfoModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonStepInfoModel
     */
    checkTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LessonStepInfoModel
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LessonType {
    Learning = 'learning',
    Training = 'training',
    Exam = 'exam'
}


/**
 * LearnersApi - axios parameter creator
 * @export
 */
export const LearnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersLessonIdGet: async (lessonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('apiLearnersLessonIdGet', 'lessonId', lessonId)
            const localVarPath = `/api/learners/{lessonId}`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersRemoveofflinelearnersPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learners/removeofflinelearners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LearnersApi - functional programming interface
 * @export
 */
export const LearnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LearnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearnerInfoModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnersLessonIdGet(lessonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearnerInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnersLessonIdGet(lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnersRemoveofflinelearnersPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnersRemoveofflinelearnersPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LearnersApi - factory interface
 * @export
 */
export const LearnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LearnersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersGet(options?: any): AxiosPromise<Array<LearnerInfoModel>> {
            return localVarFp.apiLearnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersLessonIdGet(lessonId: string, options?: any): AxiosPromise<LearnerInfoModel> {
            return localVarFp.apiLearnersLessonIdGet(lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnersRemoveofflinelearnersPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLearnersRemoveofflinelearnersPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LearnersApi - object-oriented interface
 * @export
 * @class LearnersApi
 * @extends {BaseAPI}
 */
export class LearnersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnersApi
     */
    public apiLearnersGet(options?: any) {
        return LearnersApiFp(this.configuration).apiLearnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnersApi
     */
    public apiLearnersLessonIdGet(lessonId: string, options?: any) {
        return LearnersApiFp(this.configuration).apiLearnersLessonIdGet(lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnersApi
     */
    public apiLearnersRemoveofflinelearnersPost(options?: any) {
        return LearnersApiFp(this.configuration).apiLearnersRemoveofflinelearnersPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonSessionApi - axios parameter creator
 * @export
 */
export const LessonSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonsessionIdFinishPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLessonsessionIdFinishPost', 'id', id)
            const localVarPath = `/api/lessonsession/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonSessionApi - functional programming interface
 * @export
 */
export const LessonSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLessonsessionIdFinishPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLessonsessionIdFinishPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonSessionApi - factory interface
 * @export
 */
export const LessonSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonSessionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonsessionIdFinishPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLessonsessionIdFinishPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonSessionApi - object-oriented interface
 * @export
 * @class LessonSessionApi
 * @extends {BaseAPI}
 */
export class LessonSessionApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonSessionApi
     */
    public apiLessonsessionIdFinishPost(id: string, options?: any) {
        return LessonSessionApiFp(this.configuration).apiLessonsessionIdFinishPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


