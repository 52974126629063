import { Result } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { FrownOutlined } from '@ant-design/icons';

import css from './index.module.css';

const AlreadyTakenLesson: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Result
      className={css.alreadyTakenLessonWindow}
      icon={<FrownOutlined />}
      title={t('lesson.lessonIsAlreadyTaken')}
    />
  );
});

export default AlreadyTakenLesson;
