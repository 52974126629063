import { action, makeAutoObservable } from 'mobx';

import { KIPDoorStore } from './door';
import { KIPLabelsStore } from './labels';
import { KIPModel } from '../model';
import { KIPTerminalsStore } from './terminals';
import { WireStore } from './wire';

export { KIPLabelsStore } from './labels';
export { KIPDoorStore } from './door';
export { KIPTerminalsStore } from './terminals';
export { WireStore } from './wire';

export class KIPStore {
  private _frontDoor = new KIPDoorStore();
  private _backDoor = new KIPDoorStore();
  private _labels = new KIPLabelsStore();
  private _terminals = new KIPTerminalsStore(16, [0, 1, 2, 3, 5]);
  private _t1Wire = new WireStore();
  private _displayText = '';

  private _model?: KIPModel;

  /**
   * Передняя дверь
   */
  public get frontDoor(): KIPDoorStore {
    return this._frontDoor;
  }

  /**
   * Задняя дверь
   */
  public get backDoor(): KIPDoorStore {
    return this._backDoor;
  }

  /**
   * Провод Т1
   */
  public get t1Wire(): WireStore {
    return this._t1Wire;
  }

  /**
   * Надписи
   */
  public get labels(): KIPLabelsStore {
    return this._labels;
  }

  public get model(): KIPModel {
    return this._model!; // TODO: убрать восклицательный знак
  }

  /**
   * Клеммы
   */
  public get terminals(): KIPTerminalsStore {
    return this._terminals;
  }

  public get displayText(): string {
    return this._displayText;
  }

  constructor() {
    makeAutoObservable(this, {
      setDisplayText: action,
    });
  }

  public init(model: KIPModel): void {
    this._model = model;
  }

  public setDisplayText(value: string): void {
    this._displayText = value;
  }
}
