import { LessonStore } from 'features/lesson-page/store';

import { ToolsPanelConfig, ToolsPanelPlaceType } from './types';
import { ToolsPanelStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: ToolsPanelStore,
  cfg: ToolsPanelConfig
): void {
  objStore.setVisibility(true);

  const placeType = cfg.place as ToolsPanelPlaceType;
  if (!Object.values(ToolsPanelPlaceType).includes(placeType)) {
    const av = Object.values(ToolsPanelPlaceType);
    throw Error(
      `Place type '${placeType}' not found for ToolsPanel. Available place types: ${av}`
    );
  }

  const requiredError = (objName: string) => {
    return Error(
      `${objName} is required for the selected place type (${placeType}), but is undefined`
    );
  };

  switch (placeType) {
    case ToolsPanelPlaceType.KIP_RIGHT: {
      if (!store.scene.KIP) throw requiredError('scene.KIP');
      objStore.moveTo(
        ToolsPanelPlaceType.KIP_RIGHT,
        store.scene.KIP.model.main
      );
    }
  }
}
