import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Material,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import {
  findByName,
  getRootUrlForObject,
  loadJSImage,
  loadMesh,
} from '../../../common/utils';

import {
  IPP1Buttons,
  IPP1ClipsConnectHelpers,
  IPP1ClipsModel,
  IPP1Config,
} from '../types';
import { IPP1SingleClipModel } from './single-clip';

export { IPP1SingleClipModel } from './single-clip';

export class IPP1Model {
  public static readonly MODEL_VERSION = 'v0002';

  private _bodyMeshes: AbstractMesh[];
  private _corpus: AbstractMesh;
  private _display2: AbstractMesh;
  private _buttons: AbstractMesh[] = [];
  private _clips: IPP1ClipsModel;
  private _clipLeftHelpers: IPP1ClipsConnectHelpers;
  private _clipRightHelpers: IPP1ClipsConnectHelpers;
  private _uiImages: HTMLImageElement[];

  public get root(): TransformNode {
    return this._corpus;
  }

  public get buttons(): AbstractMesh[] {
    return this._buttons;
  }

  public get clips(): IPP1ClipsModel {
    return this._clips;
  }

  public get clipLeftHelpers(): IPP1ClipsConnectHelpers {
    return this._clipLeftHelpers;
  }

  public get clipRightHelpers(): IPP1ClipsConnectHelpers {
    return this._clipRightHelpers;
  }

  public get corpus(): AbstractMesh {
    return this._corpus;
  }

  public get uiImages(): HTMLImageElement[] {
    return this._uiImages;
  }

  constructor(
    scene: Scene,
    model: ISceneLoaderAsyncResult,
    uiImages: HTMLImageElement[]
  ) {
    const { meshes, transformNodes } = model;
    this._uiImages = uiImages;

    const display = findByName(meshes, 'Display');
    this._display2 = findByName(meshes, 'Display2');
    this._buttons[IPP1Buttons.LEFT] = findByName(meshes, 'Button.Left');
    this._buttons[IPP1Buttons.UP] = findByName(meshes, 'Button.Up');
    this._buttons[IPP1Buttons.RIGHT] = findByName(meshes, 'Button.Right');
    this._buttons[IPP1Buttons.DOWN] = findByName(meshes, 'Button.Down');
    this._buttons[IPP1Buttons.ESC] = findByName(meshes, 'Button.ESC');
    this._buttons[IPP1Buttons.ENTER] = findByName(meshes, 'Button.Enter');
    this._buttons[IPP1Buttons.F1] = findByName(meshes, 'Button.F1');
    this._buttons[IPP1Buttons.F2] = findByName(meshes, 'Button.F2');
    this._buttons[IPP1Buttons.F3] = findByName(meshes, 'Button.F3');
    const connectors = findByName(meshes, 'Connectors');
    this._corpus = findByName(meshes, 'Corpus');

    this._clips = {
      black: new IPP1SingleClipModel(scene, model, 'Black'),
      yellow: new IPP1SingleClipModel(scene, model, 'Yellow'),
      red: new IPP1SingleClipModel(scene, model, 'Red'),
    };
    this._clipLeftHelpers = {
      black: findByName(transformNodes, 'Helper_Left_Black'),
      yellow: findByName(transformNodes, 'Helper_Left_Yellow'),
      red: findByName(transformNodes, 'Helper_Left_Red'),
    };
    this._clipRightHelpers = {
      black: findByName(transformNodes, 'Helper_Right_Black'),
      yellow: findByName(transformNodes, 'Helper_Right_Yellow'),
      red: findByName(transformNodes, 'Helper_Right_Red'),
    };

    this._bodyMeshes = [
      ...this._buttons,
      display,
      this._display2,
      connectors,
      this._corpus,
    ];

    this.setVisibility(false);
  }

  static async load(scene: Scene, cfg: IPP1Config): Promise<IPP1Model> {
    const imagesPromise: Promise<HTMLImageElement>[] = [];

    const uiRootUrl = getRootUrlForObject(
      cfg.ui.root_url,
      this.MODEL_VERSION,
      cfg.ui.folder
    );

    for (let i = 0; i < 12; i += 1)
      imagesPromise.push(loadJSImage(`${uiRootUrl}${i}.png`));

    const [model, uiImages] = await Promise.all([
      loadMesh(scene, cfg.model, this.MODEL_VERSION),
      Promise.all(imagesPromise),
    ]);

    return new IPP1Model(scene, model, uiImages);
  }

  setDisplayMaterial(material: Material): void {
    this._display2.material = material;
  }

  setVisibility(isVisible: boolean): void {
    for (const m of this._bodyMeshes) m.isVisible = isVisible;
  }
}
