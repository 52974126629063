import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import {
  IPP1ClipConnectTarget,
  IPP1ClipConnectType,
  IPP1Config,
} from './types';
import { IPP1Store } from './store';
import { ToolsType } from '../types';

export function setupLogic(
  store: LessonStore,
  objStore: IPP1Store,
  cfg: IPP1Config
): void {
  const { xr } = store.core;
  const { hands, inventory } = store.player;
  if (!xr || !hands || !inventory)
    throw Error(
      'Cannot configure without core.xr, player.hands, or player.inventory'
    );

  const minorHandId = {
    type: IPP1ClipConnectType.PLAYER_HAND,
    num: 0,
  };

  const onToolId = (i: number) => {
    return {
      type: IPP1ClipConnectType.TOOL,
      num: i,
    };
  };

  const onKIPId = (i: number) => {
    return {
      type: IPP1ClipConnectType.KIP_TERMINAL,
      num: i,
    };
  };

  const onEMCVEId = (i: number) => {
    return {
      type: IPP1ClipConnectType.EMCVE_TERMINAL,
      num: i,
    };
  };

  // Установка начальных значений
  objStore.clips.black.setConnectStatus(onToolId(0));
  objStore.clips.red.setConnectStatus(onToolId(1));
  objStore.clips.yellow.setConnectStatus(onToolId(2));

  // Установка мешей
  autorun(() => {
    const clipHelpers = hands.leftHandIsMain
      ? objStore.clipRightHelpers
      : objStore.clipLeftHelpers;

    const targets: IPP1ClipConnectTarget[] = [
      {
        id: minorHandId,
        bbox: hands.minorHand.bbox,
        helper: hands.minorHand.helperTake,
      },
      {
        id: onToolId(0),
        bbox: objStore.clips.black.bbox, // TODO: Неправильно указан bbox
        helper: clipHelpers.black,
      },
      {
        id: onToolId(1),
        bbox: objStore.clips.red.bbox, // TODO: Неправильно указан bbox
        helper: clipHelpers.red,
      },
      {
        id: onToolId(2),
        bbox: objStore.clips.yellow.bbox, // TODO: Неправильно указан bbox
        helper: clipHelpers.yellow,
      },
    ];
    store.scene.KIP &&
      store.scene.KIP.model.terminals.measure.forEach((m, id) => {
        targets.push({
          id: onKIPId(id),
          bbox: m.body,
          helper: m.helper,
        });
      });
    store.scene.EMCVE &&
      store.scene.EMCVE.model.terminals.forEach((m, id) => {
        targets.push({
          id: onEMCVEId(id),
          bbox: m.body,
          helper: m.helper,
        });
      });
    objStore.clips.setConnectTargets(targets);
  });

  // Установка видимости
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.IPP1;
    const isVisible = isSelected && !hands.mainHand.animating;
    objStore.setVisibility(isVisible);
  });

  // Установка родителя прибора
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.IPP1;
    objStore.moveToParent(isSelected ? hands.mainHand.helperTake : null);
  });

  // Работа с кнопками
  autorun(() => {
    const { buttonDown } = objStore.buttons;
    if (buttonDown === undefined) return;
    objStore.display.processButtonPress(buttonDown);
  });

  // Установка индивидуальной логики для кажого крокодила
  for (const clip of objStore.clips.list) {
    // Установка видимости
    autorun(() => {
      const isSelected = inventory.selectedTool === ToolsType.IPP1;
      const isConnected =
        clip.connectStatus?.type === IPP1ClipConnectType.KIP_TERMINAL;
      const isDoorOpen =
        store.scene.KIP?.frontDoor.targetIsOpen === true &&
        store.scene.KIP?.frontDoor.isOpen === true;
      const isVisible =
        isConnected ||
        (isSelected &&
          (!hands.minorHand.animating || !clip.inHand) &&
          !hands.mainHand.animating);
      clip.setVisibility(isVisible && isDoorOpen);
    });

    // Установка подсветки
    autorun(() => {
      clip.setHighlighted(clip.intersection.marks.size > 0);
    });

    // Включение необходимых хуков на пересечение
    autorun(() => {
      const targetTypes: IPP1ClipConnectType[] = [];
      if (clip.connectStatus?.type === IPP1ClipConnectType.PLAYER_HAND) {
        targetTypes.push(IPP1ClipConnectType.KIP_TERMINAL);
        targetTypes.push(IPP1ClipConnectType.EMCVE_TERMINAL);
      }
      if (!objStore.clips.inHand)
        targetTypes.push(IPP1ClipConnectType.PLAYER_HAND);
      clip.enableIntersectsFor(new Set(targetTypes));
    });

    let triggerDown = false;
    autorun(() => {
      const isSelected = inventory.selectedTool === ToolsType.IPP1;
      if (!isSelected) return;

      const controller = hands.leftHandIsMain
        ? xr.controllers.right
        : xr.controllers.left;
      if (!controller) return;
      if (controller.buttons.standardTriggerDown) {
        triggerDown = true;
        return;
      }
      if (!triggerDown) return;
      triggerDown = false;

      const intersectId: number | undefined = clip.intersection.marks
        .values()
        .next().value;
      if (intersectId === undefined) return;
      const connectTargetId = clip.connectTargets[intersectId].id;
      if (!objStore.clips.isTargetFree(connectTargetId)) return;
      clip.setConnectStatus(connectTargetId);
    });
  }
}
