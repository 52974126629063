import { LessonStore } from 'features/lesson-page/store';
import { autorun, reaction } from 'mobx';

import { HandState } from '../../player/hands/types';
import { SingleHandStore } from '../../player/hands/store';
import { TechBlockConfig } from './types';
import { TechBlockStore } from './store';

/**
 * Функция отвечает за установку состояния объекта по умолчаню, а так же
 * установку внешней логики (связи между различными объектами сцены).
 *
 * Для этого с помощью autorun отслеживаются изменения указанных сторов
 * объектов, после чего происходит изменение собственного стора.
 *
 * Например:
 *   autorun(() => {
 *     // Изменяет видимость объекта в зависимости от того открыта ли дверца KIP'а
 *     objStore.setVisibility(store.scene.KIP?.frontDoor.isOpen === true);
 *   });
 */

enum Hands {
  rightHand,
  leftHand,
}

export function setupLogic(
  store: LessonStore,
  objStore: TechBlockStore,
  cfg: TechBlockConfig
): void {
  objStore.setVisibility(true);
  const { hands } = store.player;
  if (!hands)
    throw Error('Cannot configure wheel rotation without player.hands');
  // Установка целевых объектов
  autorun(() => {
    if (store.player.hands) {
      objStore.wheel1.intersect.setTargets([
        { id: 0, mesh: hands.right.bbox },
        { id: 1, mesh: hands.left.bbox },
      ]);
    }
  });

  // Прикрепление рук к рулю при нажатии кнопки
  reaction(
    () => [
      store.core.xr?.controllers.right?.buttons.standardTriggerDown,
      store.core.xr?.controllers.left?.buttons.standardTriggerDown,
    ],
    ([isRightBtnDown, isLeftBtnDown]) => {
      const intersected = objStore.wheel1.intersect.marks;
      if (isRightBtnDown) {
        if (
          intersected.has(Hands.rightHand) &&
          !objStore.wheel1.rightHandOnWheel.status
        ) {
          objStore.wheel1.setRotationTarget(
            store.core.xr!.controllers.right!.pointer
          );
          objStore.wheel1.moveToWheel(hands.right, 0);
        }
      } else if (objStore.wheel1.rightHandOnWheel.status) {
        // вернуть руку на место
        moveFromWheel(hands.right);
        objStore.wheel1.setRotationTarget(null);
      }
      if (isLeftBtnDown) {
        if (
          isLeftBtnDown &&
          intersected.has(Hands.leftHand) &&
          !objStore.wheel1.leftHandOnWheel.status
        ) {
          objStore.wheel1.setRotationTarget(
            store.core.xr!.controllers.left!.pointer
          );
          objStore.wheel1.moveToWheel(hands.left, 0);
        }
      } else if (objStore.wheel1.leftHandOnWheel.status) {
        // вернуть руку на место
        moveFromWheel(hands.left);
        objStore.wheel1.setRotationTarget(null);
      }
    }
  );

  /**
   * Убрать руку с руля
   */
  function moveFromWheel(hand: SingleHandStore): void {
    if (
      store.core.xr?.controllers.right?.pointer &&
      store.core.xr?.controllers.left?.pointer
    ) {
      // TODO исправить, возможно возникновение гонки (race conditions).
      hand.setTargetState(HandState.FIXED);
      objStore.wheel1.setHandOnWheel(hand.isRightHand, {
        status: false,
        helper: null,
      });
      hand.root.parent = hand.isRightHand
        ? store.core.xr.controllers.right.pointer
        : store.core.xr.controllers.left.pointer;
    }
  }
}
