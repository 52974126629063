import { LessonType } from 'api';

import { LessonScene } from 'features/lesson-page/scene-components/init';
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

/**
 * Установка логики для панели результатов
 */
export function setupLogicForSceneResultPanel(scene: LessonScene): void {
  if (!scene.scene?.resultPanel || !lessonStore.scene.resultPanel) return;
  const obj = scene.scene.resultPanel;
  const objStore = lessonStore.scene.resultPanel;

  // При перелистывании страничек
  obj.onRightButtonClick.add(() => {
    objStore.openPage(objStore.pageNo + 1);
  });
  obj.onLeftButtonClick.add(() => {
    objStore.openPage(objStore.pageNo - 1);
  });

  // При закрытии окна
  obj.onCloseButtonClick.add(() => {
    objStore.setVisibility(false);
  });

  if (lessonStore.lessonType === LessonType.Exam)
    obj.onCloseButtonClick.add(() => {
      lessonStore.isExamResultsPanelVisible = true;
    });
}
