import { makeAutoObservable } from 'mobx';

export type InfoPageContentType = {
  fullName: string;
  lessonTitle: string;
  lessonDuration: string;
  lessonMode: string;
};

export type StepsPageContentLineType = {
  name: string;
  errorCount: string;
  stepDuration: string;
};

export class StepsPageContentType {
  private _lines: StepsPageContentLineType[] = [];

  public get lines(): StepsPageContentLineType[] {
    return this._lines;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public setContent(cnt: StepsPageContentLineType[]): void {
    this._lines.splice(0, this._lines.length);
    for (const line of cnt) {
      makeAutoObservable(line);
      this._lines.push(line);
    }
  }
}
