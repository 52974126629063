import { ModelConfig } from '../../common/types';

export type KeyConfig = {
  model: ModelConfig;
};

export enum KeyState {
  OPENED,
  IN_PROCESS,
  CLOSED,
}
