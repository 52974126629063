import { BaseController } from './controller';

export class BaseObject<StoreType, ConfigType> extends BaseController<
  StoreType,
  ConfigType
> {
  /**
   * Зарегистрировать объект для вызова setupLogic
   * @param object объект, который будет зарегистрирован
   */
  protected registerObject(object: BaseController<unknown, unknown>): void {
    this.registerController(object);
  }
}
