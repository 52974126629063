import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';

import { CraneUnitConfig } from './types';
import { CraneUnitModel } from './model';
import { CraneUnitStore } from './store';
import { setupLogic } from './logic';

export class CraneUnitObject extends BaseModelObject<
  CraneUnitModel,
  CraneUnitStore,
  CraneUnitConfig
> {
  constructor(
    scene: Scene,
    model: CraneUnitModel,
    store: CraneUnitStore,
    cfg: CraneUnitConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  public static async setup(
    scene: Scene,
    cfg: CraneUnitConfig
  ): Promise<CraneUnitObject> {
    const model = await CraneUnitModel.load(scene, cfg);
    const store = new CraneUnitStore(model);
    return new CraneUnitObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: CraneUnitStore, cfg: CraneUnitConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }
}
