import { DebugLayer, Engine, Scene, UniversalCamera } from '@babylonjs/core';

import { FullscreenPanelObject } from '../objects/fullscreen-panel';
import { SceneObject } from '../objects/scene';
import { ToolsObject } from '../objects/tools';

import { CoreObject } from '../objects/core';
import { PlayerObject } from '../objects/player';
import { WorldObject } from '../objects/world';

export class LessonScene {
  private _engine: Engine;
  private _scene: Scene;

  public camera: UniversalCamera | null = null;

  public core?: CoreObject;
  public fullscreenPanel?: FullscreenPanelObject;
  public player?: PlayerObject;

  public scene?: SceneObject;
  public tools?: ToolsObject;
  public world?: WorldObject;

  public get babylonScene(): Scene {
    return this._scene;
  }

  public get debugLayer(): DebugLayer {
    return this._scene.debugLayer;
  }

  constructor(engine: Engine, scene: Scene) {
    this._engine = engine;
    this._scene = scene;
  }

  public runRenderLoop(): void {
    this._engine.runRenderLoop(() => {
      this._scene.render();
    });
  }

  public resize(): void {
    this._engine.resize();
  }
}
