import { Measure } from '@babylonjs/gui';

export type CornerMeasure = {
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
};

export function drawRoundedRect(
  context: CanvasRenderingContext2D,
  box: Measure,
  round: CornerMeasure | number,
  offset = 0
): void {
  let { left: x, top: y, width: w, height: h } = box;
  x += offset;
  y += offset;
  w -= offset * 2;
  h -= offset * 2;
  if (h <= 0 || w <= 0) return;

  let topLeft;
  let topRight;
  let bottomRight;
  let bottomLeft;
  if (typeof round === 'number') {
    const r = Math.min(Math.min(w, h) / 2, round);

    topLeft = r;
    topRight = r;
    bottomRight = r;
    bottomLeft = r;
  } else {
    topLeft = round.topLeft;
    topRight = round.topRight;
    bottomRight = round.bottomRight;
    bottomLeft = round.bottomLeft;
  }

  // TODO: Если, например, topLeft + topRight > box.width, то будет не квадрат

  topLeft = Math.max(0, topLeft - offset);
  topRight = Math.max(0, topRight - offset);
  bottomRight = Math.max(0, bottomRight - offset);
  bottomLeft = Math.max(0, bottomLeft - offset);

  context.beginPath();
  context.moveTo(x + topLeft, y);
  context.lineTo(x + w - topRight, y);
  context.quadraticCurveTo(x + w, y, x + w, y + topRight);
  context.lineTo(x + w, y + h - bottomRight);
  context.quadraticCurveTo(x + w, y + h, x + w - bottomRight, y + h);
  context.lineTo(x + bottomLeft, y + h);
  context.quadraticCurveTo(x, y + h, x, y + h - bottomLeft);
  context.lineTo(x, y + topLeft);
  context.quadraticCurveTo(x, y, x + topLeft, y);
  context.closePath();
}
