import {
  AbstractActionManager,
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  IAction,
  Observable,
  Scene,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseModelController } from '../base';
import { IntersectionStore } from './store';
import { IntersectionTarget } from './types';

export class IntersectionController extends BaseModelController<
  AbstractMesh,
  IntersectionStore,
  undefined
> {
  private _manager: AbstractActionManager;
  private _actions: IAction[] = [];

  public onIntersection = new Observable<[number, boolean]>();

  constructor(scene: Scene, model: AbstractMesh, store: IntersectionStore) {
    super(scene, model, store, undefined);

    this._manager = model.actionManager || new ActionManager(scene);
    model.actionManager = this._manager;
  }

  protected _connectToStore(store: IntersectionStore, cfg: undefined): void {
    autorun(() => {
      this.clearIntersectionHook();
      store.isEnabled && this.setupIntersectionHook(store.targets);
    });
    this.onIntersection.add((e) => {
      store.markTarget(e[0], e[1]);
    });
  }

  private clearIntersectionHook(): void {
    this._actions.forEach((a) => {
      this._manager.unregisterAction(a);
    });
    this._actions = [];
  }

  private setupIntersectionHook(targets: IntersectionTarget[]): void {
    targets.forEach((target) => {
      const actionParams = {
        mesh: target.mesh,
        usePreciseIntersection: true,
      };
      const enterAction = this._manager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersection.notifyObservers([target.id, true]);
          }
        )
      );
      const exitAction = this._manager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionExitTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersection.notifyObservers([target.id, false]);
          }
        )
      );

      enterAction && this._actions.push(enterAction);
      exitAction && this._actions.push(exitAction);
    });
  }
}
