import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { useRootData } from 'hooks';

import css from './index.module.css';

const FinishButton: FC = observer(() => {
  const { t } = useTranslation();
  const { finishLesson } = useRootData((store) => ({
    finishLesson: store.lesson.finishLesson,
  }));

  const handleFinishLesson = () => finishLesson();

  return (
    <div className={css.finishButtonWrapper}>
      <Button onClick={handleFinishLesson}>{t('lesson.finishLesson')}</Button>
    </div>
  );
});

export default FinishButton;
