/* eslint-disable no-console */

export class AudioHint {
  private fileName: string;

  private sound: HTMLAudioElement | null;
  public isPlaying: boolean;

  constructor(fileName: string) {
    if (!fileName) {
      console.error('Either scene or file name is undefined');
    }
    this.fileName = fileName;
    this.sound = null;
    this.isPlaying = false;

    this.loadAudio();
  }

  loadAudio(): void {
    try {
      this.sound = new Audio(this.fileName);
      this.sound.onplay = () => {
        this.isPlaying = true;
      };
      this.sound.onpause = () => {
        this.isPlaying = false;
      };
    } catch {
      console.error(`Failed to load audiofile ${this.sound}`);
    }
  }

  playSound(): void {
    this.sound?.play().catch((e) => {
      console.error(`Failed to decode or play audio for '${this.fileName}'`);
    });
  }

  pauseSound(): void {
    try {
      this.sound?.pause();
    } catch {
      console.error(`Failed to pause audio for ${this.fileName}`);
    }
  }
}
