import { action, makeAutoObservable } from 'mobx';

export interface ISkyboxStore {
  /**
   * Видемость объекта
   */
  get isVisible(): boolean;
}

export class SkyboxStore implements ISkyboxStore {
  private _isVisible = true;

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor() {
    makeAutoObservable(this, {
      setVisibility: action,
    });
  }

  /**
   * Установить видемость объекта
   * @param isVisible виден ли объект
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
