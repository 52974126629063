import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { TubeConfig } from './types';
import { TubeModel } from './model';
import { TubeStore } from './store';
import { setupLogic } from './logic';

export class TubeObject extends BaseModelObject<
  TubeModel,
  TubeStore,
  TubeConfig
> {
  private _intersect: IntersectionController;

  constructor(
    scene: Scene,
    model: TubeModel,
    store: TubeStore,
    cfg: TubeConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersect = new IntersectionController(
      scene,
      model.tube,
      store.intersect
    );
    this.registerController(this._intersect);
  }

  protected _connectToStore(store: TubeStore, cfg: TubeConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }

  public static async setup(
    scene: Scene,
    cfg: TubeConfig
  ): Promise<TubeObject> {
    const model = await TubeModel.load(scene, cfg);
    const store = new TubeStore(model.root);
    return new TubeObject(scene, model, store, cfg);
  }
}
