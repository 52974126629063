import { Measure } from '@babylonjs/gui';

import { RoundedSquareButton } from 'features/lesson-page/scene-components/widgets';
import { drawRoundedRect } from 'features/lesson-page/scene-components/draw-utils/rounded-rect';

export enum ArrowButtonType {
  RIGHT = 0,
  DOWN = 1,
  LEFT = 2,
  UP = 3,
}

/*
  Кнопка стрелка
*/
export class ArrowButton extends RoundedSquareButton {
  private _ARROW_ROUND_RATIO = 0.2;
  private _LINE_THICKNESS = 0.15;
  private _LINE_LEN = 0.5;
  private _ARROW_COLOR = '#5f5f5f';

  private _arrowType: ArrowButtonType;

  constructor(arrowType: ArrowButtonType, name?: string) {
    super(0.2, name);

    this._arrowType = arrowType;
    this.background = '#d6d6d6';
  }

  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();
    super._localDraw(context); // Отображение кнопки

    const { left, top, width, height } = this._currentMeasure;

    const radius = height * this._ARROW_ROUND_RATIO;
    const lineWidth = height * this._LINE_THICKNESS;
    const lineHeight = height * this._LINE_LEN;
    const box = new Measure(
      -lineWidth / 2,
      -lineWidth / 2,
      lineWidth,
      lineHeight
    );

    context.fillStyle = this._ARROW_COLOR;

    // center of button
    context.translate(left + width / 2, top + height / 2);
    context.rotate((Math.PI / 2) * this._arrowType.valueOf());

    context.translate(-(lineWidth - lineHeight) / Math.SQRT2 / 2, 0);

    // Нижняя часть >
    context.rotate(Math.PI / 4);
    drawRoundedRect(context, box, radius);
    context.fill();

    // Верхняя часть >
    context.rotate(Math.PI / 2);
    drawRoundedRect(context, box, radius);
    context.fill();

    context.restore();
  }
}
