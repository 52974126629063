import {
  AbstractMesh,
  ActionManager,
  Color3,
  ExecuteCodeAction,
  HighlightLayer,
  Mesh,
  Observable,
  Scene,
} from '@babylonjs/core';

export class KIPLabelsController {
  private _mesh: AbstractMesh[];
  private _scene: Scene;
  private _highlightLayer: HighlightLayer;
  private _labels: string[] = [];

  public onPointerClick = new Observable<number>();
  public onIntersectionEnterLabel = new Observable<number>();
  public onIntersectionExitLabel = new Observable<number>();

  /**
   * Получение количества надписей
   */
  public get labelsCount(): number {
    return this._labels.length;
  }

  constructor(
    scene: Scene,
    mesh: AbstractMesh[],
    highlightLayer: HighlightLayer
  ) {
    this._highlightLayer = highlightLayer;
    this._mesh = mesh;
    this._scene = scene;
    mesh.forEach((l, index) => {
      const label = l;
      this._labels.push(label.name);
      // Создать менеджер действий
      if (!label.actionManager) {
        label.actionManager = new ActionManager(scene);
      }

      // При нажатии на объект
      label.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          this.onPointerClick.notifyObservers(index);
          // Убрать подсветку
          // TODO: убрать highlight в стор (как у дверей КИПа)
          this._highlightLayer.removeMesh(label as Mesh);
        })
      );
      // Подсветить надписи
      this._highlightLayer.addMesh(label as Mesh, Color3.Green());
    });
  }

  public setupLabelIntersection(controller: number, mesh: AbstractMesh): void {
    this._mesh.forEach((label, idx: number) => {
      if (!label.actionManager) {
        label.actionManager = new ActionManager(this._scene);
      }
      const actionParams = {
        mesh,
        usePreciseIntersection: true,
      };
      label.actionManager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: actionParams,
          },
          () => {
            this._highlightLayer.removeMesh(label as Mesh);
            this.onIntersectionEnterLabel.notifyObservers(idx);
          }
        )
      );
      label.actionManager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionExitTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersectionExitLabel.notifyObservers(idx);
          }
        )
      );
    });
  }
}
