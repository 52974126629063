import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { TechBlockConfig } from './types';
import { WheelModel } from '../../common/wheel';
import { findByName, loadMesh } from '../../common/utils';

/**
 * Общедоступные части модели.
 *
 * Из любой части программы можно получить доступ к полям, описанным в этом
 * интерфейсе. Используется в для привязки других объектов к разным частям
 * этой модели, а также для проверки пересечения объектов с этой моделью
 */
export interface ITechBlockModel {
  get root(): TransformNode;
  // get someMesh(): AbstractMesh;
}

/**
 * Класс представляющий модель объекта.
 *
 * Класс предоставляет доступ к разным частям модели
 */
export class TechBlockModel implements ITechBlockModel {
  public static readonly MODEL_VERSION = 'v0001';

  private _allMeshes: AbstractMesh[];
  private _root: TransformNode;
  private _wheels: WheelModel[];

  public get root(): TransformNode {
    return this._root;
  }

  get wheel(): WheelModel[] {
    return this._wheels;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    const { meshes } = model;

    this._allMeshes = meshes;
    [this._root] = meshes;
    this._root.position.y = -0.7;

    const wheelMesh = findByName(meshes, 'SteeringWheel.004') as Mesh;
    const wheelMeshes = meshes.filter((child) => {
      return child.id.includes('SteeringWheel');
    });
    this._wheels = [];
    wheelMeshes.forEach((mesh) => {
      this._wheels.push({
        name: mesh.id,
        mesh: wheelMesh,
        rightHandHelpers: wheelMesh
          .getChildren()
          .filter((child) =>
            child.id.includes('Helper_SteeringWheel_Right')
          ) as TransformNode[],
        leftHandHelpers: wheelMesh
          .getChildren()
          .filter((child) =>
            child.id.includes('Helper_SteeringWheel_Left')
          ) as TransformNode[],
      });
    });
  }

  static async load(
    scene: Scene,
    cfg: TechBlockConfig
  ): Promise<TechBlockModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new TechBlockModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }
}
