import {
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  Observable,
  Scene,
} from '@babylonjs/core';

import { KIPWireModel } from '../model';

export class KIPWireController {
  private _scene: Scene;
  private _model: KIPWireModel;
  private _isConnected = true;
  private _isAnimating = false;

  public onIntersectionEnter = new Observable<void>();

  constructor(scene: Scene, model: KIPWireModel) {
    this._scene = scene;
    this._model = model;

    this._model.defectiveAnim.onAnimationGroupEndObservable.add(() => {
      this._onAnimComplete();
    });
    this._model.properlyAnim.onAnimationGroupEndObservable.add(() => {
      this._onAnimComplete();
    });

    // Создать менеджер действий
    if (!this._model.mesh.actionManager)
      this._model.mesh.actionManager = new ActionManager(scene);

    // При нажати на объект
    this._model.mesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
        this.onIntersectionEnter.notifyObservers();
      })
    );
  }

  public setupWireIntersection(controller: number, mesh: AbstractMesh): void {
    if (!this.model.mesh.actionManager) {
      this.model.mesh.actionManager = new ActionManager(this._scene);
    }
    const actionParams = {
      mesh,
      usePreciseIntersection: true,
    };
    this.model.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: actionParams,
        },
        () => {
          this.onIntersectionEnter.notifyObservers();
        }
      )
    );
  }

  private _onAnimComplete() {
    this._isAnimating = false;
  }

  private _updateWireState(newConnected: boolean) {
    if (newConnected === this._isConnected) return;
    if (this._isAnimating) return;
    this._isAnimating = true;
    this._isConnected = newConnected;

    const { properlyAnim } = this._model;
    const { defectiveAnim } = this._model;
    // Воспроизвести анимацию
    if (this._isConnected) properlyAnim.start();
    else defectiveAnim.start();
  }

  /**
   * Изменить состояние провода
   * @param targetIsOpen Состояние, в которое будет установлен провод
   */
  public setWireState(targetIsOpen: boolean): void {
    this._updateWireState(targetIsOpen);
  }

  get model(): KIPWireModel {
    return this._model;
  }
}
