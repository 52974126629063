import { TransformNode } from '@babylonjs/core';

import { action, autorun, makeAutoObservable } from 'mobx';

import { IXRControllerStore, XRControllerStore } from './controller';
import {
  IXRControllersButtonsStore,
  XRControllersButtonsStore,
} from './controllers-buttons';

export interface IXRControllersStore {
  get left(): IXRControllerStore | undefined;
  get right(): IXRControllerStore | undefined;

  get list(): (IXRControllerStore | undefined)[];
  get buttons(): IXRControllersButtonsStore;
}

export class XRControllersStore implements IXRControllersStore {
  private _left?: XRControllerStore;
  private _right?: XRControllerStore;
  private _buttons: XRControllersButtonsStore;

  public get left(): XRControllerStore | undefined {
    return this._left;
  }

  public get right(): XRControllerStore | undefined {
    return this._right;
  }

  public get list(): (XRControllerStore | undefined)[] {
    return [this._left, this._right];
  }

  public get buttons(): XRControllersButtonsStore {
    return this._buttons;
  }

  constructor() {
    this._buttons = new XRControllersButtonsStore();
    makeAutoObservable(this, {
      addController: action,
    });

    autorun(() => {
      this._buttons.setA(this.list.some((c) => c?.buttons.aDown));
    });
  }

  public addController(
    isLeft: boolean,
    pointer: TransformNode
  ): XRControllerStore {
    const controller = new XRControllerStore(isLeft ? 0 : 1, isLeft, pointer);
    if (isLeft) this._left = controller;
    else this._right = controller;
    return controller;
  }
}
