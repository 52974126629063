import { HemisphericLight, Light, Scene, Vector3 } from '@babylonjs/core';

import { LightsConfig } from './types';

export class LightsModel {
  private _lights: Light[];

  get lights(): Light[] {
    return this._lights;
  }

  constructor(scene: Scene, lights: Light[]) {
    this._lights = lights;
  }

  static load(scene: Scene, cfg: LightsConfig): Promise<LightsModel> {
    const res: Light[] = [];
    for (const c of cfg.lights) {
      const d = new Vector3(c.diraction.x, c.diraction.y, c.diraction.z);
      const light = new HemisphericLight('light', d, scene);
      light.intensity = c.intensity;
      res.push(light);
    }
    return Promise.resolve(new LightsModel(scene, res));
  }
}
