import { Observable } from '@babylonjs/core';
import { Vector2WithInfo } from '@babylonjs/gui';
import { autorun } from 'mobx';

import {
  StepsPanelContent,
  StepsPanelItemStatus,
  StepsPanelWidget,
} from '../widget';

import { StepsPanelStore } from '../store';

export class StepsPanelWrap {
  private _content: StepsPanelContent;
  private _widget: StepsPanelWidget;
  private _curStepNum = 0;

  public onPointerClick = new Observable<Vector2WithInfo>();

  constructor(widget: StepsPanelWidget) {
    this._widget = widget;
    this._content = widget.content;

    // Перенаправление callback'ов
    this._widget.onPointerClickObservable.add((e) => {
      this.onPointerClick.notifyObservers(e);
    });
  }

  /**
   * Подключить объект к его стору
   */
  public connectToStore(store: StepsPanelStore): void {
    // Установка номера текущего шага
    autorun(() => {
      const { activeStepNo } = store;
      this.setCurrentStepNum(activeStepNo);
      const showDeltaStep = store.isOpen ? 4 : 0;
      this.showLines(
        Math.max(activeStepNo - showDeltaStep, 0),
        Math.min(activeStepNo + showDeltaStep + 1, store.steps.length)
      );
    });

    autorun(() => {
      this.setSteps(store.steps);
    });
  }

  getStepsCount(): number {
    return this._content.getLineCount();
  }

  _updateStepStatus(): void {
    const cnt = this._content;

    for (let i = 0; i < this._curStepNum; i += 1)
      cnt.updateLineStatus(i, StepsPanelItemStatus.PASSED);

    cnt.updateLineStatus(this._curStepNum, StepsPanelItemStatus.ACTIVE);

    const stepsCount = cnt.getLineCount();
    for (let i = this._curStepNum + 1; i < stepsCount; i += 1)
      cnt.updateLineStatus(i, StepsPanelItemStatus.NOT_STARTED);
  }

  setSteps(stepName: string[]): void {
    const cnt = this._content;

    const status: StepsPanelItemStatus[] = [];
    for (let i = 0; i < stepName.length; i += 1) {
      if (i < this._curStepNum) status.push(StepsPanelItemStatus.PASSED);
      else if (i === this._curStepNum) status.push(StepsPanelItemStatus.ACTIVE);
      else status.push(StepsPanelItemStatus.NOT_STARTED);
    }
    cnt.setSteps(stepName, status);
  }

  setCurrentStepNum(stepNum: number): void {
    if (this._curStepNum === stepNum) return;
    this._curStepNum = stepNum;
    this._updateStepStatus();
  }

  showLines(start: number, end: number): void {
    this._widget.showLines(start, end);
  }
}
