import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { InfoPanelConfig, InfoPanelPlaceType } from './types';
import { InfoPanelStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: InfoPanelStore,
  cfg: InfoPanelConfig
): void {
  objStore.setVisibility(true);

  const placeType = cfg.place as InfoPanelPlaceType;
  if (!Object.values(InfoPanelPlaceType).includes(placeType)) {
    const av = Object.values(InfoPanelPlaceType);
    throw Error(
      `Place type '${placeType}' not found for InfoPanel. Available place types: ${av}`
    );
  }

  const requiredError = (objName: string) => {
    return Error(
      `${objName} is required for the selected place type (${placeType}), but is undefined`
    );
  };

  switch (placeType) {
    case InfoPanelPlaceType.KIP_LEFT: {
      if (!store.scene.KIP) throw requiredError('scene.KIP');
      objStore.moveTo(InfoPanelPlaceType.KIP_LEFT, store.scene.KIP.model.main);
    }
  }

  autorun(() => {
    objStore.setContent(store.script.currentStep?.shortHint || '');
  });
}
