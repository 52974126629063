import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { KeyConfig } from './types';
import { KeyStore } from './store';
import { ToolsType } from '../types';

export function setupLogic(
  store: LessonStore,
  objStore: KeyStore,
  cfg: KeyConfig
): void {
  const { hands, inventory } = store.player;
  if (!hands || !inventory)
    throw Error('Cannot configure without player.hands or player.inventory');

  // Установка целевых объектов
  autorun(() => {
    if (!store.scene.KIP) return;
    objStore.intersect.setTargets([
      { id: 0, mesh: store.scene.KIP.model.keyHoleFront.bbox },
    ]);
  });
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.KEY;
    const isAttached = objStore.attached;
    const setHooks = isSelected && isAttached;

    objStore.intersect.setEnabled(isSelected && !isAttached);
    objStore.setDistanceTarget(setHooks ? hands.mainHand.root : null);
  });

  // Установка видимости ключа
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.KEY;
    const isHandsAnimating = hands.animating;
    const isAttached = objStore.attached;

    objStore.setVisibility((isSelected && !isHandsAnimating) || isAttached);
  });

  // Установка родителя ключа
  autorun(() => {
    if (!store.scene.KIP) return;
    const isSelected = inventory.selectedTool === ToolsType.KEY;
    const isAttached = objStore.attached;

    if (isAttached) {
      objStore.moveToKeyhole(store.scene.KIP.model.keyHoleFront.helper);
    } else if (isSelected) {
      objStore.moveToHand(hands.mainHand.helperTake);
    } else {
      objStore.moveToHand(null);
    }
  });

  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.KEY;
    const isAttached = objStore.attached;

    if (!isAttached || !isSelected) return;
    const ctrls = store.core.xr?.controllers;
    const controller = hands.leftHandIsMain ? ctrls?.left : ctrls?.right;
    if (!controller) return;
    objStore.axisRotationAccumulator.setTargetAxis(controller.defaultXAxis);
    objStore.axisRotationAccumulator.addQuaternion(controller.rotationDeltaQ);
  });

  // Проверка вставлен ли ключ в замочную скважину
  autorun(() => {
    if (objStore.intersect.isEnabled && objStore.intersect.marks.size !== 0)
      objStore.setAttached(true);
    if (objStore.detachBecauseDistance) objStore.setAttached(false);
  });
}
