import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../intersection';

import { HandOnWheel } from './types';
import { SingleHandStore } from '../../player/hands/store';
import { WheelModel } from './model';

export interface IWheelStore {
  /**
   * Сотояние правой руки на руле,
   * status - прикреплена ли рука к рулю,
   * helper - хелпер к которому прикреплена рука
   */
  get rightHandOnWheel(): HandOnWheel;
  /**
   * Сотояние левой руки на руле,
   * status - прикреплена ли рука к рулю,
   * helper - хелпер к которому прикреплена рука
   */
  get leftHandOnWheel(): HandOnWheel;
  get intersect(): IntersectionStore;
  /**
   * Установить объект относительно которого будет считатся вращение
   */
  setRotationTarget(target: TransformNode | null): void;
  /**
   * Переместить руку на руль
   */
  setHandOnWheel(isRight: boolean, status: HandOnWheel): void;
}

export class WheelStore implements IWheelStore {
  private _isEnabled = true;
  private _model: WheelModel;
  private _value: number;

  private _isOpen: boolean;
  private _maxRotation: number;

  private _intersect = new IntersectionStore();

  private _rightHandOnWheel: HandOnWheel = {
    status: false,
    helper: null,
  };

  private _leftHandOnWheel: HandOnWheel = {
    status: false,
    helper: null,
  };

  private _rotationTarget: TransformNode | null = null;

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public setEnabled(isEnabled: boolean): void {
    this._isEnabled = isEnabled;
  }

  public get rightHandOnWheel(): HandOnWheel {
    return this._rightHandOnWheel;
  }

  public get leftHandOnWheel(): HandOnWheel {
    return this._leftHandOnWheel;
  }

  /**
   * текущий угол поворота колеса
   */
  public get value(): number {
    return this._value;
  }

  /**
   * максимальный угол поворота колеса в радианах
   */
  public get maxRotation(): number {
    return this._maxRotation;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  /**
   * Объект относительно которого считается угол поворота
   */
  public get rotationTarget(): TransformNode | null {
    return this._rotationTarget;
  }

  public get isOpen(): boolean {
    return this._isOpen;
  }

  constructor(model: WheelModel) {
    this._model = model;
    this._value = 0;
    this._isOpen = false;
    this._maxRotation = 2 * Math.PI;
    makeAutoObservable(this, {
      moveToWheel: action,
      setHandOnWheel: action,
      setValue: action,
      addValue: action,
      setRotationTarget: action,
    });
  }

  /**
   * Установить угол вращения колеса
   */
  public setValue(rotation: number): void {
    this._value = rotation;
  }

  /**
   * Добавить угол вращения колеса
   */
  public addValue(rotation: number): void {
    this._value += rotation;
  }

  public setHandOnWheel(isRight: boolean, status: HandOnWheel): void {
    isRight
      ? (this._rightHandOnWheel = status)
      : (this._leftHandOnWheel = status);
  }

  /**
   * Установить объект относительно которого будет считатся вращение
   */
  public setRotationTarget(target: TransformNode | null): void {
    this._rotationTarget = target;
  }

  /**
   * Переместить руку на руль
   */
  public moveToWheel(hand: SingleHandStore, handTargetState: number): void {
    const helpers = hand.isRightHand
      ? this._model.rightHandHelpers
      : this._model.leftHandHelpers;
    const closestHelper = this.findClosestHelper(
      helpers,
      hand.bbox.absolutePosition
    );
    hand.root.parent = helpers[closestHelper];
    this.setHandOnWheel(hand.isRightHand, {
      status: true,
      helper: closestHelper,
    });
    // TODO исправить, возможно возникновение гонки (race conditions).
    hand.setTargetState(handTargetState);
  }

  /**
   * Найти ближайший не занятый хелпер на руле
   */
  private findClosestHelper(
    helpers: TransformNode[],
    position: Vector3
  ): number {
    let closestDistance = Infinity;
    let closestHelperIndex = 0;
    helpers.forEach((helper, index) => {
      if (
        index === this.rightHandOnWheel.helper ||
        index === this.leftHandOnWheel.helper
      )
        return;
      const distance = Vector3.DistanceSquared(
        helper.getAbsolutePosition(),
        position
      );
      if (distance < closestDistance) {
        closestDistance = distance;
        closestHelperIndex = index;
      }
    });
    return closestHelperIndex;
  }
}
