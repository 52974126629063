import { action, makeAutoObservable } from 'mobx';

import { EMCVEModel, IEMCVEModel } from './model';

export interface IEMCVEStore {
  /**
   * Модель
   */
  get model(): IEMCVEModel;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;
}

export class EMCVEStore implements IEMCVEStore {
  private _isVisible = true;
  private _model: EMCVEModel;

  public get model(): EMCVEModel {
    return this._model;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor(model: EMCVEModel) {
    this._model = model;

    makeAutoObservable(this, {
      setVisibility: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
