import { action, makeAutoObservable } from 'mobx';

import { ToolsType } from '../../tools';

export interface IInventoryStore {
  /**
   * Надеты ли перчатки
   */
  get gloveSelected(): boolean;

  /**
   * Какой инструмент выбран
   */
  get selectedTool(): ToolsType | undefined;
}

export class InventoryStore implements IInventoryStore {
  private _gloveSelected = false;
  private _selectedTool?: ToolsType;

  public get gloveSelected(): boolean {
    return this._gloveSelected;
  }

  public get selectedTool(): ToolsType | undefined {
    return this._selectedTool;
  }

  constructor() {
    makeAutoObservable(this, {
      setGloveSelected: action,
      setSelectedTool: action,
    });
  }

  /**
   * Установить одеты ли перчатки
   */
  public setGloveSelected(isSelected: boolean): void {
    this._gloveSelected = isSelected;
  }

  /**
   * Установить какой инструмент сейчас выбран
   */
  public setSelectedTool(selectedTool?: ToolsType): void {
    this._selectedTool = selectedTool;
  }
}
