import { Result } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { SmileOutlined } from '@ant-design/icons';

import css from './index.module.css';

const SuccessfulFinish: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Result
      className={css.successfulFinish}
      icon={<SmileOutlined />}
      title={t('lesson.lessonFinished')}
    />
  );
});

export default SuccessfulFinish;
