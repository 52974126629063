import { AbstractMesh } from '@babylonjs/core';
import { action, makeAutoObservable } from 'mobx';

export interface ILandscapeStore {
  /**
   * Виден ли объект
   */
  get isVisible(): boolean;

  /**
   * Пол, по которому будет ходить человек
   */
  get collisionFloor(): AbstractMesh;
}

export class LandscapeStore implements ILandscapeStore {
  private _isVisible = true;
  private _isCollisionEnabled = true;
  private _collisionFloor: AbstractMesh;

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get isCollisionEnabled(): boolean {
    return this._isCollisionEnabled;
  }

  public get collisionFloor(): AbstractMesh {
    return this._collisionFloor;
  }

  constructor(collisionFloor: AbstractMesh) {
    this._collisionFloor = collisionFloor;
    makeAutoObservable(this, {
      setVisibility: action,
      setCollisionEnabled: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  public setCollisionEnabled(isEnabled: boolean): void {
    this._isCollisionEnabled = isEnabled;
  }
}
