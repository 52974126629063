import { Scene } from '@babylonjs/core';

import { BaseController } from './controller';

export class BaseModelController<
  ModelType,
  StoreType,
  ConfigType
> extends BaseController<StoreType, ConfigType> {
  private __model: ModelType;

  /**
   * Модель
   */
  public get model(): ModelType {
    return this.__model;
  }

  constructor(
    scene: Scene,
    model: ModelType,
    store: StoreType,
    cfg: ConfigType
  ) {
    super(scene, store, cfg);
    this.__model = model;
  }
}
