import { autorun } from 'mobx';

import { LessonScene } from 'features/lesson-page/scene-components/init';
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

/**
 * Установка логики для окна запроса соответствия перемычек
 */
export function setupLogicForAcceptJumpersWindow(scene: LessonScene): void {
  if (
    !scene.scene?.acceptJumpersWindow ||
    !lessonStore.scene.acceptJumpersWindow
  )
    return;
  const objStore = lessonStore.scene.acceptJumpersWindow;
  const obj = scene.scene.acceptJumpersWindow;

  // При нажатии на кнопку подтверждения
  obj.onAcceptButtonClick.add(() => {
    objStore.setIsAccepted(true);
  });

  autorun(() => {
    objStore.setVisibility(
      lessonStore?.script?.currentStep?.doneCondition?.stateName ===
        'isKipJumpersPositionCorrect'
    );
  });
}
