import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

export interface ITabletStore {
  /**
   * Корень, за который можно привязывать объект
   */
  get root(): TransformNode;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Получить номер текущей записи планшета
   */
  get recordsId(): number;
}

export class TabletStore implements ITabletStore {
  private _visible = false;
  private _root: TransformNode;

  private _recordsId = 0;
  private _isEnabled = true;

  public get root(): TransformNode {
    return this._root;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get recordsId(): number {
    return this._recordsId;
  }

  /**
   * Показывать ли модальное окно
   */
  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  constructor(root: TransformNode) {
    this._root = root;
    makeAutoObservable(this, {
      setVisibility: action,
      moveToParent: action,
      addRecord: action,
      setEnabled: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  public moveToParent(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
    this._root.rotation = new Vector3(0, 0, 0);
  }

  /**
   * Добавить запись в планшет
   */
  public addRecord(): void {
    this._recordsId += 1;
  }

  public setEnabled(value: boolean): void {
    this._isEnabled = value;
  }
}
