import { ModelConfig } from '../../common/types';

export type KIPConfig = {
  model: ModelConfig;
};

export type JumpersConfig = {
  model: ModelConfig;
};

export enum KIPTerminalState {
  Normal,
  Highlighted,
  Dirty,
}

export type KIPObjectTerminalAction = {
  terminal_index: number;
  controller: number;
};
