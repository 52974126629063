import { AdvancedDynamicTexture, Control } from '@babylonjs/gui';
import { StepsPanelWidget, StepsPanelWrap } from '../steps-panel';

export class FullscreenPanelGUI {
  private STEPS_PANEL_TOP_PADDING = 40;

  private _stepsPanel: StepsPanelWrap;

  get stepsPanel(): StepsPanelWrap {
    return this._stepsPanel;
  }

  constructor(texture: AdvancedDynamicTexture, scale: number) {
    const stepsPanelWidget = new StepsPanelWidget(scale);
    stepsPanelWidget.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    stepsPanelWidget.topInPixels = this.STEPS_PANEL_TOP_PADDING * scale;
    this._stepsPanel = new StepsPanelWrap(stepsPanelWidget);
    texture.addControl(stepsPanelWidget);
  }
}
