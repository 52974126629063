import { action, makeAutoObservable } from 'mobx';

export interface IXRControllerButtonsStore {
  get standardTriggerDown(): boolean;
  get standardTriggerUp(): boolean;

  get aDown(): boolean;
  get aUp(): boolean;
}

// 0: "xr-standard-trigger"
// 1: "xr-standard-squeeze"
// 2: "xr-standard-thumbstick"
// 3: "a-button"
// 4: "b-button"
// 5: "thumbrest"

// 0: "xr-standard-trigger"
// 1: "xr-standard-squeeze"
// 2: "xr-standard-thumbstick"
// 3: "x-button"
// 4: "y-button"
// 5: "thumbrest"

export class XRControllerButtonsStore implements IXRControllerButtonsStore {
  private _standardTrigger = false;
  private _a = false;

  public get standardTriggerDown(): boolean {
    return this._standardTrigger;
  }

  public get standardTriggerUp(): boolean {
    return !this._standardTrigger;
  }

  public get aDown(): boolean {
    return this._a;
  }

  public get aUp(): boolean {
    return !this._a;
  }

  constructor() {
    makeAutoObservable(this, {
      setStandardTrigger: action,
      setA: action,
    });
  }

  public setStandardTrigger(isDown: boolean): void {
    this._standardTrigger = isDown;
  }

  public setA(isDown: boolean): void {
    this._a = isDown;
  }
}
