import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../../common/intersection';

export interface ITubeStore {
  /**
   * Корень, за который можно привязывать объект
   */
  get root(): TransformNode;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Номера клемм, которые на данный момент задеваются инструментом
   */
  get intersectedTerminals(): Set<number>;
}

export class TubeStore implements ITubeStore {
  private _visible = false;
  private _root: TransformNode;

  private _intersect = new IntersectionStore();

  public get root(): TransformNode {
    return this._root;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  public get intersectedTerminals(): Set<number> {
    return this._intersect.marks;
  }

  constructor(root: TransformNode) {
    this._root = root;
    makeAutoObservable(this, {
      setVisibility: action,
      moveToParent: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  public moveToParent(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
    this._root.rotation = new Vector3(0, 0, 0);
  }
}
