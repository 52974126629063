import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { LightsConfig } from './types';
import { LightsModel } from './model';
import { LightsStore } from './store';
import { setupLogic } from './logic';

export class LightsObject extends BaseModelObject<
  LightsModel,
  LightsStore,
  LightsConfig
> {
  constructor(
    scene: Scene,
    model: LightsModel,
    store: LightsStore,
    cfg: LightsConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  /**
   * Скачать все модели и создать Lights
   */
  public static async setup(
    scene: Scene,
    cfg: LightsConfig
  ): Promise<LightsObject> {
    const model = await LightsModel.load(scene, cfg);
    const store = new LightsStore();
    return new LightsObject(scene, model, store, cfg);
  }
}
