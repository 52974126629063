import { action, makeAutoObservable } from 'mobx';

import { randomShuffle } from 'features/lesson-page/store/utils';

import { KIPTerminalsModel } from '../model';

export class KIPTerminalsStore {
  private _terminalsCount: number;
  private _highlighted: boolean[];
  private _dirty: boolean[];
  private _checkTerminals: number[];
  private _looseTerminals: Set<number> = new Set();
  private _greasedTerminals: Set<number> = new Set();

  private _model?: KIPTerminalsModel;

  /**
   * Подсвечены ли клеммы
   */
  public get highlighted(): boolean[] {
    return this._highlighted;
  }

  /**
   * Грязные ли клеммы
   */
  public get dirty(): boolean[] {
    return this._dirty;
  }

  /**
   * Ослабленные клеммы
   */
  public get looseTerminals(): Set<number> {
    return this._looseTerminals;
  }

  /**
   * Все ли клеммы чистые
   */
  public get areAllClean(): boolean {
    return this._dirty.every((el) => !el);
  }

  /**
   * Все ли клеммы ослаблены
   */
  public get areAllLoose(): boolean {
    // TODO хранить состояние силовые/не силовых клемм отдельно
    return this._looseTerminals.size === this._terminalsCount - 4; // без учета  4х силовых клемм
  }

  /**
   * Все ли клеммы указанные в сценарии ослаблены
   */
  public get areCheckedTerminalsLoose(): boolean {
    return this._checkTerminals.every((terminal) => {
      return this._looseTerminals.has(terminal);
    });
  }

  /**
   * Все ли клеммы затянуты
   */
  public get areAllTight(): boolean {
    return !this._looseTerminals.size;
  }

  /**
   * Все ли клеммы обработаны вазелином
   */
  public get areAllGreased(): boolean {
    return this._greasedTerminals.size === this._terminalsCount;
  }

  public get model(): KIPTerminalsModel {
    return this._model!;
  }

  constructor(len: number, checkTerminals: number[]) {
    this._terminalsCount = len;
    this._checkTerminals = checkTerminals;
    this._highlighted = Array.from({ length: len }, () => false);
    this._dirty = Array.from({ length: len }, () => false);

    makeAutoObservable(this, {
      setHighlightedAll: action,
      setHighlighted: action,
      setDirtyAll: action,
      setDirtyRandom: action,
      setDirty: action,
      setGreasedTerminal: action,
      setTightTerminal: action,
    });
  }

  public init(model: KIPTerminalsModel): void {
    this._model = model;
  }

  /**
   * Количество клемм
   */
  public get length(): number {
    return this._terminalsCount;
  }

  /**
   * Установить параметр highlighted у всех клемм
   */
  public setHighlightedAll(value: boolean): void {
    for (let i = 0; i < this._highlighted.length; i += 1)
      this._highlighted[i] = value;
  }

  /**
   * Установить параметр highlighted у клеммы index
   */
  public setHighlighted(index: number, value: boolean): void {
    this._highlighted[index] = value;
  }

  /**
   * Установить параметр dirty у всех клемм
   */
  public setDirtyAll(value: boolean): void {
    for (let i = 0; i < this._dirty.length; i += 1) this._dirty[i] = value;
  }

  /**
   * Установить параметр dirty у count случайных клемм
   */
  public setDirtyRandom(count: number, value: boolean): void {
    const updateOrder = randomShuffle(this._dirty.map((x, i) => i));
    for (const i of updateOrder.slice(0, count)) this._dirty[i] = value;
  }

  /**
   * Установить параметр dirty у клеммы index
   */
  public setDirty(index: number, value: boolean): void {
    this._dirty[index] = value;
  }

  /**
   * Обработать клемму техническим вазелином
   *
   * Добавляет id клеммы в список смазанных клемм
   */
  public setGreasedTerminal(index: number): void {
    this._greasedTerminals.add(index);
  }

  /**
   * Установить состояние клеммы: затянута или ослаблена
   */
  public setTightTerminal(index: number, isTight: boolean): void {
    if (isTight) this._looseTerminals.delete(index);
    else this._looseTerminals.add(index);
  }

  /**
   * Изменяет состояние клеммы на противоположное
   */
  public toggleTerminalTight(index: number): void {
    this.setTightTerminal(index, this._looseTerminals.has(index));
  }
}
