import {
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  IAction,
  Observable,
  PBRMaterial,
  Scene,
} from '@babylonjs/core';

import { KIPModel } from '../model';
import { KIPObjectTerminalAction, KIPTerminalState } from '../types';

/**
 * Класс для управления клеммами КИПа.
 * Отвечает за замену текстуры клемм, событие нажания, пересечения меша
 */
export class KIPTerminalsController {
  private _scene: Scene;
  private _terminals: AbstractMesh[];
  private _screw: AbstractMesh[];

  private _normalMaterial: PBRMaterial;
  private _dirtMaterial: PBRMaterial;
  private _highlightMaterial: PBRMaterial;

  private _terminalActions: IAction[][] = [];
  private _screwActions: IAction[][] = [];

  public onClick = new Observable<KIPObjectTerminalAction>();
  public onIntersectionExit = new Observable<KIPObjectTerminalAction>();
  public onIntersectionEnter = new Observable<KIPObjectTerminalAction>();

  /**
   * Создать класс управления крышкой КИПа
   */
  constructor(scene: Scene, model: KIPModel) {
    this._scene = scene;
    this._terminals = model.terminals.all.map((m) => m.body);
    this._screw = model.terminals.measure.map((m) => m.screw!);

    this._normalMaterial = model.materials.normal;
    this._dirtMaterial = model.materials.dirty;
    this._highlightMaterial = this._dirtMaterial.clone('HighlightMaterial');
    this._highlightMaterial.emissiveColor.set(0.5, 0.5, 0);

    for (const t of this._terminals) {
      t.actionManager = new ActionManager(this._scene);
      this._terminalActions.push([]);
    }
    for (const s of this._screw) {
      s.actionManager = new ActionManager(this._scene);
      this._screwActions.push([]);
    }
  }

  /**
   * Установить hook на событие клика мышкой на клеммы
   */
  public registerClickHook(): void {
    // Регистрация на событие нажатия на клемму
    const register = (mesh: AbstractMesh, idx: number, actions: IAction[]) => {
      const notifyValue = { terminal_index: idx, controller: -1 };
      const action = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          this.onClick.notifyObservers(notifyValue);
        })
      );
      if (action) actions.push(action);
    };

    this._terminals.forEach((terminal, idx) => {
      register(terminal, idx, this._terminalActions[idx]);
    });
    this._screw.forEach((screw, idx) => {
      register(screw, idx, this._screwActions[idx]);
    });
  }

  /**
   * Установить hook на событие пересечения проб мультиметра с клеммами
   */
  public registerIntersectionHook(
    controller: number,
    intersectMesh: AbstractMesh
  ): void {
    const register = (mesh: AbstractMesh, idx: number, actions: IAction[]) => {
      const actionParams = {
        intersectMesh,
        usePreciseIntersection: true,
      };
      const notifyValue = { terminal_index: idx, controller };
      const action1 = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersectionEnter.notifyObservers(notifyValue);
          }
        )
      );
      const action2 = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionExitTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersectionExit.notifyObservers(notifyValue);
          }
        )
      );

      if (action1) actions.push(action1);
      if (action2) actions.push(action2);
    };

    this._terminals.forEach((terminal, idx) => {
      register(terminal, idx, this._terminalActions[idx]);
    });
    this._screw.forEach((screw, idx) => {
      register(screw, idx, this._screwActions[idx]);
    });
  }

  /**
   * Удалить все зарегестрированные hooks
   */
  public unregisterAllHooks(): void {
    this._terminals.forEach((terminal, idx) => {
      for (const action of this._terminalActions[idx])
        terminal.actionManager!.unregisterAction(action);
      this._terminalActions[idx] = [];
    });

    this._screw.forEach((screw, idx) => {
      for (const action of this._screwActions[idx])
        screw.actionManager!.unregisterAction(action);
      this._screwActions[idx] = [];
    });
  }

  public setTerminalState(index: number, state: KIPTerminalState): void {
    let material = this._normalMaterial;
    if (state === KIPTerminalState.Highlighted)
      material = this._highlightMaterial;
    else if (state === KIPTerminalState.Dirty) material = this._dirtMaterial;

    this._terminals[index].material = material;
    if (index < this._screw.length) this._screw[index].material = material;
  }
}
