import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';

import { EMCVEConfig } from './types';
import { EMCVEModel } from './model';
import { EMCVEStore } from './store';
import { setupLogic } from './logic';

export class EMCVEObject extends BaseModelObject<
  EMCVEModel,
  EMCVEStore,
  EMCVEConfig
> {
  constructor(
    scene: Scene,
    model: EMCVEModel,
    store: EMCVEStore,
    cfg: EMCVEConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  protected _connectToStore(): void {
    autorun(() => {
      this.model.setVisibility(this.store.isVisible);
    });
  }

  public static async setup(
    scene: Scene,
    cfg: EMCVEConfig
  ): Promise<EMCVEObject> {
    const model = await EMCVEModel.load(scene, cfg);

    const store = new EMCVEStore(model);
    return new EMCVEObject(scene, model, store, cfg);
  }
}
