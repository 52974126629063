import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC } from 'react';

import { requirementToPhrase } from './requirement-to-phrase';

import css from './index.module.css';

const UnfulfilledRequiredStatesPanel: FC = observer(() => {
  const { unfulfilledRequiredStates } = useRootData((store) => ({
    unfulfilledRequiredStates: store.lesson.unfulfilledRequiredStates,
  }));

  return (
    <div className={css.mistakeInfo}>
      {unfulfilledRequiredStates.map((req) => (
        <div key={req.stateName}>{`${requirementToPhrase(
          req.stateName!
        )}`}</div>
      ))}
    </div>
  );
});

export default UnfulfilledRequiredStatesPanel;
