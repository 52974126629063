import { AdvancedDynamicTexture } from '@babylonjs/gui';

import { UIAskIfAcceptWindowGUI } from 'features/lesson-page/scene-components/user-interfaces';

export class AcceptJumpersWindowGUI extends UIAskIfAcceptWindowGUI {
  constructor(texture: AdvancedDynamicTexture) {
    super(texture, {
      titleText: 'Запись показаний',
      questionText: 'Правильно ли установлены перемычки?',
      acceptText: 'Да',
      rejectText: 'Нет',
    });
  }
}
