import { action, makeAutoObservable } from 'mobx';

export class JumpersStore {
  private _P1_T3IsConnected = true;
  private _VE_T1IsConnected = true;
  constructor() {
    makeAutoObservable(this, {
      P1_T3StateToggle: action,
      VE_T1StateToggle: action,
    });
  }

  public get P1_T3(): boolean {
    return this._P1_T3IsConnected;
  }

  public get VE_T1(): boolean {
    return this._VE_T1IsConnected;
  }

  /**
   * Переключить состояние перемычки на противоположное
   */
  P1_T3StateToggle(): void {
    this._P1_T3IsConnected = !this._P1_T3IsConnected;
  }

  VE_T1StateToggle(): void {
    this._VE_T1IsConnected = !this._VE_T1IsConnected;
  }
}
