// import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { LandscapeConfig } from './types';
import { LandscapeStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: LandscapeStore,
  cfg: LandscapeConfig
): void {}
