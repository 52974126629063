import { action, makeAutoObservable } from 'mobx';

export interface IXRControllersButtonsStore {
  get aDown(): boolean;
  get aUp(): boolean;
}

export class XRControllersButtonsStore implements IXRControllersButtonsStore {
  private _a = false;

  public get aDown(): boolean {
    return this._a;
  }

  public get aUp(): boolean {
    return !this._a;
  }

  constructor() {
    makeAutoObservable(this, {
      setA: action,
    });
  }

  public setA(isDown: boolean): void {
    this._a = isDown;
  }
}
