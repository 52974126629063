import { action, makeAutoObservable } from 'mobx';

export class StepsPanelStore {
  private _activeStepNo = 0;
  private _steps: string[] = [];
  private _isOpen = false;

  /**
   * Текущий шаг
   */
  public get activeStepNo(): number {
    return this._activeStepNo;
  }

  public set activeStepNo(value: number) {
    this._activeStepNo = value;
  }

  /**
   * Имена шагов
   */
  public get steps(): string[] {
    return this._steps;
  }

  /**
   * Открыта ли панелька
   */
  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
  }

  constructor() {
    makeAutoObservable(this, {
      toggleOpen: action,
      setSteps: action,
    });
  }

  /**
   * Установить шаги
   */
  setSteps(steps: string[]): void {
    this._steps = steps;
  }

  /**
   * Изменить состояние открыто ли окно
   */
  public toggleOpen(): void {
    this._isOpen = !this._isOpen;
  }
}
