import { action, makeAutoObservable } from 'mobx';

import { AbstractMesh, TransformNode } from '@babylonjs/core';

import { ISingleHandStore, SingleHandStore } from './hand';
import { MainHandsStore } from './mainHand';

export { SingleHandStore } from './hand';
export type { ISingleHandStore } from './hand';

export interface IHandsStore {
  /**
   * Является ли правая рука главной
   */
  get rightHandIsMain(): boolean;

  /**
   * Является ли левая рука главной
   */
  get leftHandIsMain(): boolean;

  /**
   * Массив рук
   */
  get list(): ISingleHandStore[];

  /**
   * Левая рука
   */
  get left(): ISingleHandStore;

  /**
   * Правая рука
   */
  get right(): ISingleHandStore;

  /**
   * Главная рука
   */
  get mainHand(): ISingleHandStore;

  /**
   * Второстепенная рука
   */
  get minorHand(): ISingleHandStore;

  /**
   * Анимируется ли сейчас модель
   */
  get animating(): boolean;
}

export class HandsStore implements IHandsStore {
  private _mainHand: MainHandsStore;
  private _left: SingleHandStore;
  private _right: SingleHandStore;

  /**
   * Является ли правая рука главной
   */
  public get rightHandIsMain(): boolean {
    return this._mainHand.rightHandIsMain;
  }

  /**
   * Является ли левая рука главной
   */
  public get leftHandIsMain(): boolean {
    return this._mainHand.leftHandIsMain;
  }

  /**
   * Массив рук
   */
  public get list(): SingleHandStore[] {
    return [this._left, this._right];
  }

  /**
   * Левая рука
   */
  public get left(): SingleHandStore {
    return this._left;
  }

  /**
   * Правая рука
   */
  public get right(): SingleHandStore {
    return this._right;
  }

  /**
   * Главная рука
   */
  public get mainHand(): SingleHandStore {
    return this._mainHand.leftHandIsMain ? this._left : this._right;
  }

  /**
   * Второстепенная рука
   */
  public get minorHand(): SingleHandStore {
    return this._mainHand.leftHandIsMain ? this._right : this._left;
  }

  /**
   * Анимируется ли сейчас модель
   */
  public get animating(): boolean {
    return this._left.animating || this._right.animating;
  }

  constructor(
    leftRoot: TransformNode,
    leftTransformRoot: TransformNode,
    leftHelperTake: TransformNode,
    leftBBox: AbstractMesh,
    rightRoot: TransformNode,
    rightTransformRoot: TransformNode,
    rightHelperTake: TransformNode,
    rightBBox: AbstractMesh
  ) {
    this._mainHand = new MainHandsStore();
    this._left = new SingleHandStore(
      0,
      leftRoot,
      leftTransformRoot,
      leftHelperTake,
      leftBBox,
      this._mainHand,
      false
    );
    this._right = new SingleHandStore(
      1,
      rightRoot,
      rightTransformRoot,
      rightHelperTake,
      rightBBox,
      this._mainHand,
      true
    );

    makeAutoObservable(this, {
      setVisibility: action,
      setGloveVisibility: action,
      setMainHand: action,
      swapMainHand: action,
    });
  }

  /**
   * Установить видемость объекта
   * @param isVisible виден ли объект
   */
  public setVisibility(isVisible: boolean): void {
    this._left.setVisibility(isVisible);
    this._right.setVisibility(isVisible);
  }

  /**
   * Установить видемость перчатки
   * @param isVisible видна ли перчатка
   */
  public setGloveVisibility(isVisible: boolean): void {
    this._left.setGloveVisibility(isVisible);
    this._right.setGloveVisibility(isVisible);
  }

  /**
   * Установить главную руку
   * @param rightHandIsMain главная рука правая?
   */
  public setMainHand(rightHandIsMain: boolean): void {
    this._mainHand.setMainHand(rightHandIsMain);
  }

  /**
   * Поменять главную руку на противоположную
   */
  public swapMainHand(): void {
    this._mainHand.setMainHand(!this._mainHand.rightHandIsMain);
  }
}
