import { Configuration, LessonApi, ResultsApi } from './impl/web-vr-training';

const configTraining = new Configuration({
  basePath: process.env.REACT_APP_SERVICE_TRAINING,
});

export const LessonController = new LessonApi(configTraining);
export const ResultsController = new ResultsApi(configTraining);

export type {
  Script,
  ScriptStep,
  LessonStepReportModel,
  StepReportModel,
} from './impl/web-vr-training';

export { LessonType } from './impl/web-vr-training';
