import { action, autorun, runInAction } from 'mobx';

// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';

import { EMCVETerminalModel } from '../../objects/scene/EMCVE/model';
import { IIPP1ClipsStore } from '../../objects/tools/ipp1/store/clips';
import { IIPP1SingleClipStore } from '../../objects/tools/ipp1/store/single-clip';
import { IPP1ClipConnectType } from '../../objects/tools/ipp1/types';
import { IPP1DisplayState } from '../../objects/tools/ipp1';
import { KIPTerminalsModel } from '../../objects/scene/kip/model';
import { ToolsType } from '../../objects/tools';

const setAreAllLabelsTouched = action((v: boolean) => {
  lessonStore.sceneState.areAllLabelsTouched = v;
});

const setIsKipJumpersPositionCorrect = action((v: boolean | null) => {
  lessonStore.sceneState.isKipJumpersPositionCorrect = v;
});

const setIsKipTerminalPadClean = action((v: boolean) => {
  lessonStore.sceneState.isKipTerminalPadClean = v;
});

const setAreKipTerminalsLoose = action((looseTerminals: Set<number>) => {
  const { stateValue = '[]' } =
    lessonStore?.script?.currentStep?.doneCondition || {};
  try {
    const requiredLooseTerminals = JSON.parse(stateValue);
    if (!Array.isArray(requiredLooseTerminals)) {
      /* eslint-disable-next-line no-console */
      console.error(
        'Некорректное значение в stateValue в файле сценария на шаге откручивания клемм, не парсится в массив.',
        requiredLooseTerminals
      );
    }
    const areRequiredTerminalsLoose = requiredLooseTerminals.every(
      (index: number) => looseTerminals.has(index)
    );
    lessonStore.sceneState.areKipTerminalsLoose = areRequiredTerminalsLoose
      ? stateValue
      : JSON.stringify(Array.from(looseTerminals).sort());
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.error(
      'Некорректное значение в stateValue в файле сценария на шаге откручивания клемм, не получилось распарсить.'
    );
  }
});

const setAreAllKipTerminalsLoose = action((v: boolean) => {
  lessonStore.sceneState.areAllKipTerminalsLoose = v;
});

const setAreAllKipTerminalsTight = action((v: boolean) => {
  lessonStore.sceneState.areAllKipTerminalsTight = v;
});

const setAreKipTerminalsGreased = action((v: boolean) => {
  lessonStore.sceneState.areKipTerminalsGreased = v;
});

const setIsJumperT1VEDisconnected = action((v: boolean) => {
  lessonStore.sceneState.isJumperT1VEDisconnected = v;
});

const setIsJumperP1T3Disconnected = action((v: boolean) => {
  lessonStore.sceneState.isJumperP1T3Disconnected = v;
});

const setIsGlovesOn = action((v: boolean) => {
  lessonStore.sceneState.isGlovesOn = v;
});

const setIsTabletTaken = action((v: boolean) => {
  lessonStore.sceneState.isTabletTaken = v;
});

const setTabletEntriesCount = action((v: number) => {
  lessonStore.sceneState.tabletEntriesCount = v;
});

const setIpp1Probes = (
  kip: KIPTerminalsModel,
  emcve: EMCVETerminalModel[],
  ipp1Store: IIPP1ClipsStore
) => {
  const getClipSummary = (store: IIPP1SingleClipStore) => {
    const status = store.connectStatus;
    if (!status) return null;
    if (status.type === IPP1ClipConnectType.KIP_TERMINAL)
      return kip.measure[status.num].name;
    if (status.type === IPP1ClipConnectType.EMCVE_TERMINAL)
      return emcve[status.num].name;
    return null;
  };

  const res = {
    yellow: getClipSummary(ipp1Store.yellow),
    red: getClipSummary(ipp1Store.red),
    black: getClipSummary(ipp1Store.black),
  };

  runInAction(() => {
    lessonStore.sceneState.ipp1Probes = JSON.stringify(res);
  });
};

const setIpp1Mode = action((v: IPP1DisplayState) => {
  switch (v) {
    case IPP1DisplayState.MAIN_TESTER:
      lessonStore.sceneState.ipp1Mode = 'MAIN_TESTER';
      break;
    case IPP1DisplayState.POTENTIALS_RESULT:
      lessonStore.sceneState.ipp1Mode = 'POTENTIALS_RESULT';
      break;
    case IPP1DisplayState.VOLTMETERS_10V:
      lessonStore.sceneState.ipp1Mode = 'VOLTMETERS_10V';
      break;
    case IPP1DisplayState.VOLTMETERS_10V_RESULT:
      lessonStore.sceneState.ipp1Mode = 'VOLTMETERS_10V_RESULT';
      break;
    default:
      lessonStore.sceneState.ipp1Mode = v;
  }
});

const setIsBackDoorOpen = action((v: boolean) => {
  lessonStore.sceneState.isBackDoorOpen = v;
});

const setIsFrontDoorOpen = action((v: boolean) => {
  lessonStore.sceneState.isFrontDoorOpen = v;
});

const setAreAllWiresConnected = action((v: boolean) => {
  lessonStore.sceneState.areAllWiresConnected = v;
});

/**
 * Установка логики для sceneState
 */
export function setupLogicForSceneState(scene: LessonScene): void {
  autorun(() => {
    if (!lessonStore.scene.KIP) return;
    if (!lessonStore.scene.acceptJumpersWindow) return;
    if (!lessonStore.scene.jumpers) return;
    if (!lessonStore.scene.toolsPanel) return;

    setAreAllLabelsTouched(lessonStore.scene.KIP.labels.areAllLabelsTouched);
    setIsKipJumpersPositionCorrect(
      lessonStore.scene.acceptJumpersWindow.isAccepted
    );
    setIsKipTerminalPadClean(lessonStore.scene.KIP.terminals.areAllClean);
    setAreKipTerminalsLoose(lessonStore.scene.KIP.terminals.looseTerminals);
    setAreAllKipTerminalsLoose(
      lessonStore.scene.KIP.terminals.areCheckedTerminalsLoose
    );
    setAreAllKipTerminalsTight(lessonStore.scene.KIP.terminals.areAllTight);
    setAreKipTerminalsGreased(lessonStore.scene.KIP.terminals.areAllGreased);
    setIsJumperT1VEDisconnected(!lessonStore.scene.jumpers.VE_T1);
    setIsJumperP1T3Disconnected(!lessonStore.scene.jumpers.P1_T3);
    setIsGlovesOn(lessonStore.scene.toolsPanel.gloveSelected); // TODO: исправить на player.tools.gloveSelected
    setIsTabletTaken(
      lessonStore.player.inventory?.selectedTool === ToolsType.TABLET
    );
    setTabletEntriesCount(lessonStore.tools.tablet!.recordsId);
    setIpp1Probes(
      lessonStore.scene.KIP.model.terminals,
      lessonStore.scene.EMCVE!.model.terminals,
      lessonStore.tools.ipp1!.clips
    );
    setIpp1Mode(lessonStore.tools.ipp1!.display.state);

    setIsBackDoorOpen(lessonStore.scene.KIP.backDoor.isOpen);
    setIsFrontDoorOpen(lessonStore.scene.KIP.frontDoor.isOpen);
    setAreAllWiresConnected(lessonStore.scene.KIP.t1Wire.isConnected);
  });
}
