import {
  Nullable,
  Observable,
  Observer,
  Scene,
  TransformNode,
  Vector3,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { WrenchConfig } from './types';
import { WrenchModel } from './model';
import { WrenchStore } from './store';
import { setupLogic } from './logic';

export class WrenchObject extends BaseModelObject<
  WrenchModel,
  WrenchStore,
  WrenchConfig
> {
  private _intersect: IntersectionController;
  private _distanceHook: Nullable<Observer<Scene>> = null;

  private onDistanceToMeshUpdate = new Observable<number | undefined>();

  constructor(
    scene: Scene,
    model: WrenchModel,
    store: WrenchStore,
    cfg: WrenchConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersect = new IntersectionController(
      scene,
      model.bBox,
      store.intersect
    );
    this.registerController(this._intersect);
  }

  protected _connectToStore(): void {
    autorun(() => {
      this.model.setVisibility(this.store.isVisible);
    });
    autorun(() => {
      this._clearDistanceHook();
      if (this.store.distanceTarget)
        this._setupDistanceHook(this.store.distanceTarget);
    });
    this.onDistanceToMeshUpdate.add((v) => {
      this.store.setDistanceToMesh(v);
    });
  }

  public static async setup(
    scene: Scene,
    cfg: WrenchConfig
  ): Promise<WrenchObject> {
    const model = await WrenchModel.load(scene, cfg);
    const store = new WrenchStore(model.root);
    return new WrenchObject(scene, model, store, cfg);
  }

  private _clearDistanceHook(): void {
    if (this._distanceHook)
      this.scene.onBeforeRenderObservable.remove(this._distanceHook);
    this._distanceHook = null;
    this.onDistanceToMeshUpdate.notifyObservers(undefined);
  }

  private _setupDistanceHook(mesh: TransformNode): void {
    if (this._distanceHook) this._clearDistanceHook();

    this._distanceHook = this.scene.onBeforeRenderObservable.add(() => {
      const dist = Vector3.Distance(
        this.model.root.absolutePosition,
        mesh.absolutePosition
      );
      this.onDistanceToMeshUpdate.notifyObservers(dist);
    });
  }
}
