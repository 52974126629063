import { RoundedSquareButton } from '../rounded-square-button';

/**
 * Основа для кнопок управления окном.
 */
export class WindowCButtonBase extends RoundedSquareButton {
  constructor(name?: string) {
    super(0.2, name);
  }
}
