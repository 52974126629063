import React, { useMemo } from 'react';

import { CoreStoreInstance } from 'core';
import { LessonStoreInstance } from 'features/lesson-page';

import { StoreState } from '../types';

type StoreProviderProps = {
  children?: React.ReactNode;
};

export const StoreProvider: React.FC = ({ children }: StoreProviderProps) => {
  const core = CoreStoreInstance;
  const lesson = LessonStoreInstance;

  const store = useMemo(() => ({ core, lesson }), [core, lesson]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const storeContext = React.createContext<StoreState | null>(null);

export default StoreProvider;
