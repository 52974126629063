import { TransformNode } from '@babylonjs/core';

export type InfoPanelConfig = {
  place: string;
};

export type InfoPanelPlace = {
  parent: TransformNode;
  type: InfoPanelPlaceType;
};

export enum InfoPanelPlaceType {
  KIP_LEFT = 'kip_left',
}
