import { LessonStepReportModel, StepReportModel } from 'api';

export enum LessonExistenceType {
  Unknown = 'loading',
  Taken = 'taken',
  Available = 'available',
  Unavailable = 'notavailable',
}

export enum SceneLoadingProgressType {
  NotLoaded = 'notloaded',
  InProgress = 'inprogress',
  Loaded = 'loaded',
}

/* 
  Тип для описания записей в попап-таблице Админ -> Результаты -> Конкретная запись -> Шаги
*/
export interface PreparedDataItemStepReportModel extends StepReportModel {
  stepDurationFormatted: string;
  errorCountFormatted: string;
}
/* 
  Тип для описания записей в попап-таблице Админ -> Результаты -> Конкретная запись
*/
export interface PreparedDataItemUserStepReport extends LessonStepReportModel {
  fullName: string;
  lessonDurationFormatted: string;
  lessonTypeFormatted: string;
  stepsFormatted: PreparedDataItemStepReportModel[];
}
