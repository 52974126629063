import { autorun, when } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from 'features/lesson-page/scene-components/init';

import { KeyState } from '../../../tools/key/types';
import { WrenchState } from '../../../tools/wrench/types';
/**
 * Установка логики KIP
 */
export function setupLogicForSceneKIP(scene: LessonScene): void {
  if (!scene.scene?.KIP || !lessonStore.scene.KIP) return;
  const objStore = lessonStore.scene.KIP;
  const obj = scene.scene.KIP;

  objStore.terminals.setDirtyRandom(5, true);

  // Реакции на действия пользователя с задней дверью
  obj.backDoor.onPointerOver.add(() => {
    objStore.backDoor.isHighlighted = true;
  });
  obj.backDoor.onPointerOut.add(() => {
    objStore.backDoor.isHighlighted = false;
  });
  obj.backDoor.onPointerClick.add(() => {
    objStore.backDoor.toggleOpenState();
  });

  autorun(() => {
    const { key } = lessonStore.tools;
    if (!key) return;
    if (key.rotationState === KeyState.OPENED)
      objStore.frontDoor.setOpenState(true);
    else if (key.rotationState === KeyState.CLOSED)
      objStore.frontDoor.setOpenState(false);
  });

  // Пометка выделеных клемм как очищенные
  autorun(() => {
    const { brush } = lessonStore.tools;
    if (!brush) return;
    brush.intersectedTerminals.forEach((idx) => {
      objStore.terminals.setDirty(idx, false);
    });
  });

  // Пометка выделеных клемм как смазанные
  autorun(() => {
    const { tube } = lessonStore.tools;
    if (!tube) return;
    tube.intersectedTerminals.forEach((id) => {
      objStore.terminals.setGreasedTerminal(id);
    });
  });

  autorun(() => {
    const { wrench } = lessonStore.tools;
    if (!wrench || !wrench.rotatedState) return;
    const [terminal, state] = wrench.rotatedState;
    if (state === WrenchState.CLOSED)
      objStore.terminals.setTightTerminal(terminal, true);
    else if (state === WrenchState.OPENED)
      objStore.terminals.setTightTerminal(terminal, false);
  });

  // Реакции на действия пользователя с передней дверью
  obj.frontDoor.onPointerOver.add(() => {
    objStore.frontDoor.isHighlighted = true;
  });
  obj.frontDoor.onPointerOut.add(() => {
    objStore.frontDoor.isHighlighted = false;
  });

  // Добавить надпись (точнее, её индекс) в список потроганных надписей
  obj.labels.onPointerClick.add((index) => {
    objStore.labels.setTouchedLabel(index);
  });
  obj.labels.onIntersectionEnterLabel.add((index) => {
    objStore.labels.setTouchedLabel(index);
  });

  // Реакции на действия пользователя с проводом Т1
  obj.t1Wire.onIntersectionEnter.add(() => {
    objStore.t1Wire.changeState(true);
  });

  // при ослаблении клеммы Т1 единожды сработает обрыв провода
  when(
    () => objStore.terminals.looseTerminals.has(1),
    () => objStore.t1Wire.changeState(false)
  );
  // При переключении в VR
  autorun(() => {
    obj.terminals.unregisterAllHooks();

    if (!lessonStore.isVR) {
      obj.terminals.registerClickHook();
    } else {
      const leftBBox = lessonStore.player.hands?.left.bbox;
      if (leftBBox) {
        // Пересечение контроллеров с проводом Т1
        obj.t1Wire.setupWireIntersection(0, leftBBox);
        // Пересечение контроллеров с надписями на КИПе
        obj.labels.setupLabelIntersection(0, leftBBox);
      }

      const rightBBox = lessonStore.player.hands?.right.bbox;
      if (rightBBox) {
        // Пересечение контроллеров с проводом Т1
        obj.t1Wire.setupWireIntersection(1, rightBBox);
        // Пересечение контроллеров с надписями на КИПе
        obj.labels.setupLabelIntersection(1, rightBBox);
      }
    }
  });
}
