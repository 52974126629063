/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { autorun } from 'mobx';

import { LessonScene } from 'features/lesson-page/scene-components/init';
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

/**
 * Установка логики по умолчанию для перемычек
 * @param obj инстанция перемычек
 */
export function setupLogicForSceneJumpers(scene: LessonScene): void {
  if (!scene.scene?.jumpers || !lessonStore.scene.jumpers) return;

  const obj = scene.scene.jumpers;
  const objStore = lessonStore.scene.jumpers;
  obj.P1_T3.onIntersectionEnter.add(() => {
    // TODO убрать после показа. Переключение стейта перемычек только на определенных шагах
    const { currentStep } = lessonStore.script;
    if (currentStep?.doneCondition?.stateName === 'isJumperP1T3Disconnected') {
      objStore.P1_T3StateToggle();
    }
  });
  obj.VE_T1.onIntersectionEnter.add(() => {
    // TODO убрать после показа. Переключение стейта перемычек только на определенных шагах
    const { currentStep } = lessonStore.script;
    console.log('currentStep:', currentStep);
    if (currentStep?.doneCondition?.stateName === 'isJumperT1VEDisconnected') {
      objStore.VE_T1StateToggle();
    }
  });

  // При переключении в VR
  autorun(() => {
    if (lessonStore.isVR) {
      lessonStore.player.hands?.list.forEach((hand) => {
        obj.P1_T3.clearJumpersIntersection();
        obj.P1_T3.setupJumpersIntersection(hand.id, hand.bbox);
        obj.VE_T1.clearJumpersIntersection();
        obj.VE_T1.setupJumpersIntersection(hand.id, hand.bbox);
      });
    }
  });
}
