import {
  AdvancedDynamicTexture,
  Button,
  Control,
  Image,
  Vector2WithInfo,
} from '@babylonjs/gui';

export class TabletSheetGUI {
  private TABLE_TOP_PADDING = 10;
  private TABLE_LEFT_PADDING = 22;
  private TABLE_RIGHT_PADDING = 8;

  private BUTTON_FONT_SIZE = 10;

  private BUTTON_TOP_PADDING = 8;
  private BUTTON_BOTTOM_PADDING = 8;
  private BUTTON_LEFT_PADDING = 22;
  private BUTTON_REGHT_PADDING = 8;
  private BUTTON_HEIGHT = 29;

  private _addButton: Button;
  private _image: Image;

  private _images: HTMLImageElement[];

  public onAddButtonClickCallback: ((e: Vector2WithInfo) => void) | null = null;

  public get isEnabled(): boolean {
    return this._addButton.isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._addButton.isEnabled = value;
  }

  /**
   * Создание текстуры планшета
   */
  constructor(
    texture: AdvancedDynamicTexture,
    scale: number,
    UIImages: HTMLImageElement[]
  ) {
    this._images = UIImages;
    this._image = this._setupTable(texture, scale);
    this._addButton = this._setupAddButton(texture, scale);

    const i = 0;
    this._image.domImage = UIImages[i];
  }

  /**
   * Создание и настройка таблицы
   */
  private _setupTable(texture: AdvancedDynamicTexture, scale: number): Image {
    const sz = texture.getSize();

    const table = new Image('Table');
    table.stretch = Image.STRETCH_UNIFORM;

    table.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    table.left = this.TABLE_LEFT_PADDING * scale;
    table.widthInPixels =
      sz.width - (this.TABLE_LEFT_PADDING + this.TABLE_RIGHT_PADDING) * scale;

    table.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    table.top = this.TABLE_TOP_PADDING * scale;
    table.heightInPixels =
      sz.height -
      (this.BUTTON_BOTTOM_PADDING +
        this.BUTTON_HEIGHT +
        this.BUTTON_TOP_PADDING +
        this.TABLE_TOP_PADDING) *
        scale;

    texture.addControl(table);
    return table;
  }

  /**
   * Создание и настройка кнопки "Записать значения"
   */
  private _setupAddButton(
    texture: AdvancedDynamicTexture,
    scale: number
  ): Button {
    const button = Button.CreateSimpleButton('AddButton', 'Записать значения');
    button.color = 'black';
    button.background = '#ffefdb';
    button.fontSize = this.BUTTON_FONT_SIZE * scale;
    button.cornerRadius = 5;
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    button.top = -this.BUTTON_BOTTOM_PADDING * scale;
    button.heightInPixels = this.BUTTON_HEIGHT * scale;
    button.paddingLeftInPixels = this.BUTTON_LEFT_PADDING * scale;
    button.paddingRightInPixels = this.BUTTON_REGHT_PADDING * scale;
    button.onPointerClickObservable.add((e: Vector2WithInfo) => {
      this.onAddButtonClickCallback && this.onAddButtonClickCallback(e);
    });
    texture.addControl(button);
    return button;
  }

  /**
   * Обновить записи в таблице
   */
  public setTabletContent(recordsId: number): void {
    if (recordsId >= this._images.length)
      throw Error(
        `Image with index ${recordsId} does not exists. Only ${this._images.length} available`
      );
    this._image.domImage = this._images[recordsId];
  }
}
