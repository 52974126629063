import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { CraneUnitConfig } from './types';
import { loadMesh } from '../../common/utils';

/**
 * Общедоступные части моедели.
 *
 * Из любой части программы можно получить доступ к полям, описанным в этом
 * интерфейсе. Используется в для привязки других объектов к разным частям
 * этой модели, а также для проверки пересечения объектов с этой моделью
 */
export interface ICraneUnitModel {}

/**
 * Класс представляющий модель объекта.
 *
 * Класс предоставляет доступ к разным частям модели
 */
export class CraneUnitModel implements ICraneUnitModel {
  public static readonly MODEL_VERSION = 'v0001';

  private _allMeshes: AbstractMesh[];
  private _root: TransformNode;

  public get root(): TransformNode {
    return this._root;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    const { meshes } = model;

    this._allMeshes = meshes;
    [this._root] = this._allMeshes;
  }

  static async load(
    scene: Scene,
    cfg: CraneUnitConfig
  ): Promise<CraneUnitModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new CraneUnitModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }
}
