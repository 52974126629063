import {
  Container,
  Control,
  Measure,
  TextBlock,
  TextWrapping,
} from '@babylonjs/gui';

import { GridTable } from 'features/lesson-page/scene-components/widgets';

import { StepsPageContentLineType } from '../store';

export class WBodyStepsPage extends Container {
  private ROWS_NUMBER = 12;
  private _borderMargin: number;

  private _titleBlocks: TextBlock[] = [];

  private _columnNumber: TextBlock[] = [];
  private _columnName: TextBlock[] = [];
  private _columnErrorCount: TextBlock[] = [];
  private _columnStepDuration: TextBlock[] = [];

  /**
   * Создание и настройка таблицы
   */
  private _setupTable(scale: number): GridTable {
    const table = new GridTable('Table');
    table.lineWidth = 1 * scale;
    table.strokeStyle = '#555';
    table.showTopLine = false;
    table.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    const COLUMN_SIZES = [5, 31, 10, 15];

    // title line
    table.addRowDefinition(4, false);

    for (let i = 0; i < COLUMN_SIZES.length; i += 1) {
      table.addColumnDefinition(COLUMN_SIZES[i], false);

      const titleBlock = new TextBlock();
      titleBlock.color = 'black';
      titleBlock.textWrapping = true;
      titleBlock.fontFamily = 'sans-serif';
      titleBlock.fontWeight = 'bold';
      titleBlock.fontSize = 6 * scale;
      table.addControl(titleBlock, 0, i);
      this._titleBlocks[i] = titleBlock;
    }

    this._titleBlocks[0].text = '№';
    this._titleBlocks[1].text = 'Название';
    this._titleBlocks[2].text = 'Кол-во ошибок';
    this._titleBlocks[3].text = 'Затраченное время';

    const textBoxBuilder = (column: Control[], x: number, y: number) => {
      const res = new TextBlock();
      res.color = 'black';
      res.fontFamily = 'sans-serif';
      res.fontSize = 7 * scale;
      table.addControl(res, x, y);
      column.push(res);
      return res;
    };

    for (let i = 0; i < this.ROWS_NUMBER; i += 1) {
      table.addRowDefinition(3, false);

      textBoxBuilder(this._columnNumber, i + 1, 0);
      textBoxBuilder(this._columnName, i + 1, 1);
      textBoxBuilder(this._columnErrorCount, i + 1, 2);
      textBoxBuilder(this._columnStepDuration, i + 1, 3);

      const tname = this._columnName[i];
      tname.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      tname.paddingLeftInPixels = 2 * scale;
      tname.paddingRightInPixels = 2 * scale;
      tname.textWrapping = TextWrapping.Ellipsis;
    }

    return table;
  }

  constructor(scale: number, name?: string) {
    super(name);

    this._borderMargin = 4 * scale;

    const table = this._setupTable(scale);
    this.addControl(table);
  }

  protected _preMeasure(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._preMeasure(parentMeasure, context);

    this.widthInPixels = parentMeasure.width - this._borderMargin * 2;
    this.heightInPixels = parentMeasure.height - this._borderMargin * 2;
  }

  setContent(content: StepsPageContentLineType[]): void {
    for (let i = 0; i < content.length; i += 1) {
      this._columnNumber[i].text = `${i + 1}`;
      this._columnName[i].text = content[i].name;
      this._columnErrorCount[i].text = content[i].errorCount;
      this._columnStepDuration[i].text = content[i].stepDuration;
    }

    for (let i = content.length; i < this._columnNumber.length; i += 1) {
      this._columnNumber[i].text = '';
      this._columnName[i].text = '';
      this._columnErrorCount[i].text = '';
      this._columnStepDuration[i].text = '';
    }
  }
}
