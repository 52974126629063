import { LessonStore } from 'features/lesson-page/store';

import { CraneUnitConfig } from './types';
import { CraneUnitStore } from './store';

/**
 * Функция отвечает за установку состояния объекта по умолчаню, а так же
 * установку внешней логики (связи между различными объектами сцены).
 *
 * Для этого с помощью autorun отслеживаются изменения указанных сторов
 * объектов, после чего происходит изменение собственного стора.
 *
 * Например:
 *   autorun(() => {
 *     // Изменяет видимость объекта в зависимости от того открыта ли дверца KIP'а
 *     objStore.setVisibility(store.scene.KIP?.frontDoor.isOpen === true);
 *   });
 */
export function setupLogic(
  store: LessonStore,
  objStore: CraneUnitStore,
  cfg: CraneUnitConfig
): void {
  objStore.setVisibility(true);
}
