import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { Scene, Texture } from '@babylonjs/core';

import { CommonPlaneModel } from '../../common/models';
import { getRootUrlForObject, loadJSImage } from '../../common/utils';

import { PanelItemType, ToolsPanelConfig } from './types';

export class ToolsPanelModel {
  public static readonly MODEL_VERSION = 'v0001';

  public static readonly WIDTH = 240;
  public static readonly HEIGHT = 180;
  public static readonly TEX_Q = 4;
  public static readonly REAL_SCALE = 0.8 / 240;

  private _plane: CommonPlaneModel;
  private _texture: AdvancedDynamicTexture;
  private _uiImages: Record<PanelItemType, HTMLImageElement>;

  public get plane(): CommonPlaneModel {
    return this._plane;
  }

  public get texture(): AdvancedDynamicTexture {
    return this._texture;
  }

  public get uiImages(): Record<PanelItemType, HTMLImageElement> {
    return this._uiImages;
  }

  constructor(
    scene: Scene,
    plane: CommonPlaneModel,
    uiImages: Record<PanelItemType, HTMLImageElement>
  ) {
    this._plane = plane;
    this._uiImages = uiImages;
    this._texture = new AdvancedDynamicTexture(
      'ToolsPanel',
      ToolsPanelModel.WIDTH * ToolsPanelModel.TEX_Q,
      ToolsPanelModel.HEIGHT * ToolsPanelModel.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
  }

  static async load(
    scene: Scene,
    cfg: ToolsPanelConfig
  ): Promise<ToolsPanelModel> {
    const uiRootUrl = getRootUrlForObject(
      cfg.ui.root_url,
      this.MODEL_VERSION,
      cfg.ui.folder
    );

    const imagesPromise: Promise<HTMLImageElement>[] = [
      loadJSImage(`${uiRootUrl}brush.png`),
      loadJSImage(`${uiRootUrl}gloves.png`),
      loadJSImage(`${uiRootUrl}ipp1.png`),
      loadJSImage(`${uiRootUrl}key.png`),
      loadJSImage(`${uiRootUrl}tablet.png`),
      loadJSImage(`${uiRootUrl}tube.png`),
      loadJSImage(`${uiRootUrl}wrench.png`),
    ];

    const planePromice = CommonPlaneModel.load(scene, {
      height: this.HEIGHT * this.REAL_SCALE,
      width: this.WIDTH * this.REAL_SCALE,
    });

    const [plane, images] = await Promise.all([
      planePromice,
      Promise.all(imagesPromise),
    ]);

    const uiImages: Record<PanelItemType, HTMLImageElement> = {
      [PanelItemType.BRUSH]: images[0],
      [PanelItemType.GLOVES]: images[1],
      [PanelItemType.IPP1]: images[2],
      [PanelItemType.KEY]: images[3],
      [PanelItemType.TABLET]: images[4],
      [PanelItemType.TUBE]: images[5],
      [PanelItemType.WRENCH]: images[6],
    };

    return new ToolsPanelModel(scene, plane, uiImages);
  }

  setVisibility(isVisible: boolean): void {
    this._plane.setVisibility(isVisible);
  }
}
