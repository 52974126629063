import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';

import { BabylonWindow } from 'features/lesson-page/scene-components/widgets';

import { PanelItemType, ToolsPanelClickInfo } from '../types';
import { WBodyContent } from './body-content';

type ClickCallback = (e: Vector2WithInfo) => void;
type ToolsPanelClickCallback = (e: ToolsPanelClickInfo) => void;

export class ToolsPanelGUI {
  private _window: BabylonWindow;

  private _bodyContent: WBodyContent;

  public onCloseButtonClick: ClickCallback | null = null;
  public onToolsClick: ToolsPanelClickCallback | null = null;

  constructor(
    texture: AdvancedDynamicTexture,
    scale: number,
    UIImages: Record<PanelItemType, HTMLImageElement>
  ) {
    this._window = new BabylonWindow({ close: false });
    this._window.scale = scale;
    this._window.titleText = 'Инвентарь';

    this._bodyContent = new WBodyContent(scale, UIImages);

    this._window.addControl(this._bodyContent);
    texture.addControl(this._window);

    // Перенаправление callback'ов
    this._bodyContent.onButtonClickCallback = (e) => {
      this.onToolsClick && this.onToolsClick(e);
    };
    this._window.onCButtonCloseClickObservable.add((e: Vector2WithInfo) => {
      this.onCloseButtonClick && this.onCloseButtonClick(e);
    });
  }

  setSelected(selected: Set<PanelItemType>): void {
    this._bodyContent.setSelected(selected);
  }
}
