import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

i18n
  .use(I18NextHttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'ru',
    load: 'currentOnly',

    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      useSuspense: true,
    },

    detection: {
      lookupCookie: 'lang',
    },

    backend: {
      loadPath: `/${process.env.REACT_APP_NAME}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
