import { makeAutoObservable } from 'mobx';

import { FPStepsPanelStore } from '../scene-components/objects/fullscreen-panel';

export class FullscreenPanelStore {
  private _stepsPanel = new FPStepsPanelStore();

  /**
   * Панель шагов
   */
  public get stepsPanel(): FPStepsPanelStore {
    return this._stepsPanel;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
