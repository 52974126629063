import { Button, Control, Image, Measure, TextBlock } from '@babylonjs/gui';

export class ImageItem extends Button {
  private THICKNESS = 1.5;
  private COLOR = '#0e7dcc';
  private CORNER_CADIUS = 4;
  private PADDING = 3;

  private _selected_thickness: number;

  private _myImage: Image;
  private _title: TextBlock;

  constructor(
    title: string,
    image: HTMLImageElement,
    scale: number,
    name?: string
  ) {
    super(name);

    this._selected_thickness = this.THICKNESS * scale;

    this.thickness = 0;
    this.color = this.COLOR;
    this.cornerRadius = this.CORNER_CADIUS * scale;
    this.paddingTopInPixels = this.PADDING * scale;
    this.paddingRightInPixels = this.PADDING * scale;
    this.paddingLeftInPixels = this.PADDING * scale;
    this.paddingBottomInPixels = this.PADDING * scale;

    this._myImage = new Image('Image');
    this._myImage.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this._myImage.stretch = Image.STRETCH_UNIFORM;
    this._myImage.domImage = image;
    this.addControl(this._myImage);

    this._title = new TextBlock('Title');
    this._title.text = title;
    this._title.textWrapping = true;
    this._title.fontSize = 9 * scale;
    this._title.color = '#222';
    this._title.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this._title.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.addControl(this._title);
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    const sz = this._measureForChildren;
    this._myImage.heightInPixels = sz.width;
    this._title.topInPixels = sz.width;
    this._title.heightInPixels = sz.height - sz.width;
  }

  setSelected(selected: boolean): void {
    this.thickness = selected ? this._selected_thickness : 0;
  }
}
