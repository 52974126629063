import { Scene } from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

import { BaseObject } from '../../common/base';
import { HandsObject } from '../hands';
import { InventoryObject } from '../inventory';
import { PlayerConfig } from './types';
import { PlayerStore, PlayerStoreList } from './store';

type PlayerObjectList = {
  hands?: HandsObject;
  inventory?: InventoryObject;
};

export class PlayerObject extends BaseObject<PlayerStore, PlayerConfig> {
  private _objects: PlayerObjectList;

  /**
   * Руки
   */
  public get hands(): HandsObject | undefined {
    return this._objects.hands;
  }

  /**
   * Инвентарь
   */
  public get inventory(): InventoryObject | undefined {
    return this._objects.inventory;
  }

  constructor(
    scene: Scene,
    objects: PlayerObjectList,
    store: PlayerStore,
    cfg: PlayerConfig
  ) {
    super(scene, store, cfg);
    this._objects = objects;
  }

  public static async setup(
    scene: Scene,
    cfg: PlayerConfig
  ): Promise<PlayerObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.hands ? HandsObject.setup(scene, cfg.hands) : none,
      cfg.inventory ? InventoryObject.setup(scene, cfg.inventory) : none,
    ]);

    // Build objects
    const objects: PlayerObjectList = {};
    [objects.hands, objects.inventory] = await loadedObjects;

    // Build stores
    const stores: PlayerStoreList = {};
    stores.hands = objects.hands?.store;
    stores.inventory = objects.inventory?.store;

    return new PlayerObject(scene, objects, new PlayerStore(stores), cfg);
  }

  public setupLogic(store: LessonStore): void {
    this._objects.hands?.setupLogic(store);
    this._objects.inventory?.setupLogic(store);

    super.setupLogic(store);
  }
}
