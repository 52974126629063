import { LessonStore } from 'features/lesson-page/store';

import { SkyboxConfig } from './types';
import { SkyboxStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: SkyboxStore,
  cfg: SkyboxConfig
): void {}
