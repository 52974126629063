import { action, makeAutoObservable } from 'mobx';

export class MainHandsStore {
  private _rightHandIsMain = true;

  /**
   * Является ли правая рука главной
   */
  public get rightHandIsMain(): boolean {
    return this._rightHandIsMain;
  }

  /**
   * Является ли левая рука главной
   */
  public get leftHandIsMain(): boolean {
    return !this._rightHandIsMain;
  }

  constructor() {
    makeAutoObservable(this, {
      setMainHand: action,
    });
  }

  /**
   * Установить главную руку
   * @param rightHandIsMain главная рука правая?
   */
  public setMainHand(rightHandIsMain: boolean): void {
    this._rightHandIsMain = rightHandIsMain;
  }
}
