import { Scene } from '@babylonjs/core';

import { BaseObject } from '../../common/base';

import { InventoryConfig } from './types';
import { InventoryStore } from './store';
import { setupLogic } from './logic';

export class InventoryObject extends BaseObject<
  InventoryStore,
  InventoryConfig
> {
  constructor(scene: Scene, store: InventoryStore, cfg: InventoryConfig) {
    super(scene, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  /**
   * Скачать все модели и создать объект
   */
  public static setup(
    scene: Scene,
    cfg: InventoryConfig
  ): Promise<InventoryObject> {
    const store = new InventoryStore();
    return Promise.resolve(new InventoryObject(scene, store, cfg));
  }
}
