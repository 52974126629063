import { Quaternion, TransformNode, Vector3 } from '@babylonjs/core';

import { action, makeAutoObservable } from 'mobx';

import {
  IXRControllerButtonsStore,
  XRControllerButtonsStore,
} from './controller-buttons';

export interface IXRControllerStore {
  /**
   * Порядковый номер контроллера
   */
  get id(): number;

  /**
   * Является ли данный контроллер левым
   */
  get isLeft(): boolean;

  /**
   * Является ли данный контроллер правым
   */
  get isRight(): boolean;

  /**
   * Указатель, к которому можно привязывать меши
   */
  get pointer(): TransformNode;

  /**
   * Кватернион вращения, на которое изменилось положение контроллера с предыдущего изменения
   */
  get rotationDeltaQ(): Quaternion;

  /**
   * Кнопки контроллера
   */
  get buttons(): IXRControllerButtonsStore;

  readonly defaultXAxis: Vector3;
  readonly defaultYAxis: Vector3;
  readonly defaultZAxis: Vector3;
}

export class XRControllerStore implements IXRControllerStore {
  private _id: number;
  private _isLeft: boolean;
  private _pointer: TransformNode;
  private _buttons: XRControllerButtonsStore;
  private _rotationDeltaQ = Quaternion.Zero();

  public get id(): number {
    return this._id;
  }

  public get isLeft(): boolean {
    return this._isLeft;
  }

  public get isRight(): boolean {
    return !this._isLeft;
  }

  public get pointer(): TransformNode {
    return this._pointer;
  }

  public get buttons(): XRControllerButtonsStore {
    return this._buttons;
  }

  public get rotationDeltaQ(): Quaternion {
    return this._rotationDeltaQ;
  }

  public readonly defaultXAxis = new Vector3(
    0,
    -0.6174170970916748,
    0.7866360545158386
  );

  public readonly defaultYAxis = new Vector3(
    0,
    0.7866359949111938,
    0.6174170970916748
  );

  public readonly defaultZAxis = new Vector3(1.0, 0, 0);

  constructor(id: number, isLeft: boolean, pointer: TransformNode) {
    this._id = id;
    this._isLeft = isLeft;
    this._pointer = pointer;
    this._buttons = new XRControllerButtonsStore();
    makeAutoObservable(this, {
      setRotationDeltaQ: action,
    });
  }

  public setRotationDeltaQ(rotationDeltaQ: Quaternion): void {
    this._rotationDeltaQ = rotationDeltaQ.clone();
  }
}
