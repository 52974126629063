import { action, makeAutoObservable } from 'mobx';

export class WireStore {
  private _isConnected = true;
  constructor() {
    makeAutoObservable(this, {
      toggleState: action,
    });
  }

  public get isConnected(): boolean {
    return this._isConnected;
  }

  public changeState(state: boolean): void {
    this._isConnected = state;
  }

  /**
   * Переключить состояние провода на противоположное
   */
  public toggleState(): void {
    this.changeState(!this._isConnected);
  }
}
