import { action, makeAutoObservable } from 'mobx';

import { IntersectionTarget } from './types';

export class IntersectionStore {
  private _isEnabled = true;
  private _targets: IntersectionTarget[] = [];
  private _marks = new Set<number>();

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public get targets(): IntersectionTarget[] {
    return this._targets;
  }

  public get marks(): Set<number> {
    return this._marks;
  }

  constructor() {
    makeAutoObservable(this, {
      setEnabled: action,
      setTargets: action,
      markTarget: action,
      resetMarks: action,
    });
  }

  public setEnabled(isEnabled: boolean): void {
    this._isEnabled = isEnabled;
  }

  public setTargets(targets: IntersectionTarget[]): void {
    this._targets = targets;
  }

  public markTarget(idx: number, mark: boolean): void {
    if (!this._isEnabled) return;
    if (mark) this._marks.add(idx);
    else this._marks.delete(idx);
  }

  public resetMarks(): void {
    this._marks.clear();
  }
}
