import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';

import { BabylonWindow } from 'features/lesson-page/scene-components/widgets';

import { WBodyContent } from './body-content';

type ClickCallback = (e: Vector2WithInfo) => void;

export class InfoPanelGUI {
  private _window: BabylonWindow;

  private _bodyContent: WBodyContent;

  public onCloseButtonClick: ClickCallback | null = null;

  constructor(texture: AdvancedDynamicTexture, scale: number) {
    this._window = new BabylonWindow({ close: true });
    this._window.scale = scale;
    this._window.titleText = 'Информация';

    this._bodyContent = new WBodyContent(scale);

    this._window.addControl(this._bodyContent);
    texture.addControl(this._window);

    // Перенаправление callback'ов
    this._window.onCButtonCloseClickObservable.add((e: Vector2WithInfo) => {
      this.onCloseButtonClick && this.onCloseButtonClick(e);
    });
  }

  setContent(content: string): void {
    this._bodyContent.setContent(content);
  }
}
