const readStoreLS = (name: string): Promise<string | undefined> =>
  new Promise((resolve) => {
    const data = localStorage.getItem(name);
    resolve(data || undefined);
  });

const writeStoreLS = (name: string, value: string): Promise<void | Error> =>
  new Promise((resolve) => {
    localStorage.setItem(name, value);
    resolve();
  });

export { readStoreLS, writeStoreLS };
