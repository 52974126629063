import { action, makeAutoObservable } from 'mobx';

import { IPP1Buttons, IPP1DisplayState } from '../types';

export interface IIPP1DisplayStore {
  get state(): IPP1DisplayState;
}

export class IPP1DisplayStore implements IIPP1DisplayStore {
  private _state: IPP1DisplayState = IPP1DisplayState.MAIN_TESTER;

  public get state(): IPP1DisplayState {
    return this._state;
  }

  constructor() {
    makeAutoObservable(this, {
      processButtonPress: action,
    });
  }

  public processButtonPress(button: IPP1Buttons): void {
    // Главное меню [работа]
    if (this._state === IPP1DisplayState.MAIN_WORK) {
      if (button === IPP1Buttons.RIGHT)
        this._state = IPP1DisplayState.MAIN_ARCHIVE;
      else if (button === IPP1Buttons.DOWN)
        this._state = IPP1DisplayState.MAIN_TESTER;
    }
    // Главное меню [тестер]
    else if (this._state === IPP1DisplayState.MAIN_TESTER) {
      if (button === IPP1Buttons.RIGHT)
        this._state = IPP1DisplayState.MAIN_CONNECTION;
      else if (button === IPP1Buttons.UP)
        this._state = IPP1DisplayState.MAIN_WORK;
      else if (button === IPP1Buttons.ENTER)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
    }
    // Главное меню [архив]
    else if (this._state === IPP1DisplayState.MAIN_ARCHIVE) {
      if (button === IPP1Buttons.LEFT) this._state = IPP1DisplayState.MAIN_WORK;
      else if (button === IPP1Buttons.DOWN)
        this._state = IPP1DisplayState.MAIN_CONNECTION;
    }
    // Главное меню [связь]
    else if (this._state === IPP1DisplayState.MAIN_CONNECTION) {
      if (button === IPP1Buttons.LEFT)
        this._state = IPP1DisplayState.MAIN_TESTER;
      else if (button === IPP1Buttons.UP)
        this._state = IPP1DisplayState.MAIN_ARCHIVE;
    }

    // Режим тестер [вольтметры]
    else if (this._state === IPP1DisplayState.TESTER_VOLTMETERS) {
      if (button === IPP1Buttons.DOWN)
        this._state = IPP1DisplayState.TESTER_POTENTIALS;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.MAIN_TESTER;
      else if (button === IPP1Buttons.ENTER)
        this._state = IPP1DisplayState.VOLTMETERS_02V;
    }
    // Режим тестер [потенциалы]
    else if (this._state === IPP1DisplayState.TESTER_POTENTIALS) {
      if (button === IPP1Buttons.UP)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.MAIN_TESTER;
      else if (button === IPP1Buttons.ENTER)
        this._state = IPP1DisplayState.POTENTIALS_RESULT;
    }

    // Режим потенциалы [результат]
    else if (this._state === IPP1DisplayState.POTENTIALS_RESULT) {
      if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.TESTER_POTENTIALS;
    }

    // Режим вольтметры [0.2В]
    else if (this._state === IPP1DisplayState.VOLTMETERS_02V) {
      if (button === IPP1Buttons.RIGHT)
        this._state = IPP1DisplayState.VOLTMETERS_10V;
      else if (button === IPP1Buttons.DOWN)
        this._state = IPP1DisplayState.VOLTMETERS_2V;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
    }
    // Режим вольтметры [2В]
    else if (this._state === IPP1DisplayState.VOLTMETERS_2V) {
      if (button === IPP1Buttons.RIGHT)
        this._state = IPP1DisplayState.VOLTMETERS_100V;
      else if (button === IPP1Buttons.UP)
        this._state = IPP1DisplayState.VOLTMETERS_02V;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
    }
    // Режим вольтметры [10В]
    else if (this._state === IPP1DisplayState.VOLTMETERS_10V) {
      if (button === IPP1Buttons.LEFT)
        this._state = IPP1DisplayState.VOLTMETERS_02V;
      else if (button === IPP1Buttons.DOWN)
        this._state = IPP1DisplayState.VOLTMETERS_100V;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
      else if (button === IPP1Buttons.ENTER)
        this._state = IPP1DisplayState.VOLTMETERS_10V_RESULT;
    }
    // Режим вольтметры [100В]
    else if (this._state === IPP1DisplayState.VOLTMETERS_100V) {
      if (button === IPP1Buttons.LEFT)
        this._state = IPP1DisplayState.VOLTMETERS_2V;
      else if (button === IPP1Buttons.UP)
        this._state = IPP1DisplayState.VOLTMETERS_10V;
      else if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.TESTER_VOLTMETERS;
    }

    // Режим вольтметры, 10В [результат]
    else if (this._state === IPP1DisplayState.VOLTMETERS_10V_RESULT) {
      if (button === IPP1Buttons.ESC)
        this._state = IPP1DisplayState.VOLTMETERS_10V;
    }
  }
}
