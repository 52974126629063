import {
  AbstractMesh,
  Material,
  Nullable,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { CommonPlaneModel } from '../../../common/models';

import { AcceptJumpersWindowConfig } from '../types';

import { AcceptJumpersImgWindowModel } from './accept-jumpers-img-window';

export class AcceptJumpersWindowModel {
  public static WIDTH = 180;
  public static HEIGHT = 120;
  public static REAL_SCALE = 0.135 / 180;

  private _plane: CommonPlaneModel;
  private _imgModel: AcceptJumpersImgWindowModel;

  get root(): TransformNode {
    return this._plane.root;
  }

  get mesh(): AbstractMesh {
    return this._plane.plane;
  }

  get material(): Nullable<Material> {
    return this._plane.plane.material;
  }

  set material(material: Nullable<Material>) {
    this._plane.plane.material = material;
  }

  public get imgModel(): AcceptJumpersImgWindowModel {
    return this._imgModel;
  }

  constructor(
    scene: Scene,
    plane: CommonPlaneModel,
    imgModel: AcceptJumpersImgWindowModel
  ) {
    this._plane = plane;
    this._imgModel = imgModel;
  }

  static async load(
    scene: Scene,
    cfg: AcceptJumpersWindowConfig
  ): Promise<AcceptJumpersWindowModel> {
    const planePromice = CommonPlaneModel.load(scene, {
      width:
        AcceptJumpersWindowModel.WIDTH * AcceptJumpersWindowModel.REAL_SCALE,
      height:
        AcceptJumpersWindowModel.HEIGHT * AcceptJumpersWindowModel.REAL_SCALE,
    });

    const imgModelPromice = AcceptJumpersImgWindowModel.load(scene, cfg);

    const [plane, imgModel] = await Promise.all([
      planePromice,
      imgModelPromice,
    ]);

    return new AcceptJumpersWindowModel(scene, plane, imgModel);
  }

  public setVisibility(isVisible: boolean): void {
    this._plane.setVisibility(isVisible);
    this._imgModel.setVisibility(isVisible);
  }
}
