import {
  Control,
  Measure,
  Rectangle,
  StackPanel,
  TextBlock,
} from '@babylonjs/gui';
import { StepsPanelItemStatus } from './types';

export class StepsPanelItem extends StackPanel {
  public static HEIGHT = 40;
  private TEXT_LEFT_PADDING = 6;

  private _status_icon: Rectangle;
  private _text: TextBlock;

  constructor(scale: number) {
    super('StepsPanelItem');

    this.isVertical = false;
    this.heightInPixels = StepsPanelItem.HEIGHT * scale;

    this._status_icon = new Rectangle('Status');
    this._status_icon.thickness = 0;
    this._status_icon.widthInPixels = StepsPanelItem.HEIGHT * scale;

    this._text = new TextBlock('Text');
    this._text.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this._text.paddingLeftInPixels = this.TEXT_LEFT_PADDING * scale;

    this.addControl(this._status_icon);
    this.addControl(this._text);
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    this._text.widthInPixels =
      parentMeasure.width - this._status_icon.widthInPixels;
  }

  setText(text: string): void {
    this._text.text = text;
  }

  setStatus(status: StepsPanelItemStatus): void {
    if (status === StepsPanelItemStatus.PASSED)
      this._status_icon.background = '#6c757d';
    else if (status === StepsPanelItemStatus.ACTIVE)
      this._status_icon.background = '#28a745';
    else this._status_icon.background = '#f8f9fa';
  }
}
