import { autorun } from 'mobx';

import { Color3, HighlightLayer, Scene, Vector3 } from '@babylonjs/core';

import { BaseModelController } from '../../../common/base';
import {
  IntersectionController,
  IntersectionTarget,
} from '../../../common/intersection';

import { IPP1SingleClipModel } from '../model';
import { IPP1SingleClipStore } from '../store/single-clip';

export class IPP1SingleClipsController extends BaseModelController<
  IPP1SingleClipModel,
  IPP1SingleClipStore,
  undefined
> {
  private _highlightLayer: HighlightLayer;
  private _intersection: IntersectionController;

  constructor(
    scene: Scene,
    model: IPP1SingleClipModel,
    store: IPP1SingleClipStore,
    highlightLayer: HighlightLayer
  ) {
    super(scene, model, store, undefined);

    this._highlightLayer = highlightLayer;
    this._intersection = new IntersectionController(
      scene,
      model.bbox,
      store.intersection
    );
    this.registerController(this._intersection);
  }

  protected _connectToStore(store: IPP1SingleClipStore, cfg: undefined): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      if (store.highlighted)
        this.model.bodyMeshes.forEach((e) => {
          this._highlightLayer.addMesh(e, Color3.Green());
        });
      else
        this.model.bodyMeshes.forEach((e) => {
          this._highlightLayer.removeMesh(e);
        });
    });
    autorun(() => {
      const types = store.connectTargetTypesEnabled;
      const targets: IntersectionTarget[] = [];
      store.connectTargets.forEach((e, id) => {
        if (!types.has(e.id.type)) return;
        targets.push({ id, mesh: e.bbox });
      });
      store.intersection.resetMarks();
      store.intersection.setTargets(targets);
    });
    autorun(() => {
      this.model.root.parent = store.connectStatusHelper;
      this.model.root.position = new Vector3(0, 0, 0);
      this.model.root.rotation = new Vector3(0, 0, 0);
    });
  }
}
