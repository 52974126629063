import { action, makeAutoObservable } from 'mobx';

import { TransformNode } from '@babylonjs/core';

import { ToolsType } from '../../tools';

import { PanelItemType, ToolsPanelPlace, ToolsPanelPlaceType } from './types';
import { ToolsPanelModel } from './model';

function itemToTool(v: PanelItemType): ToolsType | undefined {
  const dict = {
    [PanelItemType.KEY]: ToolsType.KEY,
    [PanelItemType.BRUSH]: ToolsType.BRUSH,
    [PanelItemType.WRENCH]: ToolsType.WRENCH,
    [PanelItemType.TUBE]: ToolsType.TUBE,
    [PanelItemType.TABLET]: ToolsType.TABLET,
    [PanelItemType.IPP1]: ToolsType.IPP1,
  } as Record<PanelItemType, ToolsType>;
  if (v in dict) return dict[v];
  return undefined;
}

function toolToItem(v: ToolsType): PanelItemType {
  const dict = {
    [ToolsType.KEY]: PanelItemType.KEY,
    [ToolsType.BRUSH]: PanelItemType.BRUSH,
    [ToolsType.WRENCH]: PanelItemType.WRENCH,
    [ToolsType.TUBE]: PanelItemType.TUBE,
    [ToolsType.TABLET]: PanelItemType.TABLET,
    [ToolsType.IPP1]: PanelItemType.IPP1,
  };
  return dict[v];
}

export interface IToolsPanelStore {
  /**
   * Выбраны ли перчатки
   */
  get gloveSelected(): boolean;

  /**
   * Какой нструмент выбран
   */
  get selectedTool(): ToolsType | undefined;

  /**
   * Список выбранных элементов
   */
  get selectedItems(): Set<PanelItemType>;

  /**
   * Видна ли панель
   */
  get isVisible(): boolean;
}

export class ToolsPanelStore implements IToolsPanelStore {
  private _model: ToolsPanelModel;

  private _gloveSelected = false;
  private _selectedTool?: ToolsType;
  private _isVisible = false;
  private _place?: ToolsPanelPlace;

  public get model(): ToolsPanelModel {
    return this._model;
  }

  public get gloveSelected(): boolean {
    return this._gloveSelected;
  }

  public get selectedTool(): ToolsType | undefined {
    return this._selectedTool;
  }

  public get selectedItems(): Set<PanelItemType> {
    const res = new Set<PanelItemType>();
    if (this._gloveSelected) res.add(PanelItemType.GLOVES);
    if (this._selectedTool !== undefined)
      res.add(toolToItem(this._selectedTool));
    return res;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get place(): ToolsPanelPlace | undefined {
    return this._place;
  }

  constructor(model: ToolsPanelModel) {
    this._model = model;
    makeAutoObservable(this, {
      clickToolButton: action,
      setVisibility: action,
    });
  }

  /**
   * Обработать событие нажатия на элемент панели
   */
  public clickToolButton(item: PanelItemType): void {
    if (item === PanelItemType.GLOVES) {
      this._gloveSelected = !this._gloveSelected;
      return;
    }

    const tool = itemToTool(item);
    if (tool === undefined) return;

    if (this._selectedTool !== tool) this._selectedTool = tool;
    else this._selectedTool = undefined;
  }

  /**
   * Установить видемость панели
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  /**
   * Установить родителя объекта
   */
  public moveTo(type: ToolsPanelPlaceType, parent: TransformNode): void {
    this._place = { parent, type };
  }
}
