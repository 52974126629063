import { Container, Measure } from '@babylonjs/gui';
import { drawRoundedRect } from '../draw-utils/rounded-rect';

/**
 * Class used to create rectangle container
 *
 * То же что и встроенный в Babylon Rectangle, но с исправленной ошибкой отрисовки скругленных краев
 */
export class RectangleCorrect extends Container {
  private _thickness = 1;
  private _cornerRadius = 0;

  /** Gets or sets border thickness */
  public get thickness(): number {
    return this._thickness;
  }

  public set thickness(value: number) {
    if (this._thickness === value) {
      return;
    }

    this._thickness = value;
    this._markAsDirty();
  }

  /** Gets or sets the corner radius angle */
  public get cornerRadius(): number {
    return this._cornerRadius;
  }

  public set cornerRadius(value: number) {
    const newValue = value < 0 ? 0 : value;
    if (this._cornerRadius === newValue) return;

    this._cornerRadius = newValue;
    this._markAsDirty();
  }

  /**
   * Creates a new Rectangle
   * @param name defines the control name
   */
  constructor(public name?: string) {
    super(name);
  }

  protected _getTypeName(): string {
    return this.constructor.name;
  }

  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();

    if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
      context.shadowColor = this.shadowColor;
      context.shadowBlur = this.shadowBlur;
      context.shadowOffsetX = this.shadowOffsetX;
      context.shadowOffsetY = this.shadowOffsetY;
    }

    if (this._background) {
      context.fillStyle = this._background;

      if (this._cornerRadius) {
        this._drawRoundedRect(context, this._thickness / 2);
        context.fill();
      } else {
        context.fillRect(
          this._currentMeasure.left,
          this._currentMeasure.top,
          this._currentMeasure.width,
          this._currentMeasure.height
        );
      }
    }

    if (this._thickness) {
      if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
      }

      if (this.color) {
        context.strokeStyle = this.color;
      }
      context.lineWidth = this._thickness;

      if (this._cornerRadius) {
        this._drawRoundedRect(context, this._thickness / 2);
        context.stroke();
      } else {
        context.strokeRect(
          this._currentMeasure.left + this._thickness / 2,
          this._currentMeasure.top + this._thickness / 2,
          this._currentMeasure.width - this._thickness,
          this._currentMeasure.height - this._thickness
        );
      }
    }

    context.restore();
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    this._measureForChildren.width -= 2 * this._thickness;
    this._measureForChildren.height -= 2 * this._thickness;
    this._measureForChildren.left += this._thickness;
    this._measureForChildren.top += this._thickness;
  }

  private _drawRoundedRect(
    context: CanvasRenderingContext2D,
    offset = 0
  ): void {
    drawRoundedRect(context, this._currentMeasure, this._cornerRadius, offset);
  }

  protected _clipForChildren(context: CanvasRenderingContext2D): void {
    if (this._cornerRadius) {
      this._drawRoundedRect(context, this._thickness);
      context.clip();
    }
  }
}
