import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelController } from '../../../common/base';

import { SingleHandModel } from '../model';
import { SingleHandStore } from '../store';

import { HandAnimationHelper } from './animationController';

export class SingleHandController extends BaseModelController<
  SingleHandModel,
  SingleHandStore,
  undefined
> {
  private _handAnimationTime = 0.4;
  private _animation: HandAnimationHelper;

  constructor(scene: Scene, model: SingleHandModel, store: SingleHandStore) {
    super(scene, model, store, undefined);

    this._animation = new HandAnimationHelper(model);
  }

  protected _connectToStore(store: SingleHandStore, cfg: undefined): void {
    autorun(() => {
      this._animation.setState(store.targetState, this._handAnimationTime);
    });
    autorun(() => {
      this.model.setVisibility(store.isVisible, store.isGloveVisible);
    });

    this._animation.onAnimationEnd = () => {
      store.animationComplete();
    };
  }
}
