import { makeAutoObservable } from 'mobx';

import { AcceptJumpersWindowStore } from '../accept-jumpers';
import { CraneUnitStore } from '../crane-unit';
import { IEMCVEStore } from '../EMCVE';
import { ITechBlockStore } from '../tech-block';
import { InfoPanelStore } from '../info-panel';
import { JumpersStore } from '../jumpers';
import { KIPStore } from '../kip';
import { ResultPanelStore } from '../result-panel';
import { ToolsPanelStore } from '../tools-panel';

export type SceneStoreList = {
  acceptJumpersWindow?: AcceptJumpersWindowStore;
  craneUnit?: CraneUnitStore;
  EMCVE?: IEMCVEStore;
  infoPanel?: InfoPanelStore;
  jumpers?: JumpersStore;
  KIP?: KIPStore;
  resultPanel?: ResultPanelStore;
  techBlock?: ITechBlockStore;
  toolsPanel?: ToolsPanelStore;
};

export class SceneStore {
  private _stores: SceneStoreList;

  /**
   * Окно с вопросом верны ли перемычки
   */
  public get acceptJumpersWindow(): AcceptJumpersWindowStore | undefined {
    return this._stores.acceptJumpersWindow;
  }

  /**
   * Крановый узел
   */
  public get craneUnit(): CraneUnitStore | undefined {
    return this._stores.craneUnit;
  }

  /**
   * Электрод сравнения (он же копье)
   */
  public get EMCVE(): IEMCVEStore | undefined {
    return this._stores.EMCVE;
  }

  /**
   * Панель с информацией
   */
  public get infoPanel(): InfoPanelStore | undefined {
    return this._stores.infoPanel;
  }

  /**
   * Перемычки (вообще не долены быть объектом, legacy)
   */
  public get jumpers(): JumpersStore | undefined {
    return this._stores.jumpers;
  }

  /**
   * Его величество КИП
   */
  public get KIP(): KIPStore | undefined {
    return this._stores.KIP;
  }

  /**
   * Панель с результатами
   */
  public get resultPanel(): ResultPanelStore | undefined {
    return this._stores.resultPanel;
  }

  /**
   * Технологический блок
   */
  public get techBlock(): ITechBlockStore | undefined {
    return this._stores.techBlock;
  }

  /**
   * Панель инструментов
   */
  public get toolsPanel(): ToolsPanelStore | undefined {
    return this._stores.toolsPanel;
  }

  constructor(stores: SceneStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
