/* eslint-disable no-console */

import { AudioHint } from './audio-hint';

export class LessonAudioHints {
  private stepNumberToFileName: Record<string, string>;

  private audioHints: Record<string, AudioHint>;
  private current: AudioHint | null;

  constructor(stepNumberToFileName: Record<number, string>) {
    if (!stepNumberToFileName) {
      console.error(
        'Either scene or step id to audiofile name map is undefined'
      );
    }
    this.stepNumberToFileName = stepNumberToFileName;
    this.audioHints = {};
    this.current = null;
  }

  loadAllAudioHints(): void {
    Object.entries(this.stepNumberToFileName).forEach(
      ([stepNumber, fileName]) => {
        const audioHint = new AudioHint(fileName);
        this.audioHints[stepNumber] = audioHint;
      }
    );
  }

  playAudioHintForStep(stepNumber: number): void {
    const audioHint = this.audioHints[stepNumber];
    if (!audioHint) return;
    if (this.current?.isPlaying) {
      this.current.pauseSound();
    }
    audioHint.playSound();
    this.current = audioHint;
  }

  loadAudioHintForStepNumber(stepNumber: number): void {
    const fileName = this.stepNumberToFileName[stepNumber];
    if (!fileName) return;

    const audioHint = new AudioHint(fileName);
    this.audioHints[stepNumber] = audioHint;
  }
}
