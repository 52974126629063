import { Control } from '@babylonjs/gui';

import { RectangleCorrect } from 'features/lesson-page/scene-components/widgets';

import { StepsPanelContent } from './steps-panel-content';
import { StepsPanelItem } from './steps-panel-item';

export class StepsPanelWidget extends RectangleCorrect {
  private WIDTH = 500;
  private CORNER_RADIUS = 10;

  private _scale: number;
  private _content: StepsPanelContent;

  get content(): StepsPanelContent {
    return this._content;
  }

  constructor(scale: number) {
    super('StepsPanel');
    this._scale = scale;

    this.isPointerBlocker = true;
    this._content = new StepsPanelContent(scale);
    this._content.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    this.widthInPixels = this.WIDTH * scale;
    this.cornerRadius = this.CORNER_RADIUS * scale;
    this.thickness = 0;

    this.showLines(0, 1);

    this.addControl(this._content);
  }

  showLines(start: number, end: number): void {
    this.heightInPixels = StepsPanelItem.HEIGHT * (end - start) * this._scale;
    this._content.top = -StepsPanelItem.HEIGHT * start * this._scale;
  }
}
