import { autorun } from 'mobx';

import {
  Nullable,
  Observable,
  Observer,
  Scene,
  TransformNode,
  Vector3,
} from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { KeyConfig } from './types';
import { KeyModel } from './model';
import { KeyStore } from './store';
import { setupLogic } from './logic';

export class KeyObject extends BaseModelObject<KeyModel, KeyStore, KeyConfig> {
  private _intersect: IntersectionController;

  private _distanceHook: Nullable<Observer<Scene>> = null;

  public onDistanceToMeshUpdate = new Observable<number>();

  constructor(scene: Scene, model: KeyModel, store: KeyStore, cfg: KeyConfig) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersect = new IntersectionController(
      scene,
      model.bbox,
      store.intersect
    );
    this.registerController(this._intersect);
  }

  public static async setup(scene: Scene, cfg: KeyConfig): Promise<KeyObject> {
    const model = await KeyModel.load(cfg, scene);
    const store = new KeyStore(model.root);
    return new KeyObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: KeyStore, cfg: KeyConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });

    autorun(() => {
      this.clearDistanceHook();
      if (store.distanceTarget) this.setupDistanceHook(store.distanceTarget);
    });

    // Установка угла поворота ключа
    autorun(() => {
      if (!store.attached) return;
      store.root.rotation = new Vector3(store.rotationAngle, 0, 0);
    });

    this.onDistanceToMeshUpdate.add((v) => {
      store.setDistanceIsToFar(v > 0.2);
    });
  }

  public clearDistanceHook(): void {
    if (this._distanceHook)
      this.scene.onBeforeRenderObservable.remove(this._distanceHook);
    this._distanceHook = null;
  }

  public setupDistanceHook(mesh: TransformNode): void {
    if (this._distanceHook) this.clearDistanceHook();

    this._distanceHook = this.scene.onBeforeRenderObservable.add(() => {
      const dist = Vector3.Distance(
        this.model.root.absolutePosition,
        mesh.absolutePosition
      );
      this.onDistanceToMeshUpdate.notifyObservers(dist);
    });
  }
}
