import {
  AbstractMesh,
  Mesh,
  MeshBuilder,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { PlaneConfig } from './types';

export class PlaneModel {
  private _plane: Mesh;

  constructor(scene: Scene, width: number, height: number) {
    this._plane = MeshBuilder.CreatePlane(
      'PlaneModel_plane',
      { width, height },
      scene
    );
    this._plane.billboardMode = Mesh.BILLBOARDMODE_NONE;
  }

  static load(scene: Scene, cfg: PlaneConfig): Promise<PlaneModel> {
    return Promise.resolve(new PlaneModel(scene, cfg.width, cfg.height));
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    this._plane.isVisible = isVisible;
  }

  /**
   * Получить мешь плоскости
   */
  get plane(): AbstractMesh {
    return this._plane;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): TransformNode {
    return this._plane;
  }
}
