import { Animation, AnimationGroup, TargetedAnimation } from '@babylonjs/core';

import { HandState } from '../types';
import { SingleHandModel } from '../model';

/**
 * Найти AnimationTarget из списка, похожий на данный таргет
 */
function findSameTarget(
  anims: TargetedAnimation[],
  target: TargetedAnimation,
  startI = 0
): number {
  for (let i = 0; i < anims.length; i += 1) {
    const j = (i + startI) % anims.length;
    const anim = anims[j];
    if (
      anim.target === target.target &&
      anim.animation.targetProperty === target.animation.targetProperty
    )
      return j;
  }
  throw Error('Bad model. Same TargetedAnimation not found in given array');
}

/**
 * Изменить порядок анимаций так, чтобы она соответствовалла тому же порядку, что в target
 */
function reorderAnimsAsTargets(
  anims: TargetedAnimation[],
  targets: TargetedAnimation[]
): TargetedAnimation[] {
  const res: TargetedAnimation[] = [];

  let i = -1;
  for (const target of targets) {
    i = findSameTarget(anims, target, i + 1);
    res.push(anims[i]);
  }

  return res;
}

export class HandAnimationHelper {
  private _targets: Record<HandState, TargetedAnimation[]>;

  private _curState: HandState;
  private _curAnims: Animation[];
  private _curAnimGroup: AnimationGroup;

  public onAnimationEnd: (() => void) | null = null;

  constructor(model: SingleHandModel) {
    const defaultTargetAnim = model.animations[HandState.FIXED];
    const defaultAnim = defaultTargetAnim.targetedAnimations;

    defaultTargetAnim.start(false);
    this._curState = HandState.FIXED;

    this._targets = {} as Record<HandState, TargetedAnimation[]>;
    for (const rawState of Object.keys(model.animations)) {
      const state = rawState as unknown as HandState;
      const anims = model.animations[state].targetedAnimations;
      if (anims.length !== defaultAnim.length)
        throw Error(
          'Bad model. Animation groups has different numer of targetedAnimations'
        );

      this._targets[state] = reorderAnimsAsTargets(anims, defaultAnim);
      if (!anims.every((a) => a.animation.getKeys().length === 1)) {
        throw Error('Bad model. There are more than 1 key for some animations');
      }
    }

    // Создание рабочей анимации (анимация, которая будет воспроизводиться в будущем)
    this._curAnims = [];
    this._curAnimGroup = new AnimationGroup('curAnimationHand');
    for (const anim of defaultAnim) {
      const newAnim = anim.animation.clone();
      newAnim.framePerSecond = 1;
      this._curAnims.push(newAnim);
      this._curAnimGroup.addTargetedAnimation(newAnim, anim.target);
    }
    this._curAnimGroup.normalize(0, 1);
    this._curAnimGroup.onAnimationGroupEndObservable.add(() => {
      this.onAnimationEnd && this.onAnimationEnd();
    });
  }

  private _configureCurAnimGroup(fromState: HandState, toState: HandState) {
    const getKeyValue = (state: HandState, i: number) =>
      this._targets[state][i].animation.getKeys()[0].value;

    for (let i = 0; i < this._curAnims.length; i += 1) {
      this._curAnims[i].setKeys([
        {
          frame: 0,
          value: getKeyValue(fromState, i),
        },
        {
          frame: 1,
          value: getKeyValue(toState, i),
        },
      ]);
    }
  }

  public setState(state: HandState, time: number): void {
    if (state === this._curState) {
      this.onAnimationEnd && this.onAnimationEnd();
      return;
    }

    this._configureCurAnimGroup(this._curState, state);
    this._curState = state;

    const speedRatio = time <= 0 ? 10000 : 1 / time;
    this._curAnimGroup.start(false, speedRatio);
  }
}
