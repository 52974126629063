import { Scene } from '@babylonjs/core';

import { BaseObject } from '../../common/base';
import { LandscapeObject } from '../landscape';
import { LightsObject } from '../lights';
import { SkyboxObject } from '../skybox';
import { WorldConfig } from './types';
import { WorldStore, WorldStoreList } from './store';

type WorldObjectList = {
  landscape?: LandscapeObject;
  lights?: LightsObject;
  skybox?: SkyboxObject;
};

export class WorldObject extends BaseObject<WorldStore, WorldConfig> {
  private _objects: WorldObjectList;

  /**
   * Ланшафт
   */
  public get landscape(): LandscapeObject | undefined {
    return this._objects.landscape;
  }

  /**
   * Свет
   */
  public get lights(): LightsObject | undefined {
    return this._objects.lights;
  }

  /**
   * Скайбокс
   */
  public get skybox(): SkyboxObject | undefined {
    return this._objects.skybox;
  }

  constructor(
    scene: Scene,
    objects: WorldObjectList,
    store: WorldStore,
    cfg: WorldConfig
  ) {
    super(scene, store, cfg);
    objects.landscape && this.registerObject(objects.landscape);
    objects.lights && this.registerObject(objects.lights);
    objects.skybox && this.registerObject(objects.skybox);
    this._objects = objects;
  }

  public static async setup(
    scene: Scene,
    cfg: WorldConfig
  ): Promise<WorldObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.landscape ? LandscapeObject.setup(scene, cfg.landscape) : none,
      cfg.lights ? LightsObject.setup(scene, cfg.lights) : none,
      cfg.skybox ? SkyboxObject.setup(scene, cfg.skybox) : none,
    ]);

    // Build objects
    const objects: WorldObjectList = {};
    [objects.landscape, objects.lights, objects.skybox] = await loadedObjects;

    // Build stores
    const stores: WorldStoreList = {};
    stores.landscape = objects.landscape?.store;
    stores.lights = objects.lights?.store;
    stores.skybox = objects.skybox?.store;

    return new WorldObject(scene, objects, new WorldStore(stores), cfg);
  }
}
