import { Scene } from '@babylonjs/core';

import { BaseObject } from '../../common/base';

import { BrushObject } from '../brush';
import { IPP1Object } from '../ipp1';
import { KeyObject } from '../key';
import { TabletObject } from '../tablet';
import { TubeObject } from '../tube';
import { WrenchObject } from '../wrench';

import { ToolsConfig } from './types';
import { ToolsStore, ToolsStoreList } from './store';

type ToolsObjectList = {
  brush?: BrushObject;
  ipp1?: IPP1Object;
  key?: KeyObject;
  tablet?: TabletObject;
  tube?: TubeObject;
  wrench?: WrenchObject;
};

export class ToolsObject extends BaseObject<ToolsStore, ToolsConfig> {
  constructor(
    scene: Scene,
    objects: ToolsObjectList,
    store: ToolsStore,
    cfg: ToolsConfig
  ) {
    super(scene, store, cfg);
    objects.brush && this.registerObject(objects.brush);
    objects.ipp1 && this.registerObject(objects.ipp1);
    objects.key && this.registerObject(objects.key);
    objects.tablet && this.registerObject(objects.tablet);
    objects.tube && this.registerObject(objects.tube);
    objects.wrench && this.registerObject(objects.wrench);
  }

  public static async setup(
    scene: Scene,
    cfg: ToolsConfig
  ): Promise<ToolsObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.brush ? BrushObject.setup(scene, cfg.brush) : none,
      cfg.ipp1 ? IPP1Object.setup(scene, cfg.ipp1) : none,
      cfg.key ? KeyObject.setup(scene, cfg.key) : none,
      cfg.tablet ? TabletObject.setup(scene, cfg.tablet) : none,
      cfg.tube ? TubeObject.setup(scene, cfg.tube) : none,
      cfg.wrench ? WrenchObject.setup(scene, cfg.wrench) : none,
    ]);

    // Build objects
    const objects: ToolsObjectList = {};
    [
      objects.brush,
      objects.ipp1,
      objects.key,
      objects.tablet,
      objects.tube,
      objects.wrench,
    ] = await loadedObjects;

    // Build stores
    const stores: ToolsStoreList = {};
    stores.brush = objects.brush?.store;
    stores.ipp1 = objects.ipp1?.store;
    stores.key = objects.key?.store;
    stores.tablet = objects.tablet?.store;
    stores.tube = objects.tube?.store;
    stores.wrench = objects.wrench?.store;

    return new ToolsObject(scene, objects, new ToolsStore(stores), cfg);
  }
}
