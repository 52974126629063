import { UIAskIfAcceptWindowGUIStore } from 'features/lesson-page/scene-components/user-interfaces';
import { action, computed, makeObservable, observable } from 'mobx';

export class AcceptJumpersWindowStore extends UIAskIfAcceptWindowGUIStore {
  private _isVisible = false;
  private _isAccepted: boolean | null = null;

  get isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * Корректно ли расположены перемычки
   */
  public get isAccepted(): boolean | null {
    return this._isAccepted;
  }

  constructor() {
    super();
    makeObservable<AcceptJumpersWindowStore, '_isVisible' | '_isAccepted'>(
      this,
      {
        _isVisible: observable,
        isVisible: computed,
        setVisibility: action,

        _isAccepted: observable,
        isAccepted: computed,
        setIsAccepted: action,
      }
    );
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  public setIsAccepted(isAccepted: boolean): void {
    this._isAccepted = isAccepted;
  }
}
