import { action, makeAutoObservable } from 'mobx';

export class KIPDoorStore {
  private _targetIsOpen = false;
  private _isOpen = false;
  private _isHighlighted = false;

  /**
   * Открыта ли дверь
   */
  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
  }

  /**
   * Целевое состояние двери
   */
  public get targetIsOpen(): boolean {
    return this._targetIsOpen;
  }

  public set targetIsOpen(value: boolean) {
    this._targetIsOpen = value;
  }

  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public set isHighlighted(value: boolean) {
    this._isHighlighted = value;
  }

  constructor() {
    makeAutoObservable(this, {
      toggleOpenState: action,
    });
  }

  /**
   * Переключить состояние двери на противоположное
   */
  toggleOpenState(): void {
    this._targetIsOpen = !this._isOpen;
  }

  setOpenState(isOpen: boolean): void {
    this._targetIsOpen = isOpen;
  }
}
