import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { SkyboxConfig } from './types';
import { SkyboxModel } from './model';
import { SkyboxStore } from './store';
import { setupLogic } from './logic';

export class SkyboxObject extends BaseModelObject<
  SkyboxModel,
  SkyboxStore,
  SkyboxConfig
> {
  constructor(
    scene: Scene,
    model: SkyboxModel,
    store: SkyboxStore,
    cfg: SkyboxConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  protected _connectToStore(store: SkyboxStore, cfg: SkyboxConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }

  /**
   * Скачать все модели и создать Skybox
   */
  public static async setup(
    scene: Scene,
    cfg: SkyboxConfig
  ): Promise<SkyboxObject> {
    const model = await SkyboxModel.load(scene, cfg);
    const store = new SkyboxStore();
    return new SkyboxObject(scene, model, store, cfg);
  }
}
