import { StackPanel } from '@babylonjs/gui';

import { StepsPanelItem } from './steps-panel-item';
import { StepsPanelItemStatus } from './types';

export class StepsPanelContent extends StackPanel {
  private _scale: number;
  private _lines: StepsPanelItem[] = [];

  constructor(scale: number) {
    super('StepsPanelContent');
    this._scale = scale;

    this.isVertical = true;
    this.background = 'white';
  }

  setSteps(text: string[], status: StepsPanelItemStatus[]): void {
    const newLines = Math.min(text.length, status.length);
    const l = Math.min(this._lines.length, newLines);

    // Add new lines
    for (let i = l; i < newLines; i += 1) {
      const newItem = new StepsPanelItem(this._scale);
      this._lines.push(newItem);
      this.addControl(newItem);
    }
    // Set info
    for (let i = 0; i < newLines; i += 1) {
      this._lines[i].setText(text[i]);
      this._lines[i].setStatus(status[i]);
    }
    // Remove unused lines
    for (let i = newLines; i < this._lines.length; i += 1) {
      this.removeControl(this._lines[i]);
    }
  }

  updateLineStatus(index: number, status: StepsPanelItemStatus): void {
    this._lines[index].setStatus(status);
  }

  getLineCount(): number {
    return this._lines.length;
  }
}
