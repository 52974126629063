import { action, makeAutoObservable } from 'mobx';

import {
  InfoPageContentType,
  StepsPageContentLineType,
  StepsPageContentType,
} from './types';

export type { InfoPageContentType, StepsPageContentLineType } from './types';
export { StepsPageContentType } from './types';

export class ResultPanelStore {
  private _pageNo = 0;
  private _infoPageContent: InfoPageContentType;
  private _stepsPageContent = new StepsPageContentType();
  private _isVisible = false;

  public get pageNo(): number {
    return this._pageNo;
  }

  public get infoPageContent(): InfoPageContentType {
    return this._infoPageContent;
  }

  public get stepsPageContent(): StepsPageContentType {
    return this._stepsPageContent;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor() {
    this._infoPageContent = {
      fullName: '',
      lessonTitle: '',
      lessonDuration: '',
      lessonMode: '',
    };

    makeAutoObservable(this, {
      openPage: action,
      setInfoContent: action,
      setStepsContent: action,
      setVisibility: action,
    });
  }

  public openPage(pageNo: number): void {
    if (pageNo < 0 || pageNo >= 2) return;
    this._pageNo = pageNo;
  }

  public setInfoContent(cnt: InfoPageContentType): void {
    this._infoPageContent.fullName = cnt.fullName;
    this._infoPageContent.lessonTitle = cnt.lessonTitle;
    this._infoPageContent.lessonDuration = cnt.lessonDuration;
    this._infoPageContent.lessonMode = cnt.lessonMode;
  }

  public setStepsContent(cnt: StepsPageContentLineType[]): void {
    this._stepsPageContent.setContent(cnt);
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
