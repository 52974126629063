import {
  ActionManager,
  ExecuteCodeAction,
  IAction,
  Observable,
  Scene,
} from '@babylonjs/core';

import { BaseModelController } from '../../../common/base';

import { IPP1Buttons, IPP1Config } from '../types';
import { IPP1ButtonsStore } from '../store/buttons';

import { IPP1Model } from '../model';

export class IPP1ButtonsController extends BaseModelController<
  IPP1Model,
  IPP1ButtonsStore,
  IPP1Config
> {
  private _actions: IAction[] = [];

  private onClick = new Observable<[IPP1Buttons, boolean]>();

  constructor(
    scene: Scene,
    model: IPP1Model,
    store: IPP1ButtonsStore,
    cfg: IPP1Config
  ) {
    super(scene, model, store, cfg);

    for (const b of this.model.buttons)
      b.actionManager = new ActionManager(scene);
  }

  protected _connectToStore(store: IPP1ButtonsStore, cfg: IPP1Config): void {
    this.registerClickHook();
    this.onClick.add(([bnt, isDown]) => {
      store.setButtonDown(isDown ? bnt : undefined);
    });
  }

  /**
   * Установить hook на событие клика мышкой на кнопки
   */
  private registerClickHook(): void {
    this.model.buttons.forEach((mesh, id) => {
      const actionDown = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
          this.onClick.notifyObservers([id, true]);
        })
      );
      const actionUp = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickUpTrigger, () => {
          this.onClick.notifyObservers([id, false]);
        })
      );
      const actionOut = mesh.actionManager!.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickOutTrigger, () => {
          this.onClick.notifyObservers([id, false]);
        })
      );
      actionDown && this._actions.push(actionDown);
      actionUp && this._actions.push(actionUp);
      actionOut && this._actions.push(actionOut);
    });
  }

  /**
   * Удалить все зарегестрированные hooks
   */
  private unregisterAllHooks(): void {
    this.model.buttons.forEach((mesh, id) => {
      mesh.actionManager!.unregisterAction(this._actions[id]);
    });
    this._actions = [];
  }
}
