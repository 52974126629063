import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IIPP1ButtonsStore, IPP1ButtonsStore } from './buttons';
import { IIPP1ClipsStore, IPP1ClipsStore } from './clips';
import { IIPP1DisplayStore, IPP1DisplayStore } from './display';
import { IPP1ClipsConnectHelpers } from '../types';
import { IPP1Model } from '../model';

export interface IIPP1Store {
  get root(): TransformNode;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Кнопки прибора
   */
  get buttons(): IIPP1ButtonsStore;

  /**
   * Дисплей прибора
   */
  get display(): IIPP1DisplayStore;

  /**
   * Крокодилы
   */
  get clips(): IIPP1ClipsStore;
}

export class IPP1Store implements IIPP1Store {
  private _isVisible = false;
  private _root: TransformNode;
  private _clipLeftHelpers: IPP1ClipsConnectHelpers;
  private _clipRightHelpers: IPP1ClipsConnectHelpers;

  private _display = new IPP1DisplayStore();
  private _buttons = new IPP1ButtonsStore();
  private _clips: IPP1ClipsStore;

  public get root(): TransformNode {
    return this._root;
  }

  public get clipLeftHelpers(): IPP1ClipsConnectHelpers {
    return this._clipLeftHelpers;
  }

  public get clipRightHelpers(): IPP1ClipsConnectHelpers {
    return this._clipRightHelpers;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get buttons(): IPP1ButtonsStore {
    return this._buttons;
  }

  public get display(): IPP1DisplayStore {
    return this._display;
  }

  public get clips(): IPP1ClipsStore {
    return this._clips;
  }

  constructor(model: IPP1Model) {
    this._root = model.root;
    this._clipLeftHelpers = model.clipLeftHelpers;
    this._clipRightHelpers = model.clipRightHelpers;
    this._clips = new IPP1ClipsStore(model.clips);

    makeAutoObservable(this, {
      setVisibility: action,
      moveToParent: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  public moveToParent(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
    this._root.rotation = new Vector3(0, 0, 0);
  }
}
