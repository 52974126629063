import { action, makeAutoObservable } from 'mobx';

export class KIPLabelsStore {
  private _labelsCount = -1;
  private _touchedLabels: Set<number> = new Set();

  /**
   * Надписи, до которых дотронулись
   */
  public get touchedLabels(): number[] {
    return Array.from(this._touchedLabels);
  }

  /**
   * Все ли надписи были потроганы
   */
  public get areAllLabelsTouched(): boolean {
    return this._touchedLabels.size === this._labelsCount;
  }

  constructor() {
    makeAutoObservable(this, {
      setLabelsCount: action,
      setTouchedLabel: action,
    });
  }

  /**
   * Установить кол-во надписей КИПа
   */
  public setLabelsCount(labelsCount: number): void {
    this._labelsCount = labelsCount;
  }

  /**
   * Добавить id надписи в список потроганных надписей
   */
  public setTouchedLabel(labelId: number): void {
    this._touchedLabels.add(labelId);
  }
}
