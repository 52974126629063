import {
  Matrix,
  Nullable,
  Observable,
  Observer,
  Scene,
  TransformNode,
  Vector3,
} from '@babylonjs/core';

import { autorun } from 'mobx';

import { BaseModelController } from '../base';

import { IntersectionController } from '../intersection';
import { WheelModel } from './model';
import { WheelStore } from './store';

/**
 * Контроллер отслеживающий вращение заданного объекта относительно XY плоскости руля и передающий его как вращение руля.
 */
export class WheelController extends BaseModelController<
  WheelModel,
  WheelStore,
  undefined
> {
  private _rotationHook: Nullable<Observer<Scene>> = null;
  private onRotationUpdate = new Observable<number>();

  private _intersect: IntersectionController;

  constructor(scene: Scene, model: WheelModel, store: WheelStore) {
    super(scene, model, store, undefined);
    this._intersect = new IntersectionController(
      scene,
      model.mesh,
      store.intersect
    );
    this.registerController(this._intersect);
  }

  protected _connectToStore(store: WheelStore, cfg: undefined): void {
    // Реакции на изменения value(угла поворота) в store
    autorun(() => {
      this.model.mesh.rotation = new Vector3(0, 0, store.value);
    });
    autorun(() => {
      this.clearRotationHook();
      if (this.store.rotationTarget && this.store.isEnabled)
        this.setupRotationHook(this.store.rotationTarget);
    });
    this.onRotationUpdate.add((angle: number) => {
      if (Math.abs(angle) < store.maxRotation) {
        this.store.setValue(angle);
      }
    });
  }

  private clearRotationHook(): void {
    if (this._rotationHook)
      this.scene.onBeforeRenderObservable.remove(this._rotationHook);
    this._rotationHook = null;
  }

  public setupRotationHook(mesh: TransformNode): void {
    if (this._rotationHook) this.clearRotationHook();
    const m = new Matrix();
    this.model.mesh.getWorldMatrix().invertToRef(m);
    let initialPosition = Vector3.TransformCoordinates(
      mesh.getAbsolutePosition(),
      m
    );
    const initialRotationAngle = this.model.mesh.rotation.z;
    let resultAngle = initialRotationAngle;
    initialPosition.z = 0;
    this._rotationHook = this.scene.onBeforeRenderObservable.add(() => {
      const handLocalPos = Vector3.TransformCoordinates(
        mesh.getAbsolutePosition(),
        m
      );
      handLocalPos.z = 0;
      const angle = Vector3.GetAngleBetweenVectors(
        initialPosition,
        handLocalPos,
        this.model.mesh.forward
      );
      // Babylon js bug, return NaN when vectors are the same or directly opposite
      if (!isNaN(angle)) {
        resultAngle += angle;
      }
      initialPosition = handLocalPos;
      this.onRotationUpdate.notifyObservers(resultAngle);
    });
  }
}
