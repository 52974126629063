import SimplePeer from 'simple-peer';

export interface Isignal {
  sdp: string;
  type: string;
}

export class StreamInitiator {
  public adminId: string;
  public stream: MediaStream | null;
  private _broadcaster: SimplePeer.Instance;
  private _canvas: HTMLCanvasElement;
  private onSignalCallback: (message: Isignal) => void;
  constructor(
    adminId: string,
    canvas: HTMLCanvasElement,
    onSignalCallback: (message: Isignal) => void
  ) {
    this.adminId = adminId;
    this._broadcaster = new SimplePeer({
      initiator: true,
      trickle: false,
    });
    this._canvas = canvas;
    this.stream = null;
    this.onSignalCallback = onSignalCallback;
    this.setEvents();
    this.captureStream();
  }

  captureStream(): void {
    this.stream = this._canvas.captureStream();
    this.stream.getTracks().forEach((track) => {
      this._broadcaster.addTrack(track, this.stream!);
    });
  }

  setEvents(): void {
    this._broadcaster.on('signal', (data: Isignal) => {
      this.onSignalCallback(data);
    });
    this._broadcaster.on('close', () => {
      this._broadcaster.removeAllListeners('close');
      this._broadcaster.destroy();
    });
  }

  public sendSignal(message: string): void {
    this._broadcaster.signal(message);
  }
}
