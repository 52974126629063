import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import {
  findByName,
  getRootUrlForObject,
  loadJSImage,
  loadMesh,
} from '../../common/utils';

import { TabletConfig } from './types';

export class TabletModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _main: AbstractMesh;
  private _sheet: AbstractMesh;

  private _uiImages: HTMLImageElement[];

  public get uiImages(): HTMLImageElement[] {
    return this._uiImages;
  }

  constructor(
    scene: Scene,
    model: ISceneLoaderAsyncResult,
    uiImages: HTMLImageElement[]
  ) {
    this._uiImages = uiImages;
    this._main = findByName(model.meshes, 'Tablet');
    this._sheet = findByName(model.meshes, 'Sheet');
  }

  static async load(scene: Scene, cfg: TabletConfig): Promise<TabletModel> {
    const imagesPromise: Promise<HTMLImageElement>[] = [];

    const uiRootUrl = getRootUrlForObject(
      cfg.ui.root_url,
      this.MODEL_VERSION,
      cfg.ui.folder
    );

    for (let i = 0; i < 10; i += 1)
      imagesPromise.push(loadJSImage(`${uiRootUrl}${i}.png`));

    const [model, uiImages] = await Promise.all([
      loadMesh(scene, cfg.model, this.MODEL_VERSION),
      Promise.all(imagesPromise),
    ]);

    return new TabletModel(scene, model, uiImages);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    this._main.isVisible = isVisible;
    this._sheet.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): TransformNode {
    return this._main;
  }

  get mainMesh(): AbstractMesh {
    return this._main;
  }

  get sheetMesh(): AbstractMesh {
    return this._sheet;
  }
}
