import { action, makeAutoObservable } from 'mobx';

import { AbstractMesh, TransformNode } from '@babylonjs/core';

import { IntersectionStore } from '../../../common/intersection';

import {
  IPP1ClipConnectTarget,
  IPP1ClipConnectTargetId,
  IPP1ClipConnectType,
} from '../types';
import { IPP1SingleClipModel } from '../model';

export interface IIPP1SingleClipStore {
  /**
   * Порядковый номер крокодила в массиве крокодилов
   */
  get id(): number;

  /**
   * Корень, к которому можно привязывать модели
   */
  get root(): TransformNode;

  /**
   * BBox для проверки на пересечения
   */
  get bbox(): AbstractMesh;

  /**
   * Виден ли объект
   */
  get isVisible(): boolean;

  /**
   * К чему подключен данный крокодил
   */
  get connectStatus(): IPP1ClipConnectTargetId | undefined;

  /**
   * Находится ли крокодил в руках
   */
  get inHand(): boolean;
}

export class IPP1SingleClipStore implements IIPP1SingleClipStore {
  private _id: number;
  private _mesh: IPP1SingleClipModel;
  private _isVisible = true;
  private _highlighted = false;

  private _connectStatus?: IPP1ClipConnectTargetId;
  private _connectTargets: IPP1ClipConnectTarget[] = [];
  private _connectTargetTypesEnabled = new Set<IPP1ClipConnectType>();
  private _intersection = new IntersectionStore();

  public get id(): number {
    return this._id;
  }

  public get root(): TransformNode {
    return this._mesh.root;
  }

  public get bbox(): AbstractMesh {
    return this._mesh.bbox;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get highlighted(): boolean {
    return this._highlighted;
  }

  public get connectStatus(): IPP1ClipConnectTargetId | undefined {
    return this._connectStatus;
  }

  public get connectTargets(): IPP1ClipConnectTarget[] {
    return this._connectTargets;
  }

  public get connectTargetTypesEnabled(): Set<IPP1ClipConnectType> {
    return this._connectTargetTypesEnabled;
  }

  public get connectStatusHelper(): TransformNode | null {
    const id = this._connectStatus;
    if (id === undefined) return null;
    for (const it of this._connectTargets)
      if (it.id.num === id.num && it.id.type === id.type) return it.helper;
    return null;
  }

  public get inHand(): boolean {
    return this._connectStatus?.type === IPP1ClipConnectType.PLAYER_HAND;
  }

  public get intersection(): IntersectionStore {
    return this._intersection;
  }

  public get intersectionsByType(): Record<IPP1ClipConnectType, Set<number>> {
    const res = {
      [IPP1ClipConnectType.KIP_TERMINAL]: new Set<number>(),
      [IPP1ClipConnectType.EMCVE_TERMINAL]: new Set<number>(),
      [IPP1ClipConnectType.PLAYER_HAND]: new Set<number>(),
      [IPP1ClipConnectType.TOOL]: new Set<number>(),
    };
    this._intersection.marks.forEach((e) => {
      const { id } = this._connectTargets[e];
      res[id.type].add(id.num);
    });
    return res;
  }

  constructor(id: number, mesh: IPP1SingleClipModel) {
    this._id = id;
    this._mesh = mesh;
    makeAutoObservable(this, {
      setVisibility: action,
      setHighlighted: action,
      setConnectStatus: action,
      setConnectTargets: action,
      enableIntersectsFor: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  public setHighlighted(isHighlighted: boolean): void {
    this._highlighted = isHighlighted;
  }

  public setConnectStatus(id?: IPP1ClipConnectTargetId): void {
    this._connectStatus = id;
  }

  public setConnectTargets(targets: IPP1ClipConnectTarget[]): void {
    this._connectTargets.splice(0);
    for (const it of targets) this._connectTargets.push(it);
  }

  public enableIntersectsFor(targetTypes: Set<IPP1ClipConnectType>): void {
    this._connectTargetTypesEnabled.clear();
    for (const it of targetTypes) this._connectTargetTypesEnabled.add(it);
  }
}
