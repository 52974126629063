import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Quaternion,
  Scene,
  TransformNode,
  Vector3,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { KeyConfig } from './types';

type Orientation = {
  defaultPosition: Vector3;
  defaultRotation: Quaternion;
};

export class KeyModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _key: AbstractMesh;
  private _bbox: AbstractMesh;
  private _defaultKeyOrientation: Orientation;

  get root(): TransformNode {
    return this._key;
  }

  get key(): AbstractMesh {
    return this._key;
  }

  get bbox(): AbstractMesh {
    return this._bbox;
  }

  get defaultKeyOrientation(): Orientation {
    return this._defaultKeyOrientation;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._key = findByName(model.meshes, 'Key');
    this._bbox = findByName(model.meshes, 'BBox_Key');
    this._defaultKeyOrientation = {
      defaultPosition: this._key.position.clone(),
      defaultRotation: this._key.rotationQuaternion!.clone(),
    };

    this.setVisibility(false);
  }

  static async load(cfg: KeyConfig, scene: Scene): Promise<KeyModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new KeyModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    this._key.isVisible = isVisible;
    this._bbox.isVisible = false;
  }
}
