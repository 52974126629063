import { Quaternion, Vector3 } from '@babylonjs/core';
import { action, makeAutoObservable } from 'mobx';

export class AxisRotationAccumulatorStore {
  private _value = 0;
  private _targetAxis = new Vector3(1, 0, 0);

  /**
   * Вектор вокруг которого рассчитывается поворот
   */
  public get targetAxis(): Vector3 {
    return this._targetAxis;
  }

  /**
   * Текущее значение поврота
   */
  public get value(): number {
    return this._value;
  }

  constructor() {
    makeAutoObservable(this, {
      setTargetAxis: action,
      addQuaternion: action,
      setValue: action,
    });
  }

  public setTargetAxis(axis: Vector3): void {
    this._targetAxis = axis;
  }

  public addQuaternion(dQ: Quaternion): void {
    const v = this._targetAxis;
    const projLen = dQ.x * v.x + dQ.y * v.y + dQ.z * v.z;
    this._value += dQ.w * projLen;
  }

  public setValue(value: number): void {
    this._value = value;
  }
}
