import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { AxisRotationAccumulatorStore } from '../../common/axis-rotation-accumulator';
import { IntersectionStore } from '../../common/intersection';
import { WrenchState } from './types';

export interface IWrenchStore {
  /**
   * Корень, за который можно привязывать объект
   */
  get root(): TransformNode;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Номер терминала, к которому прикреплен ключ
   */
  get attachedTarget(): number | undefined;

  /**
   * Номер терминала, к которому прикреплен ключ, сделавший поворот
   */
  get rotatedState(): [number, WrenchState] | undefined;
}

export class WrenchStore implements IWrenchStore {
  private _visible = false;
  private _root: TransformNode;

  private _intersect = new IntersectionStore();

  private _attachedTarget?: number;

  private _distanceTarget: TransformNode | null = null;
  private _distanceToMesh?: number;

  private _axisRotationAccumulator = new AxisRotationAccumulatorStore();

  public get root(): TransformNode {
    return this._root;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  public get attachedTarget(): number | undefined {
    return this._attachedTarget;
  }

  public get rotatedState(): [number, WrenchState] | undefined {
    const res = this._attachedTarget;
    if (res === undefined) return undefined;

    const a = this._axisRotationAccumulator.value;
    if (a < -Math.PI / 4) return [res, WrenchState.CLOSED];
    if (a > Math.PI / 4) return [res, WrenchState.OPENED];
    return [res, WrenchState.IN_PROCESS];
  }

  public get distanceTarget(): TransformNode | null {
    return this._distanceTarget;
  }

  public get distanceToMesh(): number | undefined {
    return this._distanceToMesh;
  }

  public get rotationAngle(): number | undefined {
    const a = this._axisRotationAccumulator.value;
    if (a > Math.PI / 4) return Math.PI / 4;
    if (a < -Math.PI / 4) return -Math.PI / 4;
    return a;
  }

  public get axisRotationAccumulator(): AxisRotationAccumulatorStore {
    return this._axisRotationAccumulator;
  }

  constructor(root: TransformNode) {
    this._root = root;
    makeAutoObservable(this, {
      setVisibility: action,
      moveToHand: action,
      moveToTerminal: action,
      setAttachedTarget: action,
      setDistanceTarget: action,
      setDistanceToMesh: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  public moveToHand(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
    this._root.rotation = new Vector3(0, 0, 0);
  }

  public moveToTerminal(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.rotation = new Vector3(0, 0, 0);
    this._root.position = new Vector3(0.1, 0, 0);
  }

  public setAttachedTarget(idx: number | undefined): void {
    if (this._attachedTarget === idx) return;
    this._attachedTarget = idx;
    this._axisRotationAccumulator.setValue(0);
    if (idx === undefined) this._intersect.resetMarks();
  }

  public setDistanceTarget(target: TransformNode | null): void {
    this._distanceTarget = target;
    this._distanceToMesh = undefined;
  }

  public setDistanceToMesh(distance: number | undefined): void {
    this._distanceToMesh = distance;
  }
}
