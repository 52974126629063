import { Scene } from '@babylonjs/core';
import { autorun } from 'mobx';

import { JumperController } from './jumper';
import { JumpersConfig } from '../types';
import { JumpersModel } from '../model';
import { JumpersStore } from '../store';

export class JumpersObject {
  private _scene: Scene;
  private _model: JumpersModel;
  private _VE_T1: JumperController;
  private _P1_T3: JumperController;

  constructor(scene: Scene, model: JumpersModel) {
    this._scene = scene;
    this._model = model;
    this._VE_T1 = new JumperController(scene, model.VE_T1);
    this._P1_T3 = new JumperController(scene, model.P1_T3);
  }

  get model(): JumpersModel {
    return this._model;
  }

  get VE_T1(): JumperController {
    return this._VE_T1;
  }

  get P1_T3(): JumperController {
    return this._P1_T3;
  }

  public static async setup(
    scene: Scene,
    cfg: JumpersConfig
  ): Promise<JumpersObject> {
    const model = await JumpersModel.load(scene, cfg);

    return new JumpersObject(scene, model);
  }

  /**
   * Подключить объект к его стору
   */

  public connectToStore(store: JumpersStore): void {
    autorun(() => {
      this._VE_T1.setJumperState(store.VE_T1);
    });
    autorun(() => {
      this._P1_T3.setJumperState(store.P1_T3);
    });
  }

  setVisibility(isVisible: boolean): void {
    this._model.setVisibility(isVisible);
  }
}
