import { StorageAdapter, persistence } from 'mobx-persist-store';
import { makeAutoObservable } from 'mobx';

import { readStoreLS, writeStoreLS } from 'libs';

export class CoreStore {
  count: number;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.count = 0;
  }
}

export const CoreStoreInstance = persistence({
  name: `persist:${process.env.REACT_APP_NAME}:v${process.env.REACT_APP_VERSION}:core`,
  properties: ['count'],
  adapter: new StorageAdapter({
    read: readStoreLS,
    write: writeStoreLS,
  }),
  reactionOptions: {
    delay: 300,
  },
})(new CoreStore());
export type CoreStoreType = typeof CoreStoreInstance;
