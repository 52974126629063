import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { BrushConfig } from './types';
import { BrushModel } from './model';
import { BrushStore } from './store';
import { setupLogic } from './logic';

export class BrushObject extends BaseModelObject<
  BrushModel,
  BrushStore,
  BrushConfig
> {
  private _intersect: IntersectionController;

  constructor(
    scene: Scene,
    model: BrushModel,
    store: BrushStore,
    cfg: BrushConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersect = new IntersectionController(
      scene,
      model.brush,
      store.intersect
    );
    this.registerController(this._intersect);
  }

  public static async setup(
    scene: Scene,
    cfg: BrushConfig
  ): Promise<BrushObject> {
    const model = await BrushModel.load(scene, cfg);
    const store = new BrushStore(model.root);
    return new BrushObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: BrushStore, cfg: BrushConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }
}
