import { action, makeAutoObservable } from 'mobx';

import { AbstractMesh } from '@babylonjs/core';

import { IXRControllersStore, XRControllersStore } from './controllers';

export interface IXRStore {
  get isActive(): boolean;
  get controllers(): IXRControllersStore;
  get collisionFloor(): AbstractMesh | undefined;

  setCollisionFloor(floor: AbstractMesh): void;
}

export class XRStore implements IXRStore {
  private _isActive = false;
  private _controllers = new XRControllersStore();
  private _collisionFloor?: AbstractMesh;

  public get isActive(): boolean {
    return this._isActive;
  }

  public get controllers(): XRControllersStore {
    return this._controllers;
  }

  public get collisionFloor(): AbstractMesh | undefined {
    return this._collisionFloor;
  }

  constructor() {
    makeAutoObservable(this, {
      setIsActive: action,
      setCollisionFloor: action,
    });
  }

  public setIsActive(isActive: boolean): void {
    this._isActive = isActive;
  }

  public setCollisionFloor(floor: AbstractMesh): void {
    this._collisionFloor = floor;
  }
}
