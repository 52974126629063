import { ModelConfig } from '../../common/types';

export type WrenchConfig = {
  model: ModelConfig;
};

export enum WrenchState {
  OPENED,
  IN_PROCESS,
  CLOSED,
}
