import { AdvancedDynamicTexture, Image } from '@babylonjs/gui';

export class IPP1ScreenGUI {
  private _image: Image;

  private _images: HTMLImageElement[];

  constructor(texture: AdvancedDynamicTexture, UIImages: HTMLImageElement[]) {
    this._images = UIImages;
    this._image = new Image();
    this._image.stretch = Image.STRETCH_UNIFORM;
    this.showImage(0);
    texture.addControl(this._image);
  }

  public showImage(id: number): void {
    this._image.domImage = this._images[id];
  }
}
