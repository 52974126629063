import { LessonStore } from 'features/lesson-page/store';

import { LightsConfig } from './types';
import { LightsStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: LightsStore,
  cfg: LightsConfig
): void {}
