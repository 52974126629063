import { action, makeAutoObservable } from 'mobx';

import { ITechBlockModel, TechBlockModel } from '../model';
import { IWheelStore } from '../../../common/wheel/store';
import { WheelStore } from '../../../common/wheel';

export interface ITechBlockStore {
  /**
   * Модель
   */
  get model(): ITechBlockModel;

  /**
   * Штурвал #1
   */
  get wheel1(): IWheelStore;

  /**
   * Штурвал #2
   */
  get wheel2(): IWheelStore;

  /**
   * Видна ли панель
   */
  get isVisible(): boolean;
}

export class TechBlockStore implements ITechBlockStore {
  private _model: TechBlockModel;
  private _wheel1: WheelStore;
  private _wheel2: WheelStore;

  private _isVisible = false;

  public get model(): TechBlockModel {
    return this._model;
  }

  public get wheel1(): WheelStore {
    return this._wheel1;
  }

  public get wheel2(): WheelStore {
    return this._wheel2;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  constructor(model: TechBlockModel) {
    this._model = model;
    this._wheel1 = new WheelStore(model.wheel[0]);
    this._wheel2 = new WheelStore(model.wheel[1]);

    makeAutoObservable(this, {
      setVisibility: action,
    });
  }

  /**
   * Установить видемость объекта
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }
}
