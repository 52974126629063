import {
  Color3,
  Scene,
  StandardMaterial,
  Texture,
  TransformNode,
} from '@babylonjs/core';

import { CommonPlaneModel } from '../../../common/models';
import {
  getRootUrlForObject,
  joinUrl,
  loadTexture,
} from '../../../common/utils';

import { AcceptJumpersWindowConfig } from '../types';

export class AcceptJumpersImgWindowModel {
  public static readonly MODEL_VERSION = 'v0001';

  public static WIDTH = 751;
  public static HEIGHT = 530;
  public static REAL_SCALE = 0.3 / 751;

  private _plane: CommonPlaneModel;

  get root(): TransformNode {
    return this._plane.root;
  }

  constructor(scene: Scene, plane: CommonPlaneModel, texture: Texture) {
    this._plane = plane;

    const modalMat = new StandardMaterial('material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = texture;
    modalMat.opacityTexture = texture;
    this._plane.plane.material = modalMat;
  }

  static async load(
    scene: Scene,
    cfg: AcceptJumpersWindowConfig
  ): Promise<AcceptJumpersImgWindowModel> {
    const imageRootUrl = getRootUrlForObject(
      cfg.image.root_url,
      this.MODEL_VERSION,
      cfg.image.folder
    );

    const [imgTexture, plane] = await Promise.all([
      loadTexture(joinUrl(imageRootUrl, cfg.image.filename), scene),
      CommonPlaneModel.load(scene, {
        width:
          AcceptJumpersImgWindowModel.WIDTH *
          AcceptJumpersImgWindowModel.REAL_SCALE,
        height:
          AcceptJumpersImgWindowModel.HEIGHT *
          AcceptJumpersImgWindowModel.REAL_SCALE,
      }),
    ]);

    return new AcceptJumpersImgWindowModel(scene, plane, imgTexture);
  }

  public setVisibility(isVisible: boolean): void {
    this._plane.setVisibility(isVisible);
  }
}
