import {
  AbstractMesh,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { JumpersConfig } from './types';

export type Jumper = {
  mesh: Mesh;
  connectAnim: AnimationGroup;
  disconnectAnim: AnimationGroup;
};

export class JumpersModel {
  public static readonly MODEL_VERSION = 'v0001';

  private _root: AbstractMesh;
  private _VE_T1: Jumper;
  private _P1_T3: Jumper;

  constructor(scene: Scene, jumpers: ISceneLoaderAsyncResult) {
    [this._root] = jumpers.meshes;
    this._VE_T1 = {
      mesh: findByName(jumpers.meshes, 'Jumper_VE-T1_primitive0') as Mesh,
      connectAnim: findByName(jumpers.animationGroups, 'VE-T1_Connected'),
      disconnectAnim: findByName(jumpers.animationGroups, 'VE-T1_Disconnected'),
    };
    this._P1_T3 = {
      mesh: findByName(jumpers.meshes, 'Jumper_P1-T3_primitive0') as Mesh,
      connectAnim: findByName(jumpers.animationGroups, 'P1-T3_Connected'),
      disconnectAnim: findByName(jumpers.animationGroups, 'P1-T3_Disconnected'),
    };
  }

  static async load(scene: Scene, cfg: JumpersConfig): Promise<JumpersModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new JumpersModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    const children = this._root.getChildMeshes();
    children.forEach((mesh) => {
      mesh.isVisible = isVisible;
    });
  }

  get VE_T1(): Jumper {
    return this._VE_T1;
  }

  get P1_T3(): Jumper {
    return this._P1_T3;
  }

  get root(): TransformNode {
    return this._root;
  }
}
