import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { AxisRotationAccumulatorStore } from '../../common/axis-rotation-accumulator';
import { IntersectionStore } from '../../common/intersection';
import { KeyState } from './types';

export interface IKeyStore {
  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Прикреплен ли ключ
   */
  get attached(): boolean;

  /**
   * Текущее состояние ключа
   */
  get rotationState(): KeyState;
}

export class KeyStore implements IKeyStore {
  private _visible = false;
  private _root: TransformNode;

  private _attached = false;
  private _intersect = new IntersectionStore();

  private _distanceTarget: TransformNode | null = null;
  private _distanceIsToFar?: boolean = false;

  private _axisRotationAccumulator = new AxisRotationAccumulatorStore();

  /**
   * Корень, за который можно привязывать объект
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get attached(): boolean {
    return this._attached;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  /**
   * Нода, до которого будет рассчитываться расстояние
   */
  public get distanceTarget(): TransformNode | null {
    return this._distanceTarget;
  }

  /**
   * Отсоединить, потому что расстояние слишком большое
   */
  public get detachBecauseDistance(): boolean {
    if (this._distanceTarget === null) return false;
    return this._distanceIsToFar || false;
  }

  public get rotationState(): KeyState {
    const a = this._axisRotationAccumulator.value;
    if (a < -Math.PI / 4) return KeyState.CLOSED;
    if (a > Math.PI / 4) return KeyState.OPENED;
    return KeyState.IN_PROCESS;
  }

  /**
   * Угол поворота ноды rotationTarget
   */
  public get rotationAngle(): number | undefined {
    const a = this._axisRotationAccumulator.value;
    if (a > Math.PI / 4) return Math.PI / 4;
    if (a < -Math.PI / 4) return -Math.PI / 4;
    return a;
  }

  public get axisRotationAccumulator(): AxisRotationAccumulatorStore {
    return this._axisRotationAccumulator;
  }

  constructor(root: TransformNode) {
    this._root = root;
    makeAutoObservable(this, {
      setVisibility: action,
      moveToHand: action,
      moveToKeyhole: action,
      setAttached: action,
      setDistanceTarget: action,
      setDistanceIsToFar: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  /**
   * Переместить ключ в руку
   */
  public moveToHand(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
    this._root.rotation = new Vector3(0, 0, 0);
  }

  /**
   * Переместить ключ в замочню скважину
   */
  public moveToKeyhole(parent: TransformNode | null): void {
    this._root.parent = parent;
    this._root.position = new Vector3(0, 0, 0);
  }

  /**
   * Установить параметр attached
   */
  public setAttached(attached: boolean): void {
    this._attached = attached;
    if (attached) {
      this._axisRotationAccumulator.setValue(0);
    } else {
      this.intersect.setEnabled(false);
      this.intersect.resetMarks();
      this._distanceIsToFar = undefined;
    }
  }

  /**
   * Установить ноду, до которой будет рассчитываться расстояние
   */
  public setDistanceTarget(target: TransformNode | null): void {
    this._distanceTarget = target;
  }

  public setDistanceIsToFar(isToFar: boolean): void {
    this._distanceIsToFar = isToFar;
  }
}
