import {
  Control,
  Measure,
  StackPanel,
  TextBlock,
  TextWrapping,
} from '@babylonjs/gui';

export class InfoPoint extends StackPanel {
  private _lineBottomMargin: number;

  public titleText: TextBlock;
  public valueText: TextBlock;

  constructor(scale: number, title: string, lines: number, name?: string) {
    super(name);

    this.isVertical = false;
    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    this.titleText = new TextBlock('Title');
    this.titleText.resizeToFit = true;
    this.titleText.text = title;
    this.titleText.fontSize = 10 * scale;
    this.titleText.color = '#222';
    this.titleText.fontStyle = 'bold';
    this.titleText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    this.valueText = new TextBlock('Value');
    this.valueText.resizeToFit = true;
    this.valueText.textWrapping = TextWrapping.WordWrap;
    this.valueText.fontSize = 10 * scale;
    this.valueText.color = '#222';
    this.valueText.widthInPixels = 200;
    this.valueText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.valueText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    this._lineBottomMargin = 2 * scale;

    this.valueText.text = '123';

    this.addControl(this.titleText);
    this.addControl(this.valueText);
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    this.valueText.widthInPixels =
      parentMeasure.width - this.titleText.widthInPixels;
    this.heightInPixels =
      Math.max(this.titleText.heightInPixels, this.valueText.heightInPixels) +
      this._lineBottomMargin;
  }
}
