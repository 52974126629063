import { ILandscapeStore } from '../landscape';
import { ILightsStore } from '../lights';
import { ISkyboxStore } from '../skybox';

export type WorldStoreList = {
  landscape?: ILandscapeStore;
  lights?: ILightsStore;
  skybox?: ISkyboxStore;
};

export class WorldStore {
  private _stores: WorldStoreList;

  /**
   * Ланшафт
   */
  public get landscape(): ILandscapeStore | undefined {
    return this._stores.landscape;
  }

  /**
   * Свет
   */
  public get lights(): ILightsStore | undefined {
    return this._stores.lights;
  }

  /**
   * Скайбокс
   */
  public get skybox(): ISkyboxStore | undefined {
    return this._stores.skybox;
  }

  constructor(stores: WorldStoreList) {
    this._stores = stores;
  }
}
