import { Grid, Measure } from '@babylonjs/gui';

/**
 * Babylon виджет таблицы
 *
 * Позволяет рисовать таблицу и помещать в ее ячейки любые другие виджеты
 */
export class GridTable extends Grid {
  private _strokeStyle = 'black';
  private _lineWidth = 2;

  private _showRightLine = true;
  private _showLeftLine = true;
  private _showTopLine = true;
  private _showBottomLine = true;

  public get lineWidth(): number {
    return this._lineWidth;
  }

  public set lineWidth(value: number) {
    if (this._lineWidth === value) return;
    this._lineWidth = value;
    this._markAsDirty();
  }

  public get strokeStyle(): string {
    return this._strokeStyle;
  }

  public set strokeStyle(value: string) {
    if (this._strokeStyle === value) return;
    this._strokeStyle = value;
    this._markAsDirty();
  }

  public get showRightLine(): boolean {
    return this._showRightLine;
  }

  public set showRightLine(value: boolean) {
    if (this._showRightLine === value) return;
    this._showRightLine = value;
    this._markAsDirty();
  }

  public get showLeftLine(): boolean {
    return this._showLeftLine;
  }

  public set showLeftLine(value: boolean) {
    if (this._showLeftLine === value) return;
    this._showLeftLine = value;
    this._markAsDirty();
  }

  public get showTopLine(): boolean {
    return this._showTopLine;
  }

  public set showTopLine(value: boolean) {
    if (this._showTopLine === value) return;
    this._showTopLine = value;
    this._markAsDirty();
  }

  public get showBottomLine(): boolean {
    return this._showBottomLine;
  }

  public set showBottomLine(value: boolean) {
    if (this._showBottomLine === value) return;
    this._showBottomLine = value;
    this._markAsDirty();
  }

  protected _getTypeName(): string {
    return this.constructor.name;
  }

  // workaround чтобы можно было рисовать поверх всех детей
  public _draw(
    context: CanvasRenderingContext2D,
    invalidatedRectangle?: Measure
  ): void {
    super._draw(context, invalidatedRectangle);
    this._postDraw(context);
  }

  // workaround чтобы не обрезались края таблицы
  protected _getGridDefinitions(
    definitionCallback: (
      lefts: number[],
      tops: number[],
      widths: number[],
      heights: number[]
    ) => void
  ): void {
    const d = Math.floor(this._lineWidth / 2) + 1;
    const oldWidth = this._currentMeasure.width;
    const oldHeight = this._currentMeasure.height;
    this._currentMeasure.width -= d * 2;
    this._currentMeasure.height -= d * 2;

    super._getGridDefinitions(
      (
        lefts: number[],
        tops: number[],
        widths: number[],
        heights: number[]
      ) => {
        this._currentMeasure.width = oldWidth;
        this._currentMeasure.height = oldHeight;

        definitionCallback(
          lefts.map((e) => e + d),
          tops.map((e) => e + d),
          widths,
          heights
        );
      }
    );
  }

  private drawTable(
    context: CanvasRenderingContext2D,
    x: number[],
    y: number[]
  ): void {
    context.beginPath();

    const xStart = x[0];
    const xEnd = x[x.length - 1];
    const yStart = y[0];
    const yEnd = y[y.length - 1];

    const xNew = x.slice(
      this._showLeftLine ? undefined : 1,
      this._showRightLine ? undefined : -1
    );
    const yNew = y.slice(
      this._showTopLine ? undefined : 1,
      this._showBottomLine ? undefined : -1
    );

    for (const xi of xNew) {
      context.moveTo(xi, yStart);
      context.lineTo(xi, yEnd);
    }
    for (const yi of yNew) {
      context.moveTo(xStart, yi);
      context.lineTo(xEnd, yi);
    }

    context.strokeStyle = this._strokeStyle;
    context.lineWidth = this._lineWidth;
    context.stroke();
  }

  protected _postDraw(context: CanvasRenderingContext2D): void {
    context.save();
    const sz = this._currentMeasure;
    context.translate(sz.left, sz.top);

    this._getGridDefinitions(
      (
        lefts: number[],
        tops: number[],
        widths: number[],
        heights: number[]
      ) => {
        if (lefts.length < 1 || tops.length < 1) return;

        lefts.push(lefts[lefts.length - 1] + widths[widths.length - 1]);
        tops.push(tops[tops.length - 1] + heights[heights.length - 1]);

        this.drawTable(context, lefts, tops);
      }
    );

    context.restore();
  }
}
