import { action, makeAutoObservable } from 'mobx';

import {
  IPP1ClipConnectTarget,
  IPP1ClipConnectTargetId,
  IPP1ClipsModel,
} from '../types';
import { IPP1SingleClipStore } from './single-clip';

export interface IIPP1ClipsStore {
  get black(): IPP1SingleClipStore;
  get red(): IPP1SingleClipStore;
  get yellow(): IPP1SingleClipStore;
  get list(): IPP1SingleClipStore[];

  /**
   * Находится ли хотя бы один крокодил в руках
   */
  get inHand(): boolean;
}

export class IPP1ClipsStore implements IIPP1ClipsStore {
  private _black: IPP1SingleClipStore;
  private _red: IPP1SingleClipStore;
  private _yellow: IPP1SingleClipStore;

  public get black(): IPP1SingleClipStore {
    return this._black;
  }

  public get red(): IPP1SingleClipStore {
    return this._red;
  }

  public get yellow(): IPP1SingleClipStore {
    return this._yellow;
  }

  public get list(): IPP1SingleClipStore[] {
    return [this._black, this._red, this._yellow];
  }

  public get inHand(): boolean {
    return this.list.some((c) => c.inHand);
  }

  constructor(mesh: IPP1ClipsModel) {
    this._black = new IPP1SingleClipStore(0, mesh.black);
    this._red = new IPP1SingleClipStore(0, mesh.red);
    this._yellow = new IPP1SingleClipStore(0, mesh.yellow);

    makeAutoObservable(this, {
      setConnectTargets: action,
    });
  }

  public setConnectTargets(targets: IPP1ClipConnectTarget[]): void {
    this.list.forEach((c) => c.setConnectTargets(targets));
  }

  public isTargetFree(target: IPP1ClipConnectTargetId): boolean {
    return !this.list.some(
      (c) =>
        c.connectStatus?.num === target.num &&
        c.connectStatus?.type === target.type
    );
  }
}
