import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Quaternion,
  Scene,
  TransformNode,
  Vector3,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { WrenchConfig } from './types';

type Orientation = {
  position: Vector3;
  rotation: Quaternion | null;
};

export class WrenchModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _wrench: AbstractMesh;
  private _bBox: AbstractMesh;
  private _defaultWrenchOrientation: Orientation;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._bBox = findByName(model.meshes, 'BBox_Wrench');
    this._wrench = findByName(model.meshes, 'Wrench');

    this._defaultWrenchOrientation = {
      position: this._wrench.position.clone(),
      rotation: this._wrench.rotationQuaternion
        ? this._wrench.rotationQuaternion.clone()
        : null,
    };
  }

  static async load(scene: Scene, cfg: WrenchConfig): Promise<WrenchModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new WrenchModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    this._wrench.isVisible = isVisible;
    this._bBox.isVisible = false;
  }

  get root(): TransformNode {
    return this._wrench;
  }

  get wrench(): AbstractMesh {
    return this._wrench;
  }

  get bBox(): AbstractMesh {
    return this._bBox;
  }

  get defaultWrenchOrientation(): Orientation {
    return this._defaultWrenchOrientation;
  }
}
