import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useRootData } from 'hooks';

import { LessonType } from 'api';

import { UnfulfilledRequiredStatesPanel } from '../components';

const UserHints: FC = observer(() => {
  const { lessonType, unfulfilledRequiredStates } = useRootData((store) => ({
    lessonType: store.lesson.lessonType,
    unfulfilledRequiredStates: store.lesson.unfulfilledRequiredStates,
  }));

  return (
    <>
      {!!unfulfilledRequiredStates.length &&
        lessonType === LessonType.Learning && (
          <UnfulfilledRequiredStatesPanel />
        )}
    </>
  );
});

export default UserHints;
