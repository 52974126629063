import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { BrushConfig } from './types';
import { BrushStore } from './store';
import { ToolsType } from '../types';

export function setupLogic(
  store: LessonStore,
  objStore: BrushStore,
  cfg: BrushConfig
): void {
  const { hands, inventory } = store.player;
  if (!hands || !inventory)
    throw Error('Cannot configure without player.hands or player.inventory');

  // Установка целевых объектов
  autorun(() => {
    if (!store.scene.KIP) return;
    objStore.intersect.setTargets(
      store.scene.KIP.model.terminals.all.map((m, id) => {
        return {
          id,
          mesh: m.bbox,
        };
      })
    );
  });

  // Установка видимости и родителя
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.BRUSH;
    objStore.setVisibility(isSelected && !hands.animating);
    objStore.moveToHand(isSelected ? hands.mainHand.helperTake : null);
  });
}
