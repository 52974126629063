import {
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  IAction,
  Observable,
  Scene,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { Jumper } from '../model';
import { JumpersStore } from '../store';

export class JumperController {
  private _scene: Scene;
  private _model: Jumper;
  private _isConnected = true;
  private _isAnimating = false;

  private _actionManager: ActionManager;
  private _intersectActions: IAction[] = [];
  public onIntersectionEnter = new Observable<void>();

  constructor(scene: Scene, model: Jumper) {
    this._scene = scene;
    this._model = model;

    this._actionManager = new ActionManager(scene);
    this._model.mesh.actionManager = this._actionManager;

    this._model.connectAnim.onAnimationGroupEndObservable.add(() => {
      this._onAnimComplete();
    });
    this._model.disconnectAnim.onAnimationGroupEndObservable.add(() => {
      this._onAnimComplete();
    });

    // При нажати на объект
    this._actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
        this.onIntersectionEnter.notifyObservers();
      })
    );
  }

  public clearJumpersIntersection(): void {
    this._intersectActions.forEach((a) => {
      this._actionManager.unregisterAction(a);
    });
    this._intersectActions = [];
  }

  public setupJumpersIntersection(
    controller: number,
    mesh: AbstractMesh
  ): void {
    const actionParams = {
      mesh,
      usePreciseIntersection: true,
    };
    const enterAction = this._actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnIntersectionEnterTrigger,
          parameter: actionParams,
        },
        () => {
          this.onIntersectionEnter.notifyObservers();
        }
      )
    );
    enterAction && this._intersectActions.push(enterAction);
  }

  private _onAnimComplete() {
    this._isAnimating = false;
  }

  private _updateJumperState(newConnected: boolean) {
    if (newConnected === this._isConnected) return;
    if (this._isAnimating) return;
    this._isAnimating = true;
    this._isConnected = newConnected;

    const { connectAnim } = this._model;
    const { disconnectAnim } = this._model;
    // Воспроизвести анимацию
    if (this._isConnected) connectAnim.start();
    else disconnectAnim.start();
  }

  /**
   * Изменить состояние перемычки
   * @param targetIsOpen Состояние, в которое будет установлена крышка
   */
  public setJumperState(targetIsOpen: boolean): void {
    this._updateJumperState(targetIsOpen);
  }

  get model(): Jumper {
    return this._model;
  }

  /**
   * Подключить объект к его стору
   */

  public connectToStore(store: JumpersStore): void {
    autorun(() => {
      this.setJumperState(store.P1_T3);
    });
  }
}
