import { ILoadingScreen } from '@babylonjs/core';

import { SceneLoadingProgressType } from '../../types';

export class SceneLoadingScreen implements ILoadingScreen {
  // обязателен для интерфейса ILoadingScreen
  public loadingUIBackgroundColor: string;

  constructor(
    public loadingUIText: string,
    private callbackOnProgressChange: (
      sceneProgress: SceneLoadingProgressType
    ) => void
  ) {
    this.loadingUIBackgroundColor = '';
    this.callbackOnProgressChange = callbackOnProgressChange;
  }

  public displayLoadingUI(): void {
    this.callbackOnProgressChange(SceneLoadingProgressType.InProgress);
  }

  public hideLoadingUI(): void {
    this.callbackOnProgressChange(SceneLoadingProgressType.Loaded);
  }
}
