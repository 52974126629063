import { LessonStore } from 'features/lesson-page/store';

import { EMCVEConfig } from './types';
import { EMCVEStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: EMCVEStore,
  cfg: EMCVEConfig
): void {}
