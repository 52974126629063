import { IXRStore } from '../xr';

export type CoreStoreList = {
  xr?: IXRStore;
};

export class CoreStore {
  private _stores: CoreStoreList;

  /**
   * XR
   */
  public get xr(): IXRStore | undefined {
    return this._stores.xr;
  }

  constructor(stores: CoreStoreList) {
    this._stores = stores;
  }
}
