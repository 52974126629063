import { Button, Measure } from '@babylonjs/gui';
import { Color3, Color4 } from '@babylonjs/core';

/**
 * Основа для квадратных кнопок со скруглением, содержащих графику.
 *
 * Радиус скругления задается отношением к высоте
 * Анимация наведения мышки заменена на изменение цвета фона
 */
export class RoundedSquareButton extends Button {
  private _ON_HOVER_COLOR = new Color4(1, 1, 1, 0.1);

  private _backgroundStore: string | null = null;
  private _roundRatio: number;

  public get roundRatio(): number {
    return this._roundRatio;
  }

  public set roundRatio(value: number) {
    this._roundRatio = value;
    this._markAsDirty();
  }

  constructor(roundRatio = 0.2, name?: string) {
    super(name);
    this._roundRatio = roundRatio;
    this.fixedRatio = 1; // square

    // change default pointer enter animation
    this.pointerEnterAnimation = () => {
      this._backgroundStore = this.background;

      const color = this._ON_HOVER_COLOR;
      const colorA = new Color3(color.r, color.g, color.b).scale(color.a);
      const colorB = Color3.FromHexString(this.background).scale(1 - color.a);

      this.background = colorA.add(colorB).toHexString();
    };

    // change default pointer out animation
    this.pointerOutAnimation = () => {
      if (!this._backgroundStore) return;
      this.background = this._backgroundStore;
    };
  }

  protected _additionalProcessing(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._additionalProcessing(parentMeasure, context);

    // Пересчитать размер скругления
    const r = Math.min(this._currentMeasure.height, this._currentMeasure.width);
    this.cornerRadius = r * Math.min(Math.max(0, this._roundRatio), 0.5);
  }
}
