/* tslint:disable */
/* eslint-disable */
/**
 * WebVRTraining.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DoneCondition
 */
export interface DoneCondition {
    /**
     * 
     * @type {string}
     * @memberof DoneCondition
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoneCondition
     */
    stateName?: string | null;
    /**
     * 
     * @type {any}
     * @memberof DoneCondition
     */
    stateValue?: any | null;
}
/**
 * 
 * @export
 * @interface Learner
 */
export interface Learner {
    /**
     * 
     * @type {string}
     * @memberof Learner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Learner
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Learner
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Learner
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Learner
     */
    position?: string | null;
}
/**
 * 
 * @export
 * @interface LearnerCreateModel
 */
export interface LearnerCreateModel {
    /**
     * 
     * @type {string}
     * @memberof LearnerCreateModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerCreateModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerCreateModel
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearnerCreateModel
     */
    position?: string | null;
}
/**
 * 
 * @export
 * @interface Lesson
 */
export interface Lesson {
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    id: string;
    /**
     * 
     * @type {Script}
     * @memberof Lesson
     */
    script?: Script;
    /**
     * 
     * @type {Learner}
     * @memberof Lesson
     */
    learner?: Learner;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    createData?: string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    expiresAt?: string | null;
}
/**
 * 
 * @export
 * @interface LessonCreateModel
 */
export interface LessonCreateModel {
    /**
     * 
     * @type {string}
     * @memberof LessonCreateModel
     */
    learnerId: string;
    /**
     * 
     * @type {string}
     * @memberof LessonCreateModel
     */
    scriptId: string;
    /**
     * Milliseconds
     * @type {number}
     * @memberof LessonCreateModel
     */
    lessonDuration?: number | null;
}
/**
 * 
 * @export
 * @interface LessonFinishModel
 */
export interface LessonFinishModel {
    /**
     * 
     * @type {string}
     * @memberof LessonFinishModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonFinishModel
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonFinishModel
     */
    end?: string;
    /**
     * 
     * @type {LessonType}
     * @memberof LessonFinishModel
     */
    lessonType?: LessonType;
    /**
     * 
     * @type {number}
     * @memberof LessonFinishModel
     */
    stepsCompleted?: number;
    /**
     * 
     * @type {Array<StepFinishModel>}
     * @memberof LessonFinishModel
     */
    steps?: Array<StepFinishModel> | null;
}
/**
 * 
 * @export
 * @interface LessonResultViewModel
 */
export interface LessonResultViewModel {
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    middleName?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof LessonResultViewModel
     */
    lessonDuration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonResultViewModel
     */
    scriptName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessonResultViewModel
     */
    stepsCompleted?: number;
    /**
     * 
     * @type {number}
     * @memberof LessonResultViewModel
     */
    stepsTotal?: number;
    /**
     * 
     * @type {Array<StepResultViewModel>}
     * @memberof LessonResultViewModel
     */
    steps?: Array<StepResultViewModel> | null;
}
/**
 * 
 * @export
 * @interface LessonStepReportModel
 */
export interface LessonStepReportModel {
    /**
     * 
     * @type {string}
     * @memberof LessonStepReportModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonStepReportModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonStepReportModel
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessonStepReportModel
     */
    scriptName?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof LessonStepReportModel
     */
    lessonDuration?: TimeSpan;
    /**
     * 
     * @type {LessonType}
     * @memberof LessonStepReportModel
     */
    lessonType?: LessonType;
    /**
     * 
     * @type {Array<StepReportModel>}
     * @memberof LessonStepReportModel
     */
    steps?: Array<StepReportModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LessonType {
    Learning = 'Learning',
    Training = 'Training',
    Exam = 'Exam'
}

/**
 * 
 * @export
 * @interface RequiredState
 */
export interface RequiredState {
    /**
     * 
     * @type {string}
     * @memberof RequiredState
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequiredState
     */
    stateName?: string | null;
    /**
     * 
     * @type {any}
     * @memberof RequiredState
     */
    stateValue?: any | null;
}
/**
 * 
 * @export
 * @interface Script
 */
export interface Script {
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    image?: string | null;
    /**
     * 
     * @type {Array<ScriptStep>}
     * @memberof Script
     */
    steps?: Array<ScriptStep> | null;
    /**
     * 
     * @type {any}
     * @memberof Script
     */
    config?: any | null;
}
/**
 * 
 * @export
 * @interface ScriptCreateModel
 */
export interface ScriptCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ScriptCreateModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptCreateModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptCreateModel
     */
    image?: string | null;
    /**
     * 
     * @type {Array<ScriptStep>}
     * @memberof ScriptCreateModel
     */
    steps?: Array<ScriptStep> | null;
    /**
     * 
     * @type {any}
     * @memberof ScriptCreateModel
     */
    config?: any | null;
}
/**
 * 
 * @export
 * @interface ScriptStep
 */
export interface ScriptStep {
    /**
     * 
     * @type {string}
     * @memberof ScriptStep
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptStep
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptStep
     */
    audioUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptStep
     */
    shortHint?: string | null;
    /**
     * 
     * @type {Array<RequiredState>}
     * @memberof ScriptStep
     */
    requiredStates?: Array<RequiredState> | null;
    /**
     * 
     * @type {DoneCondition}
     * @memberof ScriptStep
     */
    doneCondition?: DoneCondition;
    /**
     * 
     * @type {number}
     * @memberof ScriptStep
     */
    stepNumber?: number;
}
/**
 * 
 * @export
 * @interface ScriptViewModel
 */
export interface ScriptViewModel {
    /**
     * 
     * @type {string}
     * @memberof ScriptViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScriptViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScriptViewModel
     */
    image?: string | null;
}
/**
 * 
 * @export
 * @interface StepFinishModel
 */
export interface StepFinishModel {
    /**
     * 
     * @type {string}
     * @memberof StepFinishModel
     */
    stepId: string;
    /**
     * 
     * @type {string}
     * @memberof StepFinishModel
     */
    checkTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StepFinishModel
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @interface StepReportModel
 */
export interface StepReportModel {
    /**
     * 
     * @type {number}
     * @memberof StepReportModel
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof StepReportModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StepReportModel
     */
    errorCount?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof StepReportModel
     */
    stepDuration?: TimeSpan;
}
/**
 * 
 * @export
 * @interface StepResultViewModel
 */
export interface StepResultViewModel {
    /**
     * 
     * @type {string}
     * @memberof StepResultViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StepResultViewModel
     */
    stepId: string;
    /**
     * 
     * @type {string}
     * @memberof StepResultViewModel
     */
    checkTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StepResultViewModel
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    ticks?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    milliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMilliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalSeconds?: number;
}

/**
 * LearnerApi - axios parameter creator
 * @export
 */
export const LearnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLearnerIdDelete', 'id', id)
            const localVarPath = `/api/learner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLearnerIdGet', 'id', id)
            const localVarPath = `/api/learner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Learner} [learner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdPost: async (id: string, learner?: Learner, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLearnerIdPost', 'id', id)
            const localVarPath = `/api/learner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LearnerCreateModel} [learnerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerPost: async (learnerCreateModel?: LearnerCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learnerCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LearnerApi - functional programming interface
 * @export
 */
export const LearnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LearnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnerGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Learner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnerIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnerIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Learner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Learner} [learner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnerIdPost(id: string, learner?: Learner, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnerIdPost(id, learner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LearnerCreateModel} [learnerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLearnerPost(learnerCreateModel?: LearnerCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLearnerPost(learnerCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LearnerApi - factory interface
 * @export
 */
export const LearnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LearnerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerGet(options?: any): AxiosPromise<Array<Learner>> {
            return localVarFp.apiLearnerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLearnerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdGet(id: string, options?: any): AxiosPromise<Learner> {
            return localVarFp.apiLearnerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Learner} [learner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerIdPost(id: string, learner?: Learner, options?: any): AxiosPromise<string> {
            return localVarFp.apiLearnerIdPost(id, learner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LearnerCreateModel} [learnerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLearnerPost(learnerCreateModel?: LearnerCreateModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiLearnerPost(learnerCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LearnerApi - object-oriented interface
 * @export
 * @class LearnerApi
 * @extends {BaseAPI}
 */
export class LearnerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnerApi
     */
    public apiLearnerGet(options?: any) {
        return LearnerApiFp(this.configuration).apiLearnerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnerApi
     */
    public apiLearnerIdDelete(id: string, options?: any) {
        return LearnerApiFp(this.configuration).apiLearnerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnerApi
     */
    public apiLearnerIdGet(id: string, options?: any) {
        return LearnerApiFp(this.configuration).apiLearnerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Learner} [learner] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnerApi
     */
    public apiLearnerIdPost(id: string, learner?: Learner, options?: any) {
        return LearnerApiFp(this.configuration).apiLearnerIdPost(id, learner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LearnerCreateModel} [learnerCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnerApi
     */
    public apiLearnerPost(learnerCreateModel?: LearnerCreateModel, options?: any) {
        return LearnerApiFp(this.configuration).apiLearnerPost(learnerCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonApi - axios parameter creator
 * @export
 */
export const LessonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {LessonFinishModel} [lessonFinishModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdFinishPost: async (id: string, lessonFinishModel?: LessonFinishModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLessonIdFinishPost', 'id', id)
            const localVarPath = `/api/lesson/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lessonFinishModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLessonIdGet', 'id', id)
            const localVarPath = `/api/lesson/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdScriptGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLessonIdScriptGet', 'id', id)
            const localVarPath = `/api/lesson/{id}/script`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LessonCreateModel} [lessonCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonPost: async (lessonCreateModel?: LessonCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lessonCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonApi - functional programming interface
 * @export
 */
export const LessonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {LessonFinishModel} [lessonFinishModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLessonIdFinishPost(id: string, lessonFinishModel?: LessonFinishModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLessonIdFinishPost(id, lessonFinishModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLessonIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLessonIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLessonIdScriptGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Script>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLessonIdScriptGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LessonCreateModel} [lessonCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLessonPost(lessonCreateModel?: LessonCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLessonPost(lessonCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonApi - factory interface
 * @export
 */
export const LessonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {LessonFinishModel} [lessonFinishModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdFinishPost(id: string, lessonFinishModel?: LessonFinishModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiLessonIdFinishPost(id, lessonFinishModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdGet(id: string, options?: any): AxiosPromise<Lesson> {
            return localVarFp.apiLessonIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonIdScriptGet(id: string, options?: any): AxiosPromise<Script> {
            return localVarFp.apiLessonIdScriptGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LessonCreateModel} [lessonCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLessonPost(lessonCreateModel?: LessonCreateModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiLessonPost(lessonCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonApi - object-oriented interface
 * @export
 * @class LessonApi
 * @extends {BaseAPI}
 */
export class LessonApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {LessonFinishModel} [lessonFinishModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public apiLessonIdFinishPost(id: string, lessonFinishModel?: LessonFinishModel, options?: any) {
        return LessonApiFp(this.configuration).apiLessonIdFinishPost(id, lessonFinishModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public apiLessonIdGet(id: string, options?: any) {
        return LessonApiFp(this.configuration).apiLessonIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public apiLessonIdScriptGet(id: string, options?: any) {
        return LessonApiFp(this.configuration).apiLessonIdScriptGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LessonCreateModel} [lessonCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public apiLessonPost(lessonCreateModel?: LessonCreateModel, options?: any) {
        return LessonApiFp(this.configuration).apiLessonPost(lessonCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResultsApi - axios parameter creator
 * @export
 */
export const ResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsIdLessonstepreportGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiResultsIdLessonstepreportGet', 'id', id)
            const localVarPath = `/api/results/{id}/lessonstepreport`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LessonType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsTypeGet: async (type: LessonType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiResultsTypeGet', 'type', type)
            const localVarPath = `/api/results/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResultsApi - functional programming interface
 * @export
 */
export const ResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiResultsIdLessonstepreportGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonStepReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiResultsIdLessonstepreportGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LessonType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiResultsTypeGet(type: LessonType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LessonResultViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiResultsTypeGet(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResultsApi - factory interface
 * @export
 */
export const ResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResultsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsIdLessonstepreportGet(id: string, options?: any): AxiosPromise<LessonStepReportModel> {
            return localVarFp.apiResultsIdLessonstepreportGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LessonType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsTypeGet(type: LessonType, options?: any): AxiosPromise<Array<LessonResultViewModel>> {
            return localVarFp.apiResultsTypeGet(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResultsApi - object-oriented interface
 * @export
 * @class ResultsApi
 * @extends {BaseAPI}
 */
export class ResultsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public apiResultsIdLessonstepreportGet(id: string, options?: any) {
        return ResultsApiFp(this.configuration).apiResultsIdLessonstepreportGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LessonType} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public apiResultsTypeGet(type: LessonType, options?: any) {
        return ResultsApiFp(this.configuration).apiResultsTypeGet(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScriptApi - axios parameter creator
 * @export
 */
export const ScriptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/script`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiScriptIdDelete', 'id', id)
            const localVarPath = `/api/script/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiScriptIdGet', 'id', id)
            const localVarPath = `/api/script/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Script} [script] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdPost: async (id: string, script?: Script, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiScriptIdPost', 'id', id)
            const localVarPath = `/api/script/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(script, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ScriptCreateModel} [scriptCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptPost: async (scriptCreateModel?: ScriptCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/script`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scriptCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScriptApi - functional programming interface
 * @export
 */
export const ScriptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScriptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiScriptGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScriptViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiScriptGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiScriptIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiScriptIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiScriptIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Script>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiScriptIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Script} [script] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiScriptIdPost(id: string, script?: Script, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiScriptIdPost(id, script, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ScriptCreateModel} [scriptCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiScriptPost(scriptCreateModel?: ScriptCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiScriptPost(scriptCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScriptApi - factory interface
 * @export
 */
export const ScriptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScriptApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptGet(options?: any): AxiosPromise<Array<ScriptViewModel>> {
            return localVarFp.apiScriptGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiScriptIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdGet(id: string, options?: any): AxiosPromise<Script> {
            return localVarFp.apiScriptIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Script} [script] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptIdPost(id: string, script?: Script, options?: any): AxiosPromise<string> {
            return localVarFp.apiScriptIdPost(id, script, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ScriptCreateModel} [scriptCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiScriptPost(scriptCreateModel?: ScriptCreateModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiScriptPost(scriptCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScriptApi - object-oriented interface
 * @export
 * @class ScriptApi
 * @extends {BaseAPI}
 */
export class ScriptApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public apiScriptGet(options?: any) {
        return ScriptApiFp(this.configuration).apiScriptGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public apiScriptIdDelete(id: string, options?: any) {
        return ScriptApiFp(this.configuration).apiScriptIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public apiScriptIdGet(id: string, options?: any) {
        return ScriptApiFp(this.configuration).apiScriptIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Script} [script] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public apiScriptIdPost(id: string, script?: Script, options?: any) {
        return ScriptApiFp(this.configuration).apiScriptIdPost(id, script, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ScriptCreateModel} [scriptCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public apiScriptPost(scriptCreateModel?: ScriptCreateModel, options?: any) {
        return ScriptApiFp(this.configuration).apiScriptPost(scriptCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


