export const requirementToPhrase = (reqName: string): string => {
  switch (reqName) {
    case 'areAllLabelsTouched':
      return 'Проверьте, что все метки были задеты';
    case 'areAllKipTerminalsLoose':
      return 'Проверьте, что контактные соединения клеммной площадки ослаблены';
    case 'areKipTerminalsGreased':
      return 'Проверьте, что контактные соединения клеммной площадки обработаны вазелином';
    case 'isGlovesOn':
      return 'Проверьте, что перчатки надеты';
    case 'isFrontDoorOpen':
      return 'Проверьте, что дверь КИП открыта';
    case 'isMultimeterTaken':
      return 'Проверьте, что мультиметр выбран';
    case 'connectedTerminals':
      // не работает, потому что массив - не примитивный тип
      return 'Проверьте, что нужные клеммы выбраны';
    case 'isTerminalsConnected':
      return 'Проверьте, что клеммы выбраны';
    case 'isTabletTaken':
      return 'Проверьте, что журнал выбран';
    case 'tabletEntriesCount':
      // не работает, потому что массив - не примитивный тип
      return 'Проверьте, что количество записей в журнале соответствует количеству измерений';
    default:
      return '';
  }
};
