import { action, makeAutoObservable } from 'mobx';

import { IPP1Buttons } from '../types';

export interface IIPP1ButtonsStore {
  /**
   * Какая кнопка нажата
   */
  get buttonDown(): IPP1Buttons | undefined;
}

export class IPP1ButtonsStore implements IIPP1ButtonsStore {
  public _buttonDown?: IPP1Buttons;

  public get buttonDown(): IPP1Buttons | undefined {
    return this._buttonDown;
  }

  constructor() {
    makeAutoObservable(this, {
      setButtonDown: action,
    });
  }

  public setButtonDown(button: IPP1Buttons | undefined): void {
    this._buttonDown = button;
  }
}
