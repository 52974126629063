import { Menu, Modal } from 'antd';
import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { LessonType } from 'api';

import css from './index.module.css';

const lessonTypes = [LessonType.Learning, LessonType.Training, LessonType.Exam];

const InitialModal: FC = observer(() => {
  const { t } = useTranslation();
  const { isInitialModalVisible, setLessonType } = useRootData((store) => ({
    isInitialModalVisible: store.lesson.isInitialModalVisible,
    setLessonType: store.lesson.setLessonType,
  }));

  const onClick = ({ key }: { key: string }) =>
    setLessonType(LessonType[key as LessonType]);

  return (
    <Modal
      centered
      closable={false}
      footer={null}
      title={t('lesson.selectLessonType')}
      visible={isInitialModalVisible}
      width={400}
    >
      <Menu onClick={onClick}>
        {lessonTypes.map((type) => (
          <Menu.Item key={type} className={css.mode}>
            {t(`lessonTypes.${type}`)}
          </Menu.Item>
        ))}
      </Menu>
    </Modal>
  );
});

export default InitialModal;
