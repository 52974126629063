import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { ToolsType } from '../types';
import { WrenchConfig } from './types';
import { WrenchStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: WrenchStore,
  cfg: WrenchConfig
): void {
  const { hands, inventory } = store.player;
  if (!hands || !inventory)
    throw Error('Cannot configure without player.hands or player.inventory');

  // Установка целевых объектов
  autorun(() => {
    if (!store.scene.KIP) return;
    objStore.intersect.setTargets(
      store.scene.KIP.model.terminals.measure.map((m, id) => {
        return {
          id,
          mesh: m.bbox,
        };
      })
    );
  });
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.WRENCH;
    const isAttached = objStore.attachedTarget !== undefined;
    const setHooks = isSelected && isAttached;

    objStore.intersect.setEnabled(!isAttached);
    objStore.setDistanceTarget(setHooks ? hands.mainHand.root : null);
  });

  // Установка видимости ключа
  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.WRENCH;
    const isHandsAnimating = hands.animating;
    const isAttached = objStore.attachedTarget !== undefined;

    objStore.setVisibility((isSelected && !isHandsAnimating) || isAttached);
  });

  // Установка родителя ключа
  autorun(() => {
    if (!store.scene.KIP) return;
    const isSelected = inventory.selectedTool === ToolsType.WRENCH;
    const { attachedTarget } = objStore;

    if (attachedTarget !== undefined)
      objStore.moveToTerminal(
        store.scene.KIP.model.terminals.measure[attachedTarget].bbox
      );
    else if (isSelected) objStore.moveToHand(hands.mainHand.helperTake);
    else objStore.moveToHand(null);
  });

  autorun(() => {
    const isSelected = inventory.selectedTool === ToolsType.WRENCH;
    const isAttached = objStore.attachedTarget !== undefined;

    if (!isAttached || !isSelected) return;
    const ctrls = store.core.xr?.controllers;
    const controller = hands.leftHandIsMain ? ctrls?.left : ctrls?.right;
    if (!controller) return;
    objStore.axisRotationAccumulator.setTargetAxis(controller.defaultXAxis);
    objStore.axisRotationAccumulator.addQuaternion(controller.rotationDeltaQ);
  });

  // Поворот ключа
  autorun(() => {
    const isAttached = objStore.attachedTarget !== undefined;

    let a = objStore.rotationAngle;
    if (!isAttached || a === undefined) return;
    if (a > Math.PI / 4) a = Math.PI / 4;
    if (a < -Math.PI / 4) a = -Math.PI / 4;
    objStore.root.rotation.x = a;
  });

  // Проверка прикреплен ли ключ к клемме
  autorun(() => {
    if (objStore.intersect.marks.size > 0) {
      objStore.setAttachedTarget(
        objStore.intersect.marks.values().next().value
      );
    }
    if (objStore.distanceToMesh && objStore.distanceToMesh > 0.2) {
      objStore.setAttachedTarget(undefined);
    }
  });
}
