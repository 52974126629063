import { Container, Control, Measure, TextBlock } from '@babylonjs/gui';

export class WBodyContent extends Container {
  private _borderMargin: number;

  private _contentText: TextBlock;

  constructor(scale: number, name?: string) {
    super(name);

    this._borderMargin = 4 * scale;

    this._contentText = new TextBlock('Content');
    this._contentText.resizeToFit = true;
    this._contentText.textWrapping = true;
    this._contentText.fontSize = 12 * scale;
    this._contentText.color = '#222';
    this._contentText.textHorizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this._contentText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    this.addControl(this._contentText);
  }

  protected _preMeasure(
    parentMeasure: Measure,
    context: CanvasRenderingContext2D
  ): void {
    super._preMeasure(parentMeasure, context);

    this.widthInPixels = parentMeasure.width - this._borderMargin * 2;
    this.heightInPixels = parentMeasure.height - this._borderMargin * 2;
  }

  setContent(content: string): void {
    this._contentText.text = content;
  }
}
