import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { TubeConfig } from './types';

export class TubeModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _tube: AbstractMesh;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._tube = findByName(model.meshes, 'Tube');
  }

  static async load(scene: Scene, cfg: TubeConfig): Promise<TubeModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new TubeModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    this._tube.isVisible = isVisible;
  }

  get root(): TransformNode {
    return this._tube;
  }

  get tube(): AbstractMesh {
    return this._tube;
  }
}
