import { autorun } from 'mobx';

import { Vector3 } from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

import { HandState, HandsConfig } from './types';
import { HandsStore } from './store';
import { ToolsType } from '../../tools';

type HandsState = { main: HandState; minor: HandState };

function getHandsState(store: LessonStore): HandsState {
  switch (store.player.inventory?.selectedTool) {
    case ToolsType.BRUSH:
      return { main: HandState.BRUSH, minor: HandState.FIXED };
    case ToolsType.IPP1: {
      const clipInHand = store.tools.ipp1?.clips.inHand;
      return {
        main: HandState.IPP,
        minor: clipInHand ? HandState.CROCODILE : HandState.FIXED,
      };
    }
    case ToolsType.KEY:
      return { main: HandState.KEY, minor: HandState.FIXED };
    case ToolsType.TABLET:
      return { main: HandState.FIXED, minor: HandState.TABLET_HORIZONTAL };
    case ToolsType.TUBE:
      return { main: HandState.TUBE, minor: HandState.FIXED };
    case ToolsType.WRENCH:
      return { main: HandState.WRENCH, minor: HandState.FIXED };
    default:
      return { main: HandState.FIXED, minor: HandState.FIXED };
  }
}

export function setupLogic(
  store: LessonStore,
  objStore: HandsStore,
  cfg: HandsConfig
): void {
  autorun(() => {
    if (!store.isVR) {
      objStore.setVisibility(false);
      return;
    }

    const cLeft = store.core.xr?.controllers.left;
    objStore.left.setVisibility(cLeft !== undefined);
    if (cLeft) {
      objStore.left.root.parent = cLeft.pointer;
      objStore.left.root.position = new Vector3(0, 0, 0);
    }

    const cRight = store.core.xr?.controllers.right;
    objStore.right.setVisibility(cRight !== undefined);
    if (cRight) {
      objStore.right.root.parent = cRight.pointer;
      objStore.right.root.position = new Vector3(0, 0, 0);
    }
  });

  // Переключение главной руки
  let lastAState: boolean | undefined;
  autorun(() => {
    if (!store.core.xr) return;
    const { aDown } = store.core.xr?.controllers.buttons;
    if (aDown === lastAState) return;
    lastAState = aDown;

    if (aDown) objStore.swapMainHand();
  });

  autorun(() => {
    const handsState = getHandsState(store);
    objStore.mainHand.setTargetState(handsState.main);
    objStore.minorHand.setTargetState(handsState.minor);
  });

  autorun(() => {
    if (!store.player.inventory) return;
    const isGloveSelected = store.player.inventory?.gloveSelected;
    objStore.setGloveVisibility(isGloveSelected);
  });
}
