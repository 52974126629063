import { autorun } from 'mobx';

import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  Space,
  StandardMaterial,
  Texture,
  Vector3,
} from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';

import { InfoPanelConfig, InfoPanelPlaceType } from './types';
import { InfoPanelGUI } from './gui';
import { InfoPanelModel } from './model';
import { InfoPanelStore } from './store';
import { setupLogic } from './logic';

export class InfoPanelObject extends BaseModelObject<
  InfoPanelModel,
  InfoPanelStore,
  InfoPanelConfig
> {
  private static WIDTH = 240;
  private static HEIGHT = 210;
  private static TEX_Q = 4;
  private static REAL_SCALE = 0.8 / 240;

  private _texture: AdvancedDynamicTexture;
  private _gui: InfoPanelGUI;

  private onCloseButtonClick = new Observable<Vector2WithInfo>();

  constructor(
    scene: Scene,
    model: InfoPanelModel,
    store: InfoPanelStore,
    cfg: InfoPanelConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._texture = new AdvancedDynamicTexture(
      'InfoPanel',
      InfoPanelObject.WIDTH * InfoPanelObject.TEX_Q,
      InfoPanelObject.HEIGHT * InfoPanelObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new InfoPanelGUI(this._texture, InfoPanelObject.TEX_Q);

    // Настройка материала
    const modalMat = new StandardMaterial('TabletModal', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this._texture;
    modalMat.opacityTexture = this._texture;
    this.model.plane.material = modalMat;
    this._texture.attachToMesh(this.model.plane, true);

    // Перенаправление callback'ов
    this._gui.onCloseButtonClick = (e: Vector2WithInfo) => {
      this.onCloseButtonClick.notifyObservers(e);
    };
  }

  public static async setup(
    scene: Scene,
    cfg: InfoPanelConfig
  ): Promise<InfoPanelObject> {
    const model = await InfoPanelModel.load(scene, {
      height: InfoPanelObject.HEIGHT * InfoPanelObject.REAL_SCALE,
      width: InfoPanelObject.WIDTH * InfoPanelObject.REAL_SCALE,
    });
    const store = new InfoPanelStore(model);
    return new InfoPanelObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: InfoPanelStore, cfg: InfoPanelConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      this._gui.setContent(store.content);
    });

    autorun(() => {
      const { place } = store;
      if (place === undefined) return;

      const { root } = this.model;
      root.parent = place.parent;

      switch (place.type) {
        case InfoPanelPlaceType.KIP_LEFT: {
          root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
          root.rotate(new Vector3(0, 1, 0), 0.5, Space.LOCAL);
          root.position.set(0.2, 0.7, 0.5);
          break;
        }
      }
    });
  }
}
